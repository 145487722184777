import React, { useState, useEffect  } from "react";
import { createMarkup } from '../../../Helpers'
import {
  Padding,
  ContentContainer,
  Heading,
} from '../../../../styles'
import {
  ContactInfo,
} from "./styles";

export default function Map({
  title,
  address,
  showDetails,
  phone,
  fax,
  map
}) {
  // states
  const [mapIframeURL, setMapIframeURL] = useState(null)
  // vars
  const mapZoom = 13

  useEffect(() => {
    if (
      map.info.address
      && map.api_key
    ) {
      const address = map.info.address
      address.replace(' ', '+')
      setMapIframeURL(`https://www.google.com/maps/embed/v1/place?key=${map.api_key}&q=${address}&zoom=${mapZoom}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  if (!address) return null;

  return (<ContentContainer
    padding={`${Padding.padding0} 0 ${Padding.padding1}`}
  >
    {title
      && <Heading
        type={'h5'}
        children={title || ''}
        color={`blue`}
        fontSize={`2.2em`}
        tTransform={`capitalize`}
      />}
    {mapIframeURL
      && <iframe
        title={'map-iframe'}
        frameBorder={0}
        height={'450px'}
        width={'100%'}
        src={mapIframeURL}
        allowFullScreen
      />}
    {address
      && <>
        <Heading
          type={'h5'}
          children={'Mailing Address'}
          color={`blue`}
          fontSize={`2.2em`}
          tTransform={`capitalize`}
        />
        <ContactInfo
          dangerouslySetInnerHTML={createMarkup(address)}
        />
      </>}
    {showDetails 
      && phone
      && <ContactInfo>
          {phone}
        </ContactInfo>}
    {showDetails
      && fax 
      && <ContactInfo>
          {fax}
        </ContactInfo>}
  </ContentContainer>);
}
