import styled from 'styled-components'

export const WrapPost = styled.article.attrs({
  className: 'WrapPost',
})`
    display: grid;
    gap: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    border: #D3D7E1 solid 1px;
    box-shadow: 4px 4px 10px 1px rgba(211,215,225,0.75);
    
    .wrapImage {
      width: 100%;
      height: 100%;
      display: flex;
      
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    
    @media screen and (min-width: 1000px) {
      grid-template-columns: 1fr 2fr;
      
      .wrapImage {
        max-height: 18.75rem;
      }
    }
`;

export const Title = styled.h2.attrs({
  className: 'Title',
})`
    a {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 2.25em;
      line-height: 1.25em;
      color #333333;
      
      &:hover {
        text-decoration: none;
      }
    }
`;

export const Content = styled.div.attrs({
  className: 'Content',
})`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    padding-top: 0px;
    
    .categories {
      font-size: 1.5em;
      margin-bottom: 0.5rem;
    }
    
    .tags {
      font-size: 1.25em;
      color: #a6a8ab;
      
      span ~ span::before {
        content: ', '; 
      }
    }
    
    @media screen and (min-width: 1000px) {
      padding: 2rem;
      padding-left: 0px;
    }
`;

export const Footer = styled.div.attrs({
  className: 'Footer',
})`
    display: flex;
    justify-content: space-between;
    
    a {
      font-size: 1.5em;
      color: #5881bc;
      font-weight: bold;
      
      &:hover {
        text-decoration: none;
      }
    }
`;
