import React, { useState } from "react";
import ModalPopup from '../ModalPopup'
import EmailAFriend from '../Forms/EmailAFriend'
import RequestQuote from '../Forms/RequestQuote'
import RequestSample from '../Forms/RequestSample'
import SVGWrapper from '../SVGWrapper'
import {
  Widths,
} from '../../../styles'
import {
  ButtonsContainer,
  CtaButton,
  CtaButtonText,
  CtaRollover,
} from "./styles";
import printIcon from '../../../assets/images/icons/Quam-Icon-Printer.svg'
import mailIcon from '../../../assets/images/icons/Quam-Icon-Email.svg'
import priceIcon from '../../../assets/images/icons/Quam-Icon-DocumentPen.svg'

export default function ProductCTAs() {
  // states
  const [emailModalOpen, setEmailModalOpen] = useState(false)
  const [quoteModalOpen, setQuoteModalOpen] = useState(false)
  const [sampleModalOpen, setSampleModalOpen] = useState(false)

  const handlePrint = (e) => {
    e.preventDefault()
    e.stopPropagation()
    window.print()
  }

  const handleEmailToFriend = (e) => {
    e.preventDefault()
    e.stopPropagation()
    !emailModalOpen && setEmailModalOpen(true)
  }

  const handleRequestQuote = (e) => {
    e.preventDefault()
    e.stopPropagation()
    !quoteModalOpen && setQuoteModalOpen(true)
  }

  const handleRequestSample = (e) => {
    e.preventDefault()
    e.stopPropagation()
    !sampleModalOpen && setSampleModalOpen(true)
  }

  return (<>
    <ButtonsContainer>
      <CtaButton
        onClick={(e) => handlePrint(e)}
      >
        <SVGWrapper
          src={printIcon}
          width={Widths.icons.small}
        />
        <CtaRollover>
          {' Print Page'}
        </CtaRollover>
      </CtaButton>
      <CtaButton
        onClick={(e) => handleEmailToFriend(e)}
      >
        <SVGWrapper
          src={mailIcon}
          width={Widths.icons.small}
        />
        <CtaRollover>
          {'Email Product'}
        </CtaRollover>
      </CtaButton>
      <CtaButton
        onClick={(e) => handleRequestQuote(e)}
      >
        <SVGWrapper
          src={priceIcon}
          width={Widths.icons.small}
        />
        <CtaRollover>
          {'Request Quote'}
        </CtaRollover>
      </CtaButton>
      <CtaButtonText
        onClick={(e) => handleRequestSample(e)}
      >
        {'Request a Sample'}
      </CtaButtonText>
    </ButtonsContainer>
    <ModalPopup
      isOpen={emailModalOpen}
      handleClose={setEmailModalOpen}
      children={<EmailAFriend title={'Email This Product'} />}
    />
    <ModalPopup
      isOpen={quoteModalOpen}
      handleClose={setQuoteModalOpen}
      children={<RequestQuote title={'Request a Quote'} />}
    />
    <ModalPopup
      isOpen={sampleModalOpen}
      handleClose={setSampleModalOpen}
      children={<RequestSample title={'Request Product Sample'} />}
    />
  </>);
}
