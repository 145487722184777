import React, {useState, useEffect, useRef} from "react";
import { useHistory } from 'react-router-dom'
import NavAPIClient from '../../../api/clients/nav'
import SiteAPIClient from '../../../api/clients/site'
import Logo from '../Logo'
import SearchBar from './SearchBar'
import Navigation from '../Navigation'
import {
  Container,
  // SearchBtn,
  FavoritesBtn,
  FavCounter,
  headerHeight
} from "./styles";
import { useCookies } from 'react-cookie';
import {
  objEmpty,
  // objHasKey,
  QUAM_COOKIE_KEY,
} from '../../Helpers'

export default function Header() {
  // router
  const router = useHistory()
  // cookies
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies([QUAM_COOKIE_KEY]);
  // refs
  const headerRef = useRef()
  // states
  const [mainNav, setMainNav] = useState(null)
  const [logo, setLogo] = useState(null)
  const [favIcon, setFavIcon] = useState(null)
  const [numFavorites, setNumFavorites] = useState(0)
  const [searchIcon, setSearchIcon] = useState(null)
  const [headerBumpHeight, setHeaderBumpHeight] = useState(0)

  let allowedToLoad = true;

  useEffect(() => {

    const load = async () => {

      try {
        // get navs available
        const API1 = new NavAPIClient()
        const response1 = await API1.getNavsAvailable()
        if (true === response1.success) {
          // find the header nav
          const _mainNav = response1.navs.find(nav => (
            'main-nav' === nav.slug
          ))
          // if we found a nav, set it.
          _mainNav && setMainNav(_mainNav)
        }

        // get header assets
        const API2 = new SiteAPIClient();
        const response2 = await API2.getHeaderOptions()
        if (true === response2.success) {
          const {options} = response2
          setLogo(options.header_logo.url || null)
          setSearchIcon(options.search_icon.url || null)
          setFavIcon(options.favorites_icon.url || null)
        }

      } catch(error) {
        console.error(error)
      }
    }
    // do not call the header yet.
    allowedToLoad && load()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, [])

  // when the cookies change, check and update number of favs
  useEffect(() => {
    if (!objEmpty(cookies[QUAM_COOKIE_KEY])) {
      setNumFavorites(Object.entries(cookies[QUAM_COOKIE_KEY]).length)
    } else {
      setNumFavorites(0)
    }
  }, [cookies])

  // fix content to header height
  useEffect(() => setHeaderBump())

  const setHeaderBump = () => {
    if (!headerRef.current) {
      return;
    }

    const body = document.body
    body.style.paddingTop = `${headerHeight}`
    return () => setHeaderBumpHeight(headerHeight)
  }

  return (<Container ref={headerRef}>
    <Logo logo={logo} />
    {mainNav
      && <Navigation
        slug={mainNav.slug}
        headerBump={headerBumpHeight}
      />}
    <SearchBar icon={searchIcon} />
    <FavoritesBtn
      onClick={() => router.push('/favorites')}
      bgColor={'blue'}
      borderColor={'blue'}
      hoverColor={`white`}
      hoverBgColor={`blue`}
      hoverBorderColor={`blue`}
    >
      {favIcon
        && <img
          src={favIcon}
          alt={'Search'}
        />}
      {0 < numFavorites
        && <FavCounter>{numFavorites}</FavCounter>}
    </FavoritesBtn>
  </Container>);
}
