import styled from "styled-components";

import {
  Color,
  Block
} from '../../../../styles'

export const Content = styled(Block)`
  line-height: 1.4em;

  a {
    color: ${Color.red};
    text-decoration: none;
  }
`;