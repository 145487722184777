import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import {
  arrEmpty,
  createMarkup,
  getThumbnail,
  useWindowSize,
} from '../../Helpers'
import FavoritesButton from '../../Components/Favorites/FavoritesButton'
import {
  FontSize,
  GridItemAnchor,
  AnimatedGridItem,
  ProductImage,
  ProductTitle,
  ProductContentContainer,
  ProductDescription,
  ButtonContainer,
  AnchorButtonAlt,
  HoverCircle,
} from '../../../styles'

import FadeInSection from '../../Components/FadeInSection'

import {useSpectraAnalytics} from 'spectra/src/hooks/useSpectraAnalytics'

export default function ProductGridItemContainer({
  product,
  showDescription = true,
  showButtons = true,
  numGridCols = 3,
}) {
  //router
  const router = useHistory()
  // states
  const [isActive, setIsActive] = useState(false)
  const [routePrimed, setRoutePrimed] = useState(false)
  const [primarySpecSheet, setPrimarySpecSheet] = useState(null)
  // vars
  const windowSize = useWindowSize()
  const prodFiles = product?.sections?.product_files?.files || null
  const prodImage = getThumbnail(product)
  // setup grid widths (all percentages)
  const horizontalMarginPerItem = 2
  const horizontalMarginPerItemSide = (2 / horizontalMarginPerItem)
  const totalHorizontalMargin = numGridCols * horizontalMarginPerItem
  const itemWidth = (100 - totalHorizontalMargin) / numGridCols

  const analytics = useSpectraAnalytics()

  useEffect(() => {
    let primaryFile = null
    if (!arrEmpty(prodFiles)) {
      primaryFile = prodFiles.find(file => !!file.primary_spec_sheet)
    }
    setPrimarySpecSheet(primaryFile)
  }, [product, prodFiles])

  const handleProductRoute = () => (
    (analytics && analytics.logEvent('product_grid_click', {product: product.name}))
    ('mobile' !== windowSize.currDevice && 'tablet' !== windowSize.currDevice
      ? router.push(`/product/${product.name}`)
      : routePrimed
        ? router.push(`/product/${product.name}`)
        : setRoutePrimed(true))
  )

  const handleActivate = () => {
    setIsActive(true)
  }

  const handleDeactivate = () => {
    setIsActive(false)
    setRoutePrimed(false)
  }

  if (!product) return null;

  return (
    <AnimatedGridItem
      active={isActive}
      onMouseEnter={() => handleActivate()} // req. for mobile
      onMouseOver={() => handleActivate()} // desktop edge-cases
      onMouseLeave={() => handleDeactivate()}
      flexDesktop={showButtons && `0 0 ${itemWidth}%`}
      margin={`0 ${horizontalMarginPerItemSide}%`}
    >
    <FadeInSection>
      {prodImage
        && <ProductImage
          hovered={isActive}
          backgroundImage={prodImage}
        >
          <GridItemAnchor
            onClick={() => handleProductRoute()}
          >
            <HoverCircle
              padding={'9em'}
              active={isActive}
              text={'VIEW'}
            />
          </GridItemAnchor>
        </ProductImage>}
      <ProductContentContainer
        onClick={() => handleProductRoute()}
      >
        {product.title
          && <ProductTitle
            type={'h5'}
            fontWeight={'800'}
            children={product.title}
          />}
        {!!showDescription
          && product.sections?.product_details?.short_description
          && <ProductDescription
            dangerouslySetInnerHTML={createMarkup(
              product.sections?.product_details.short_description
            )}
          />}
      </ProductContentContainer>
      {!!showButtons
        && <ButtonContainer
          active={isActive}
        >
          <FavoritesButton
            product={product}
            fontSize={FontSize.button.small}
            padding={'0.75em 0.2em'}
            inclIcon={false}
          />
          {primarySpecSheet
            && <AnchorButtonAlt
              fontSize={FontSize.button.small}
              padding={'0.75em 0.2em'}
              href={primarySpecSheet?.file?.url}
              target={'_blank'}
              rel={'noopener noreferrer'}
              onClick={() => (
                analytics && analytics.logEvent('product_spec_sheet', {
                  product: product.name,
                  spec_sheet: primarySpecSheet?.file?.url
                })
              )}
            >
              {'View Spec Sheet'}
            </AnchorButtonAlt>}
        </ButtonContainer>}
      </FadeInSection>
    </AnimatedGridItem>
  )
}
