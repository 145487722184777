import React, { useState, useEffect, useRef } from "react";
import SVGWrapper from '../SVGWrapper'
import { 
  arrEmpty,
  createMarkup,
} from '../../Helpers'
import {
  GalleryContainer,
  CurrentSelectionContainer,
  ThumbnailContainer,
  ThumbnailWrapper,
  ThumbnailScrollRight,
  ThumbnailScrollLeft,
  ThumbnailItem,
  InternalVideoContainer,
  ExternalVideoContainer,
} from './styles'
import { Widths } from '../../../styles'
import arrowLeft from '../../../assets/images/icons/Quam-Icon-LeftArrow.svg'
import arrowRight from '../../../assets/images/icons/Quam-Icon-RightArrow.svg'
import playIcon from '../../../assets/images/icons/Quam-Icon-Play.svg'

export default function ProductGallery({
  gallery
}) {
  // refs
  const thumbnailWrapperEl = useRef()
  // states
  const [currentlySelected, setCurrentlySelected] = useState(0)
  // const [videoHTML, setCurrentlySelected] = useState(0)
  const [thumbScrollLeftVisible, setThumbScrollLeftVisible] = useState(true)
  const [thumbScrollRightVisible, setThumbScrollRightVisible] = useState(true)

  // hide left/right arrows if 4 or less gallery images
  useEffect(() => {
    if (
      arrEmpty(gallery)
      || 4 >= gallery.length
    ) {
      setThumbScrollLeftVisible(false)
      setThumbScrollRightVisible(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleThumbnailClick = (idx, type) => {
    setCurrentlySelected(idx)
  }

  const buildShowcaseDisplay = () => {
    const mediaType = gallery[currentlySelected]?.type
    const mediaUrl = gallery[currentlySelected]?.media
    if ('internal_video' === mediaType) {
      return (<CurrentSelectionContainer
        inclBackdrop={true}
      >
        <InternalVideoContainer
          playsInline // iOS hack to autplay without entering fullscreen mode
          fullscreen={true}
          autoPlay={true} 
          loop={false}
          muted={false}
          controls={true}
          poster={gallery[currentlySelected]?.large_thumbnail[0] || null}
          preload={'metadata'}
        >
          <source 
            src={mediaUrl}
            type={`video/${mediaUrl.split('.').pop()}`}
          />
        </InternalVideoContainer>
      </CurrentSelectionContainer>)
    } else if ('external_video' === mediaType) {
      return (<CurrentSelectionContainer
        inclBackdrop={true}
      >
        <ExternalVideoContainer
          dangerouslySetInnerHTML={createMarkup(mediaUrl)}
        />
      </CurrentSelectionContainer>)
    } else {
      return (<CurrentSelectionContainer
        bgImage={gallery[currentlySelected]?.large_thumbnail[0]}
      />)
    }
  }

  const handleThumbnailScroll = (scrollDirection = 'right') => {
    const horizontalContainer = thumbnailWrapperEl.current
    if (!horizontalContainer) return null;
    // px currently scrolled, MINUS half the container width
    // px currently scrolled, PLUS half the container width
    const scrollAmount = 'left' === scrollDirection
      ? horizontalContainer.scrollLeft - (horizontalContainer.offsetWidth / 2)
      : horizontalContainer.scrollLeft + (horizontalContainer.offsetWidth / 2)
    horizontalContainer.scroll({
      top: 0, 
      left: scrollAmount, 
      behavior: 'smooth'
    })
    // show/hide left/right arrows
    // if (horizontalContainer.scrollLeft) {}
  }

  return (
    <GalleryContainer
      bgColor={`transparent`}
    >
      {buildShowcaseDisplay()}
      <ThumbnailContainer>
        <ThumbnailScrollLeft
          visible={thumbScrollLeftVisible}
          onClick={() => handleThumbnailScroll('left')}
        >
          <SVGWrapper
            src={arrowLeft}
            height={Widths.icons.small}
          />
        </ThumbnailScrollLeft>
        <ThumbnailWrapper
          ref={thumbnailWrapperEl}
        >
          {gallery
            && gallery.map((item, idx) => {
            const type = item.type
            return <ThumbnailItem
                key={idx}
                active={idx === currentlySelected}
                bgImage={item?.thumbnail[0]}
                onClick={() => handleThumbnailClick(idx, item?.type)}
                flexMobile={`0 0 23%`}
                flexTablet={`0 0 23%`}
              >
                {type.includes('video')
                  && <SVGWrapper
                    src={playIcon}
                    width={Widths.icons.xlarge}
                  />}
              </ThumbnailItem>
          })}
        </ThumbnailWrapper>
        <ThumbnailScrollRight
          visible={thumbScrollRightVisible}
          onClick={() => handleThumbnailScroll('right')}
        >
          <SVGWrapper
            src={arrowRight}
            height={Widths.icons.small}
          />
        </ThumbnailScrollRight>
      </ThumbnailContainer>
    </GalleryContainer>
  );
}
