import styled from "styled-components";

import {
  Color,
  Padding,
  Block,
  Button,
  InputText,
  FormContainer,
} from '../../../styles'

export const SearchContainer = styled(Block)`
  position: relative;
  width: 100%;
  height: auto;
  padding: ${Padding.padding1} ${Padding.padding3} 0;
  box-sizing: border-box;
  background-color: ${Color.white};
`

export const SearchForm = styled(FormContainer)`
  height: 70px;
  width: 100%;
  display: flex;
  max-width: 600px;
  margin: 0 auto;
`

export const SearchInput = styled(InputText).attrs(props => ({
  bgColor: `white`,
  maxWidth: `100%`,
  padding: `1em 4em 0.82em 0.82em`,
  border: `1px solid ${Color.slateGray}`,
}))`
  height: 100%;
  width: 85%;
`

export const SearchBtn = styled(Button).attrs(props => ({
  bgColor: `red`,
  borderColor: `red`,
  padding: `0.82em 0`
}))`
  height: 100%;
  width: 15%;

  .SVGContainer {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }


  &:hover {
    svg {
      fill: ${Color.white};
      stroke: ${Color.white};
      
      path,
      text,
      polygon
      {
        fill: ${Color.white};
        stroke: ${Color.white};
      }
    }
  }
`