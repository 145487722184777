import styled from "styled-components";

import {
  Color,
  Widths,
  Block,
  HorizontalLine,
  restrictedWidth,
} from "../../../styles";

export const Container = styled(Block).attrs((props) => ({
  className: "FooterContainer",
}))`
  position: relative;
  width: 100%;
  padding: 0 6vw;
  box-sizing: border-box;
  background-color: ${Color.blue};

  /* @media screen and (min-width: ${Widths.minTablet}) {
    padding: 0;
  } */
`;

export const Separator = styled(HorizontalLine).attrs((props) => ({
  className: `Separator`,
  color: Color.white,
}))`
  ${restrictedWidth}
`;
