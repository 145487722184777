import axios from 'axios'
import api from '../index'
import APIClient from './base'

class BlogAPIClient extends APIClient {

  constructor (base64Auth) {
    super(base64Auth)
    this.endpoint = `${api.wpURL}/posts`;
  }

  async getPosts (postsPerPage = 100, page = 1) {
    const url = `${this.endpoint}/?_embed=wp:term&per_page=${postsPerPage}&page=${page}`
    const response = await axios.get(url)
    return response.data
  }
  
  async getPost (slug = null) {
    const url = `${this.endpoint}/?_embed=wp:term&slug=${slug}`
    const response = await axios.get(url)
    return response.data
  }
}

export default BlogAPIClient
