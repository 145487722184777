import styled from 'styled-components'

import {Color} from './global'

export const outputImgAlignment = (align) => {
  let alignment = ``;
  switch(align) {
    case 'right':
      alignment += `
        float: right;
      `
    break;

    case 'left':
      alignment += `
        float: left;
      `
    break;

    default:
      alignment += `
        margin-left: auto;
        margin-right: auto;
      `
  }
  return alignment;
}


export const getColorByName = (colorName, fallbackColor) => {
  return colorName
    && Color.hasOwnProperty(colorName)
      ? Color[colorName]
      : fallbackColor && Color.hasOwnProperty(fallbackColor)
        ? Color[fallbackColor]
        : 'inherit'
}

export const coverBackground = (url) => (`
  background-image: url(${url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`)

export const ContentSpacer = styled.div.attrs(props => ({
  height: props.height || `0`,
  className: `ContentSpacer ${props.className || ''}`
}))`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: ${props => props.height} 0 0 0;
  background: transparent;
  opacity: 0;
`;
