import styled from "styled-components";

import {
  Block,
  AnchorButton,
  Color,
  Span,
} from '../../../styles'

export const headerHeight = `6em`

export const HeaderButtonStyles = `
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 5em;
  text-align: center;
  font-size: 1.4em;
  padding: 0.6em 0;
  box-sizing: border-box;

  img {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: auto;
  }
`

export const Container = styled(Block).attrs(props => ({
  className: 'HeaderContainer'
}))`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 1%;
  box-sizing: border-box;
  background-color: ${Color.white};
  z-index: 9999999999;
`;

export const FavoritesBtn = styled(AnchorButton)`
  ${HeaderButtonStyles}
  z-index: 9998;
`

export const FavCounter = styled(Span)`
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  color: ${Color.white};
  font-size: 1.2em;
`
