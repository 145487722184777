import styled from 'styled-components'

import {
  FontSize,
  Color,
  Padding,
  Block,
  InputNum,
} from '../../../styles'

export const PaginationContainer = styled(Block).attrs(props => ({
  className: `PaginationContainer`,
}))`
  position: relative;
  max-width: 100%;
  box-sizing: border-box;
  height: auto;
  padding: ${Padding.padding0} ${Padding.padding1} ${Padding.padding2};
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PaginationItem = styled(Block).attrs(props => ({
  className: `PaginationItem`,
}))`
  font-size: ${FontSize.body};
  color: ${props => props.active ? `${Color.blue}` : `${Color.darkGray}`};
  padding: 0 ${Padding.padding0};
  background-color: ${props => props.active ? `${Color.veryLightBlue}` : `${Color.white}`};
  border: 1px solid ${Color.transparent};
  border-radius: 4px;
  padding: 0.5em 0.8em;
  margin: 0 0.2em;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: ${Color.blue};
    background-color: ${Color.veryLightBlue};
  }
`

export const PaginationItemSpacer = styled(Block).attrs(props => ({
  className: `PaginationItemSpacer`,
}))`
  opacity: 0;
  font-size: ${FontSize.body};
  // color: ${Color.transparent};
  padding: 0 ${Padding.padding0};
  background-color: ${Color.transparent};
  border: 1px solid ${Color.transparent};
  padding: 0.6em 0.8em;
  margin: 0 0.2em;
`

export const PageSelector = styled(InputNum).attrs(props => ({
  className: `PageSelector`,
}))`
  font-size: ${FontSize.body};
  padding: 0 ${Padding.padding0};
  background-color: ${Color.white};
  border: 1px solid ${Color.gray};
  border-radius: 4px;
  padding: 0.5em 0.8em;
  margin: 0 0.2em;
  width: auto;
  text-align: center;
`

export const NumPagesText = styled(Block).attrs(props => ({
  className: `NumPagesText`,
}))`
  font-size: ${FontSize.body};
  padding: 0 ${Padding.padding0};
  background-color: ${Color.transparent};
  padding: 0.5em 0.8em;
  margin: 0 0.2em;
`
