import styled from 'styled-components'
import {
  FontSize,
  Color,
  Widths,
  BoxShadow,
  Block,
  Span,
  Anchor,
  Padding,
} from '../../../styles'
import { HeaderButtonStyles } from '../Header/styles'

export const NavBlock = styled(Block)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 14em;
  height: 100%;
  width: calc(100% - 35em);
  box-sizing: border-box;
  margin: 0 2em;
`

export const NavBlockMobile = styled(Block).attrs(props => ({
  isOpen: props.isOpen || false,
  headerBump: props.headerBump || `0`
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${Color.white};
  justify-content: flex-start;
  position: fixed;
  top: ${props => props.headerBump};
  left: 0;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  transition: 0.2s;
  ${props => false === props.isOpen
    ? `height: 0;
      overflow: hidden;
      padding: 0;`
    : `height: auto;
      padding: ${Padding.padding2} ${Padding.padding3};
      box-shadow: 0 15px 10px -20px;`
  }
`

export const MenuToggle = styled(Block).attrs(props => ({
  isOpen: props.isOpen || false,
}))`
  ${HeaderButtonStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  right: 140px;
  max-width: 70px;
  z-index: 9998;
  background: ${Color.offWhite};
`

export const BurgerMenuToggleContainer = styled(Span).attrs(props => ({
  isOpen: props.isOpen || false,
}))`
  transition: 0.2s;
  transform-origin: center;
  position: relative;
  width: 23px;
  height: 16px;

  .BurgerMenuToggleLine {
    position: absolute;
    top: 7px;
    left: 0;
    width: 23px;
    height: 2px;
    background-color: ${Color.darkGray};
    margin-bottom: 5px;
    transition: all 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);

    transform: ${props => props.isOpen ? `rotate(45deg)` : `rotate(0deg)`};

    &:first-child {
      top: 0;
      opacity: ${props => props.isOpen ? `0` : `1`};
    }

    &:last-child {
      top: unset;
      bottom: 0;
      margin-bottom: 0px;

      ${props => props.isOpen
        ? `top: 7px;
           transform: rotate(-45deg);`
        : ``
      };
    }
  }

`

export const BurgerMenuToggleLine = styled(Span).attrs(props => ({
  className: `BurgerMenuToggleLine`,
}))`

`

export const MenuOverlay = styled(Block)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${props => props.open ? '100vh' : '0'};
  opacity: 0;
  background: transparent;
  z-index: 9996;
`

export const MenuItemContainer = styled(Block).attrs(props => ({
  currDevice: props.currDevice || 'desktop'
}))`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 9997;
  ${props => 'desktop' === props.currDevice || 'laptop' === props.currDevice
    ? `padding: 0;`
    : `padding: ${Padding.padding1} 0;
      flex-wrap: wrap;
      justify-content: center;`
  }
  font-size: ${FontSize.header.menuItem.mobile};

  @media screen and (min-width: ${Widths.minTablet}) {
    font-size: ${FontSize.header.menuItem.tablet};
  }
  @media screen and (min-width: ${Widths.minDesktop}) {
    font-size: ${FontSize.header.menuItem.desktop};
  }
`

const onHover = `
  &:hover {
    color: ${Color.blue};
    text-shadow: 0 0 0.5px;
    text-decoration: none;
  }
`;

export const MenuItemAnchor = styled(Anchor)`
  padding-left: 2em;
  padding-right: 2em;
  transition: 0.2s;
  ${onHover}
`

export const SubMenuContainer = styled(Block).attrs(props => ({
  currDevice: props.currDevice || 'desktop'
}))`
  background: transparent;
  box-sizing: border-box;
  z-index: 9998;
  ${props => 'desktop' === props.currDevice || 'laptop' === props.currDevice
    ? `position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 0.5em;
      font-size: 0.89em;`
    : `position: relative;
      width: 96vw;
      padding: 1.5em 0.5em 0;
      font-size: 0.78em;
      line-height: 1; `
  }
`

export const CloseSubMenu = styled(Span).attrs(props => ({
  currDevice: props.currDevice || 'desktop'
}))`
  position: absolute;
  right: 0.2em;
  top: 0.5em;
  padding: 0.5em;
  font-size: ${FontSize.body};
  transform: rotate(45deg);

  @media screen and (min-width: ${Widths.minLaptop}) {
    display: none;
  }
`

export const SubMenuWrapper = styled(Block).attrs(props => ({
  currDevice: props.currDevice || 'desktop'
}))`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: ${Color.lightGray};
  padding: 1.5em 2.15em 1.5em;
  box-sizing: border-box;
  box-shadow: ${props => 'desktop' === props.currDevice || 'laptop' === props.currDevice
    ? BoxShadow.small
    : 'none'};
  border-radius: 3px;
  max-width: 100vw;
  ${props => 'desktop' === props.currDevice || 'laptop' === props.currDevice
    ? `min-width: ${props.twoCols
      ? Widths.subMenu.twoColMin.desktop
      : Widths.subMenu.oneColMin.desktop};`
    : ``
  }
  z-index: 9997;

  > a {
    display: inline-block;
    flex: ${props => props.twoCols ? `0 0 50%` : `1 1 100%`};
    padding: 0.60em 0;
    transition: 0.2s;
    color: ${Color.black};
    ${onHover}
  }
`
