import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import TechnicalFilesAPIClient from '../../../api/clients/technicalFiles'
import Loading from '../../Components/Loading'
import TitleArea from '../../Components/TitleArea'
import ProductFiles from '../../Components/ProductFiles'
import Notice from '../../Components/Notice'
import PaginationAlt from '../../Components/PaginationAlt'
import { InputText, InputSelect } from '../../../styles/forms'
import {
  Color,
  Padding,
  ContentContainer,
  ContentWrapper,
  GridContainer,
  GridItem,
  P,
} from '../../../styles'
import {
  Label,
} from './styles'
import SiteAPIClient from '../../../api/clients/site'
import {
  arrEmpty,
  sortArrayOfObjectsByKeyName,
  useWindowSize,
} from '../../Helpers'

export default function TechnicalFilesTemplate({
  title,
  content, // should be null
  updateResults,
}) {
  // router
  const router = useLocation()
  const [typeOptions, setTypeOptions] = useState({})
  // states
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(null)
  const [numPages, setNumPages] = useState(null)
  const [technicalFiles, setTechnicalFiles] = useState(null)

  const [filename, setFilename] = useState('')
  const [filetype, setFiletype] = useState('')
  const inputBorderStyle = `1px solid ${Color.gray}`
  const inputMarginStyle = `0 0 3% 0`
  const inputPaddingStyle = `0.65em`
  // vars
  const windowSize = useWindowSize()
  let allowedToLoad = true

  useEffect(() => {
    const loadOptions = async () => {
      // setIsLoading(true)
      try {
        const API = new SiteAPIClient();
        const response = await API.getTechnicalFilesOptions()
        if (true === response.success) {
          // set title area
          setTypeOptions(response?.options?.technical_files_color_mapping || {})
        }
      } catch(err) {
        console.error(err)
      }
      // setIsLoading(false)
    }

    // do not call the header yet.
    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && setPage(1)

    loadOptions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, [])

  // get the results
  useEffect(() => {
    const loadProducts = async () => {
      setIsLoading(true)
      try {
        const API = new TechnicalFilesAPIClient();
        const response = await API.getTechnicalFiles(page, filename, filetype)
        if (true === response.success) {
          // set files
          const _files = !arrEmpty(response.technical_files)
            && sortArrayOfObjectsByKeyName(response.technical_files, 'file_title')
          setTechnicalFiles(_files)
          // create an empty array of length based on the num of pages, so the pagination component can loop through it easily...
          setNumPages(Array.from(Array(response.num_pages)))
        } else {
          setTechnicalFiles(null)
          setNumPages(null)
        }
      } catch(err) {
        console.error(err)
      }
      setIsLoading(false)
    }

    // do not call the header yet.
    (router
      && '_error' !== router.pathname)
      && loadProducts()
  }, [page, router, filename, filetype])

  return (<ContentContainer bgColor={Color.white} >
    <TitleArea title={title} />
    <ContentWrapper
      currDevice={windowSize.currDevice}
      // minHeight={`1640px`} // TODO set initial then once loaded the first time calculate based on the number of items per page + buffer...
    >
      <GridContainer
        gap={`0`}
        columns={`100%`}
        rows={`1`}
        padding={`${Padding.padding1} 0 ${Padding.padding1}`}
      >
        <Label>Search Technical Files</Label>
        <GridContainer
          gap={'mobile' === windowSize.currDevice ? `0%` : `5%`}
          columns={'mobile' === windowSize.currDevice ? `100%` : `65% 30%`}
          rows={`1`}
          padding={`-20px 0 ${Padding.padding3}`}
          alignItems={`flex-start`}
        >
          <InputText
            name="filename"
            value={filename}
            onChange={({target}) => setFilename(target.value)}
            margin={inputMarginStyle}
            padding={inputPaddingStyle}
            border={inputBorderStyle}
            placeholder="Filter by file or product name"
          />
          <InputSelect
            name="filetype"
            onChange={({target}) => setFiletype(target.value)}
            margin={inputMarginStyle}
            padding={inputPaddingStyle}
            border={inputBorderStyle}
            >
              <option value="">Filter by file type</option>
              {Object.keys(typeOptions).map((key, index) => <option key={index} value={key}>{key}</option>)}
          </InputSelect>
        </GridContainer>
      </GridContainer>

      <GridContainer
        gap={`0`}
        columns={`100%`}
        rows={`1`}
        padding={`${Padding.padding1} 0 ${Padding.padding1}`}
      >
        <GridItem>
          <P>{'Click on model number to download.'}</P>
        </GridItem>
      </GridContainer>
      {isLoading
        ? <Loading />
        : !arrEmpty(technicalFiles)
          ? <>
            <GridContainer
              gap={'mobile' === windowSize.currDevice ? `0% 0%` : `0% 10%`}
              columns={'mobile' === windowSize.currDevice ? `100%` : `45% 45%`}
              rows={`1`}
              padding={`-20px 0 ${Padding.padding3}`}
            >
                  <ProductFiles
                    files={technicalFiles}
                    slimline={true}
                  />
            </GridContainer>
          </>
          : <Notice message={'No files found.'} />}
          {page && numPages && !arrEmpty(technicalFiles)
            && <PaginationAlt
              currPage={page}
              numPages={numPages}
              updatePage={setPage}
              isLoading={isLoading}
            />}
    </ContentWrapper>
  </ContentContainer>);
}
