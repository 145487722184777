import React, {useState, useEffect} from "react";
import { useHistory, useParams } from 'react-router-dom'
import TemplateFactory from '../Templates/TemplateFactory'
import BlogAPIClient from '../../api/clients/blog'
import { arrEmpty } from '../Helpers'
import Loading from '../Components/Loading'

export default function Post() {
  // router
  const router = useHistory()
  const params = useParams()
  // state
  const [isLoading, setIsLoading] = useState(false)
  const [titleArea, setTitleArea] = useState(null)
  const [post, setPost] = useState(null)
  // vars
  let allowedToLoad = true;
  
  // get the banner content
  useEffect(() => {
    const loadBanner = async () => {
      setIsLoading(true)
      try {
        const API = new BlogAPIClient();
        const response = await API.getPost(params.slug);
        
        if (response) {
          // set title area
          setTitleArea({
            title: response[0].title.rendered || null,
            image: null,
            breadcrumbs: [{
                'title': 'Blog',
                'url': '/blog',
              }],
          })
          setPost(response[0]);
        }
      } catch(err) {
        console.error(err)
      }
      setIsLoading(false)
    }

    // when safe to load
    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && loadBanner()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, []) // run once initially

  // early exit
  if (arrEmpty(titleArea)) return null
  
  return (isLoading
    ? <Loading inclIcon={true} />
    : <TemplateFactory
      template={'post-template'}
      title={titleArea}
      modules={post}
    />)
}
