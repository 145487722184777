import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import ProductsAPIClient from '../../../api/clients/products'
import Loading from '../../Components/Loading'
import TitleArea from '../../Components/TitleArea'
import SearchBar from '../../Components/SearchBar'
import ProductsGrid from '../../Components/ProductsGrid'
import {
  Color,
  Padding,
  ContentContainer,
  ContentWrapper,
  GridContainer,
  GridItem,
  P,
  Span,
} from '../../../styles'
import {
  arrEmpty,
  useWindowSize,
} from '../../Helpers'

export default function SearchResultsTemplate({
  title,
  content, // the current search keyword
  updateResults,
}) {
  // router
  const router = useLocation()
  // states
  const [keyword, setKeyword] = useState()
  const [products, setProducts] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  // vars
  const windowSize = useWindowSize()
  let allowedToLoad = true

  useEffect(() => {
    if (content) {
      setIsLoading(true)
      setKeyword(content)
      title.title = 'SEARCH: ' + content
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[content]) // on keyword change

  // get the results
  useEffect(() => {
    const loadProducts = async () => {
      setIsLoading(true)
      try {
        const API = new ProductsAPIClient();
        const response = await API.getProductsByKeyword(keyword)
        if (true === response.success) {
          // set products
          setProducts(response.products)
        } else {
          setProducts(null)
        }
      } catch(err) {
        console.error(err)
      }
      setIsLoading(false)
    }

    // do not call the header yet.
    (router
      && '_error' !== router.pathname)
      && null !== keyword
      && '' !== keyword
      && allowedToLoad
      && loadProducts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, [keyword])

  return (<ContentContainer bgColor={Color.white} >
    <TitleArea title={title} />
    <SearchBar keyword={keyword || null} />
    <ContentWrapper
      currDevice={windowSize.currDevice}
    >
      <GridContainer
        gap={`0`}
        columns={`100%`}
        rows={`1`}
        padding={`0 0 ${Padding.padding3}`}
      >
        <GridItem>
          {isLoading
            ? <Loading />
            : !arrEmpty(products)
              ? <>
                <P margin={`3vw auto 3vw 0`} >
                  {1 === products.length
                    ? `${products.length} result found for `
                    : `${products.length} results found for `}
                    <Span weight={`600`} >
                      {keyword}
                    </Span>
                </P>
                <ProductsGrid
                  sortBy={'date'}
                  products={products}
                  showDescription={true}
                  showButtons={true}
                  numGridCols={4}
                />
              </>
              : <P margin={`3vw auto 3vw 0`} >
                {'No results found for '}
                <Span weight={`600`} >
                  {keyword}
                </Span>
                {'. Make sure the product name is correct. Or try doing a simpler search. Still having problems? Contact us.'}
              </P>}
        </GridItem>
      </GridContainer>
    </ContentWrapper>
  </ContentContainer>);
}
