import React, { useState, useRef } from "react";
import { useHistory } from 'react-router-dom'
import {
  arrEmpty,
  useWindowSize,
  isExternal,
} from '../../../Helpers'
import SubMenu from './SubMenu'
import {useSpectraAnalytics} from 'spectra/src/hooks/useSpectraAnalytics'
import {
  MenuItemContainer,
  // MenuItemLink,
  MenuItemAnchor,
  MenuOverlay,
} from '../styles'

export default function MenuItem({
  item,
  setMenuOpen
}) {
  const analytics = useSpectraAnalytics()
  // router
  const router = useHistory()
  // states
  const [isOpen, setIsOpen] = useState(false)
  // refs
  const overlayEl = useRef(null)
  // vars
  const hasChildren = !arrEmpty(item.children)
  const windowSize = useWindowSize()
  const itemAs = 'custom' === item.base_slug
    ? `${item.href}`
    : `/${item.base_slug}/${item.slug}`

  const handleMenuItemClick = () => {
    !hasChildren && setMenuOpen && setMenuOpen(false)
  }

  return (<>
    {isOpen
      && <MenuOverlay
        ref={overlayEl}
        open={isOpen}
        onClick={e => setIsOpen(false)}
        onMouseEnter={() => setIsOpen(false)} // req. for mobile
        onMouseOver={() => setIsOpen(false)} // desktop edge-cases
      />}
    <MenuItemContainer
      onMouseEnter={() => hasChildren && setIsOpen(true)} // req. for mobile
      onMouseOver={() => hasChildren && setIsOpen(true)} // desktop edge-cases
      onMouseLeave={() => setIsOpen(false)}
      currDevice={windowSize.currDevice}
      onClick={() => handleMenuItemClick()}
    >
      {1 >= item.href.length
        ? <MenuItemAnchor
          onClick={() => {
            analytics
            && analytics.logEvent('navigation_click', item)
          }}
          dangerouslySetInnerHTML={{__html: item.title}}
        />
        : <MenuItemAnchor
          onClick={() => {
            analytics
            && analytics.logEvent('navigation_click', item)
            isExternal(itemAs)
            ? window.location = itemAs
            : router.push(itemAs)
          }}
          dangerouslySetInnerHTML={{__html: item.title}}
        />
      }
      {isOpen && hasChildren
        && <SubMenu
          items={item.children || null}
          setMenuOpen={setMenuOpen}
          setSubMenuOpen={setIsOpen}
        />}
    </MenuItemContainer>
  </>);
}
