import React from "react";
import {
  TitleAreaContainer
} from './styles'
import {
  Heading
} from '../../../styles'

export default function TitleArea({
  title
}) {
  return (
    title
      && <TitleAreaContainer
        backgroundImage={title.image}
      >
        <Heading
          type={'h1'}
          children={title.title}
        />
        {/* {outputBreadcrumbs(title.breadcrumbs)} */}
      </TitleAreaContainer>
  );
}
