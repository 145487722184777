import styled from "styled-components";

import {
  P,
} from '../../../../styles'

export const ContactInfo = styled(P).attrs(props => ({
  className: `ContactInfo` 
}))`

`
