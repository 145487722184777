import React from "react";
import { useWindowSize } from '../../Helpers'
import ModuleFactory from '../../Modules/ModuleFactory'
import SidebarModuleFactory from '../../SidebarModules/SidebarModuleFactory'
import TitleArea from '../../Components/TitleArea'
import WYSIWYGContent from '../../Components/WYSIWYGContent'
import {
  Padding,
  ContentContainer,
  ContentWrapper,
  GridContainer,
  GridItem,
} from '../../../styles'

export default function RightSidebarTemplate({
  title,
  content,
  modules,
  sidebarModules,
}) {
  // vars
  const windowSize = useWindowSize()
  let gap = '5%'
  let columns = '70% 25%'
  let rows = 'auto'
  if ('mobile' === windowSize.currDevice) {
    gap = '5em'
    columns = 'repeat(1,100%)'
    rows = 'repeat(2,auto)'
  } else if ('tablet' === windowSize.currDevice) {
    gap = '5em'
    columns = 'repeat(1,100%)'
    rows = 'repeat(2,auto)'
  }

  return (<ContentContainer>
    {'object' === typeof title
      && <TitleArea title={title} />}
    <ContentWrapper
      currDevice={windowSize.currDevice}
    >
      <GridContainer
        gap={gap}
        columns={columns}
        rows={rows}
        padding={`${Padding.padding2} 0`}
      >
        <GridItem>
          <WYSIWYGContent content={content} />
          {modules
            && modules.map((module, i) => (
              <ModuleFactory
                module={module}
                key={i}
              />
            ))}
        </GridItem>
        <GridItem>
          {sidebarModules
            && sidebarModules.map((module, i) => (
              <SidebarModuleFactory
                key={i}
                module={module}
              />
            ))}
        </GridItem>
      </GridContainer>
    </ContentWrapper>
  </ContentContainer>);
}
