import React, { useState, useRef } from "react";
import { formConfig } from './Form.config'
import ContactFormAPIClient from '../../../../api/clients/contactForm'
import {
  Color,
  Padding,
  ContentContainer,
  Heading,
  FormContainer,
  InputLabel,
  // InputReqLabel,
  // REQ_TEXT,
  InputText,
  Button,
  MailSuccess,
  MailError,
  ReqMessage,
  ContentSpacer,
} from '../../../../styles'
import {
  validateMultipleEmails,
  validateEmail,
  useLocation,
} from '../../../Helpers'

export default function EmailAFriend({
  title,
  content = null
}) {
  // state
  const [mailSuccess, setMailSuccess] = useState(false)
  const [mailFailed, setMailFailed] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // const [formInputEmail, setFormInputEmail] = useState('')
  // refs
  const inputEmailEl = useRef(null)
  const inputFromEl = useRef(null)
  // location
  const location = useLocation()
  // vars
  const inputBorderStyle = `1px solid ${Color.gray}`;
  const inputMarginStyle = `0 0 3% 0`;
  const inputPaddingStyle = `0.65em`;

  const handleFormSubmission = e => {
    // Prevent the default action
    e.preventDefault()
    e.stopPropagation()
    // disable btn
    setIsLoading(true)

    const allEmailsValid = validateMultipleEmails(inputEmailEl.current.value)
    const fromEmailValid = validateEmail(inputFromEl.current.value)
    let productURLs = ''
    // if content included we're on the favs page so include all products in the content
    // else simply use the current url as we're on the produt page itself...
    content
      ? content.map((prod, idx) => (
        productURLs = productURLs + `<br> - ${location.origin}/product/${prod.name}`
      ))
      : productURLs = `<br> - ${location.href}`

    if (
      false !== allEmailsValid
      && false !== fromEmailValid
      && '' !== productURLs
    ) {
      submitForm({
        toClient: allEmailsValid,
        subject: 'Email This Product',
        bodyClient: `Hi,<br><br>You've been sent the following product(s) by a friend (${inputFromEl.current.value}):<br> ${productURLs}`,
        bodyAdmin: `Hi,<br><br>The following product(s) have been sent to a friend list:<br> ${productURLs}`,
        url: location.href,
      })
    } else {
      setMailSuccess(false)
      setMailFailed(true)
      setIsLoading(false)
      setIsError(false)
    }
  }

  const submitForm = async (formData) => {
    // console.log('formData', formData)
    try {
      const API = new ContactFormAPIClient()
      const response = await API.submitContactForm(formData)
      // console.log('response', response)
      // handle response if successful
      if (true === response.success) {
        setMailSuccess(true)
        setMailFailed(false)
        setIsError(false)
        resetForm()
      } else {
        setMailSuccess(false)
        setMailFailed(false)
        setIsError(true)
      }
    } catch(err) {
      setMailSuccess(false)
      setMailFailed(false)
      setIsError(true)
      console.error(err)
    }
    setIsLoading(false)
  }

  const resetForm = () => {
    inputEmailEl.current.value = '';
    inputFromEl.current.value = '';
  }

  return (<ContentContainer>
    <Heading
      type={'h4'}
      children={title}
      color={`blue`}
    />
    <FormContainer
      action={'#'}
      padding={`${Padding.padding0} 0`}
    >
      <ReqMessage>
        {'All fields are required.'}
      </ReqMessage>
      <InputLabel>
        {formConfig.form_fields.email.label}
        {/* {formConfig.form_fields.email.required
          && <InputReqLabel>{REQ_TEXT}</InputReqLabel>} */}
      </InputLabel>
      <InputText
        ref={inputEmailEl}
        name={formConfig.form_fields.email.name}
        type={'email'}
        placeholder={formConfig.form_fields.email.placeholder}
        required={true}
        margin={inputMarginStyle}
        padding={inputPaddingStyle}
        border={inputBorderStyle}
      />
      <InputLabel>
        {formConfig.form_fields.from.label}
        {/* {formConfig.form_fields.from.required
          && <InputReqLabel>{REQ_TEXT}</InputReqLabel>} */}
      </InputLabel>
      <InputText
        ref={inputFromEl}
        name={formConfig.form_fields.from.name}
        type={'email'}
        placeholder={formConfig.form_fields.from.placeholder}
        required={true}
        margin={inputMarginStyle}
        padding={inputPaddingStyle}
        border={inputBorderStyle}
      />
      {mailFailed
        && <MailError>
          {formConfig.mailFail}
        </MailError>
      }
      {isError
        && <MailError>
          {formConfig.formError}
        </MailError>
      }
      {mailSuccess
        && <MailSuccess>
          {formConfig.mailSent}
        </MailSuccess>
      }
      <ContentSpacer height={`65px`} />
      <Button
        type={'submit'}
        onClick={(e) => handleFormSubmission(e)}
        disabled={isLoading}
      >
        {'Send'}
      </Button>
    </FormContainer>
  </ContentContainer>);
}
