import React from "react";
// import App from 'next/app'
import { CookiesProvider } from 'react-cookie';
import QuamRouter from './router'
import '../scss/app.css'
import '../scss/print.scss'

function QuamApp(props) {

  return (<CookiesProvider>
      <QuamRouter />
  </CookiesProvider>);
}

// QuamApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
// console.log(appProps)
//   return { ...appProps }
// }

export default QuamApp;
