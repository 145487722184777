import React, { useState, useEffect, useRef } from "react";
import ProductsAPIClient from '../../../../api/clients/products'
import {
  arrEmpty,
  mergeAndRemoveDuplicatesByKey,
  useWindowSize,
} from '../../../Helpers'
import ProductSlideItemContainer from './ProductSlideItemContainer'
import SVGWrapper from '../../../Components/SVGWrapper'
import {
  Container,
} from './styles'
import {
  Heading,
  SlideContainer,
  SlideWrapper,
  SlideLeft,
  SlideRight,
  Widths,
} from '../../../../styles'
import arrowLeft from '../../../../assets/images/icons/Quam-Icon-LeftArrow.svg'
import arrowRight from '../../../../assets/images/icons/Quam-Icon-RightArrow.svg'

/**
 *
 * @param {string} title
 * @param {array} products
 *  Takes priority; if products are sent we don't utilise 'productTypes'.
 * @param {array} productTypes
 *  Either;
 *    a) [{
        acf_fc_layout: "product_group",
        group: 5
      }]
 *    b) [{
        acf_fc_layout: "product_ids",
        ids: [92, 173]
      }]
 */
export default function ProductsSlider({
  title,
  products,
  productTypes,
  slimline = false,
}) {
  // refs
  const slideWrapperEl = useRef()
  // states
  const [sliderProducts, setSliderProducts] = useState(products || [])
  // eslint-disable-next-line no-unused-vars
  const [sliderLeftVisible, setSliderLeftVisible] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [sliderRightVisible, setSliderRightVisible] = useState(true)
  // vars
  const windowSize = useWindowSize()

  let allowedToLoad = true;

  useEffect(() => {

    const loadProducts = async () => {
      const productGroupIds = [];
      const productIds = [];
      let fetchedProducts = [];

      // gather required ids for API calls
      productTypes.forEach(productType => {
        // get group ids
        productType.acf_fc_layout === 'product_group'
          && productGroupIds.push(productType.group);
        // get product ids
        productType.acf_fc_layout === 'product_ids'
          && productIds.push(productType.ids);
      });

      try {
        // instantiate API class
        const API = new ProductsAPIClient()

        // get prods by group ids
        if (!arrEmpty(productGroupIds)) {
          const response1 = await API.getProductsByGroupId(productGroupIds)
          if (
            true === response1.success
            && 0 < response1.products.length
          ) {
            fetchedProducts = mergeAndRemoveDuplicatesByKey(
              fetchedProducts,
              response1.products,
              'id'
            )
          }
        }

        // get prods by ids
        if (!arrEmpty(productIds)) {
          const response2 = await API.getProductsById(productIds)
          if (
            true === response2.success
            && 0 < response2.products.length
          ) {
            fetchedProducts = mergeAndRemoveDuplicatesByKey(
              fetchedProducts,
              response2.products,
              'id'
            )
          }
        }

        // set prods to state
        setSliderProducts(fetchedProducts)
      } catch(error) {
        console.error(error)
        return []
      }
    }

    allowedToLoad
      && arrEmpty(sliderProducts) // products AREN'T provided
      && !arrEmpty(productTypes) // an array of product types ARE provided
      && loadProducts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, []);

  const handleThumbnailScroll = (scrollDirection = 'right') => {
    const horizontalContainer = slideWrapperEl.current
    if (!horizontalContainer) return null;
    // if mobile, scroll a full container width, else scroll a half container width
    const scrollAmountDivision = 'mobile' === windowSize.currDevice ? 1 : 2
    // px currently scrolled, MINUS half the container width
    // px currently scrolled, PLUS half the container width
    const scrollAmount = 'left' === scrollDirection
      ? horizontalContainer.scrollLeft - (horizontalContainer.offsetWidth / scrollAmountDivision)
      : horizontalContainer.scrollLeft + (horizontalContainer.offsetWidth / scrollAmountDivision)
    horizontalContainer.scroll({
      top: 0,
      left: scrollAmount, // TODO: add a slight buffer in here?
      behavior: 'smooth'
    })
    // show/hide left/right arrows
    // if (horizontalContainer.scrollLeft) {}
  }

  const arrowTop = 'mobile' === windowSize.currDevice
    ? `30%`
    : `26%`

  return (
    <Container
      slimline={slimline}
    >
      {title
        && <Heading
          type={'h2'}
          children={title}
        />}
      <SlideContainer>
        <SlideLeft
          visible={sliderLeftVisible}
          onClick={() => handleThumbnailScroll('left')}
          top={arrowTop}
        >
          <SVGWrapper
            src={arrowLeft}
            height={Widths.icons.small}
          />
        </SlideLeft>
        <SlideWrapper
          ref={slideWrapperEl}
          padding={`0`}
        >
          {sliderProducts
            && sliderProducts.map((product, index) => {
              return <ProductSlideItemContainer
                key={index}
                product={product}
              />
            })}
        </SlideWrapper>
        <SlideRight
          visible={sliderRightVisible}
          onClick={() => handleThumbnailScroll('right')}
          top={arrowTop}
        >
          <SVGWrapper
            src={arrowRight}
            height={Widths.icons.small}
          />
        </SlideRight>
      </SlideContainer>
    </Container>
  );
}
