import React from 'react';
import ReactDOM from 'react-dom';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';

// import {firebaseInit} from './firebase'
//
// const firebaseConfig = {
//   apiKey: "AIzaSyCbU1zCwEpTGvwgflwFfEh6j04ZBCMbFfo",
//   authDomain: "quam-6258f.firebaseapp.com",
//   databaseURL: "https://quam-6258f.firebaseio.com",
//   projectId: "quam-6258f",
//   storageBucket: "quam-6258f.appspot.com",
//   messagingSenderId: "473404444713",
//   appId: "1:473404444713:web:885f64d8c2e87d76374c38",
//   measurementId: "G-GY0YV1EJJ2"
// };
//
// firebaseInit(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
