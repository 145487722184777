import styled from "styled-components";

import {
  Block,
  Color,
  GridContainer,
  GridItem,
  P,
  restrictedWidth
} from '../../../../styles'

export const Container = styled(Block)`
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color.lightGray};

  h2 {
    margin-bottom: 1.1em;
  }
`;

export const InnerContainer = styled(GridContainer)`
  ${restrictedWidth}
`;

export const Icon = styled(GridItem)`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  cursor: ${props => props.clickable
    ? `pointer;`
    : `default;`}

    img {
      width: 18em;
    }
`;

export const IconText = styled(P).attrs(props => ({
  className: `IconText`,
  currDevice: props.currDevice || 'desktop',
}))`
  font-size: ${props => 'mobile' === props.currDevice ? `1.6em` : `2.2em`};
  margin: 1.55em 10% 0;
  font-weight: bold;
  text-align: center;
`;
