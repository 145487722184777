import axios from 'axios'
import api from '../index'
import APIClient from './base'

class TechnicalFilesAPIClient extends APIClient {

  constructor (base64Auth) {
    super(base64Auth)
    this.endpoint = `${api.quamURL}/technical-files`;
  }

  async getTechnicalFiles (page = 1, filename = '', filetype = '') {
    const url = `${this.endpoint}`
    const response = await axios.get(url, {
      params: {
        page: page,
        filename: filename,
        filetype: filetype
      }
    })
    return response.data
  }
}

export default TechnicalFilesAPIClient
