import styled from "styled-components";

import {
  Color,
  FontWeight,
  Widths,
  Block,
  H4,
  P,
  InputRadioRound,
  InputLabel,
  // FontSize,
  // FontWeight,
  Rollover,
} from '../../../styles'

export const Container = styled(Block).attrs(props => ({
  className: 'CategoryFiltersContainer'
}))`
  padding: 0 0 1em;
`

export const FiltersTitleWrapper = styled(Block)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 18px;
`

export const Title = styled(H4)`
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 0;
  color: ${Color.blue};
`

export const FiltersWrapper = styled(Block)`
  padding: 0;
  overflow: hidden;
  transition: 0.2s;
  
  height: ${props => props.filtersOpen ? `auto` : `0`};
  
  @media screen and (min-width: ${Widths.minLaptop}) {
    height: auto !important;
  }
`

export const FilterContainer = styled(Block)`
  padding: 0em 0 0.4em;
`

export const FilterTitle = styled(H4)`
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 15px;
`

export const FiltersToggle = styled(Block)`
  font-size: 2.5em;
  margin-left: 1.2em;
  
  @media screen and (min-width: ${Widths.minLaptop}) {
    display: none;
  }
`

export const CloseFilterDrawer = styled(Block)`
  font-size: 1.2em;
  margin: 75px auto 30px;
  color: ${Color.red};
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
  
  @media screen and (min-width: ${Widths.minLaptop}) {
    display: none;
  }
`

export const FilterContent = styled(Block)``

export const FilterOption = styled(Block)``

export const FilterRadio = styled(InputRadioRound)``

export const FilterRadioLabel = styled(InputLabel).attrs(props => ({
  fontWeight: FontWeight.regular
}))`
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  cursor: pointer;

  ${props => props.disabled 
    ? `opacity: 0.5;
       cursor: not-allowed;`
    : ``}
`

export const CtaRightCol = styled(Block)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`

export const CtaWrapper = styled(Block)`
  position: relative;
  cursor: pointer;
  text-align: right;
  margin-top: 1vw;
  margin-bottom: 1vw;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    & > .Rollover {
      opacity: 1;
    }

    p {
      color: ${Color.blue};
    }

    svg {
      fill: ${Color.blue};
      stroke: ${Color.blue};

      path,
      text,
      polygon
      {
        fill: ${Color.blue};
        stroke: ${Color.blue};
      }
    }
  }

  svg {
    fill: ${Color.mediumGray};
    stroke: ${Color.mediumGray};

    path,
    text,
    polygon
    {
      fill: ${Color.mediumGray};
      stroke: ${Color.mediumGray};
    }
  }
`

export const CtaText = styled(P)`
  display: inline-block;
  margin: 0.1em 0 0 0.5em;
`

export const CtaRollover = styled(Rollover)``
