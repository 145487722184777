import React, {useState, useEffect} from "react";
import { useHistory } from 'react-router-dom'
import TemplateFactory from '../Templates/TemplateFactory'
import BlogAPIClient from '../../api/clients/blog'
import { arrEmpty } from '../Helpers'
import Loading from '../Components/Loading'

export default function Blog() {
  // router
  const router = useHistory()
  // state
  const [isLoading, setIsLoading] = useState(false)
  const [titleArea, setTitleArea] = useState(null)
  const [posts, setPosts] = useState(null)
  // vars
  let allowedToLoad = true;

  // get the banner content
  useEffect(() => {
    const loadBanner = async () => {
      setIsLoading(true)
      try {
        const API = new BlogAPIClient();
        const response = await API.getPosts();
        if (response) {
          // set title area
          setTitleArea({
            title: `The Know Quam Blog` || null,
            image: null,
            breadcrumbs: null
          })
          setPosts(response);
        }
      } catch(err) {
        console.error(err)
      }
      setIsLoading(false)
    }

    // when safe to load
    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && loadBanner()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, []) // run once initially

  // early exit
  if (arrEmpty(titleArea)) return null
    
  return (isLoading
    ? <Loading inclIcon={true} />
    : <TemplateFactory
      template={'blog-template'}
      title={titleArea}
      modules={posts}
    />)
}
