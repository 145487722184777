import React from 'react'
import Image from './sidebarModules/Image'
import Text from './sidebarModules/Text'

export default function SidebarModuleFactory({ module }) {
  let moduleComponent = null;
  switch (module.section_name) {
    case "image":
      // image
      moduleComponent = <Image
        image={module.image}
      />;
      break;
      
    case "text":
      // text
      moduleComponent = <Text
        text={module.text}
        bgColor={module.background_color}
      />;
      break;
    default:
      break
  }
  return moduleComponent;
}
