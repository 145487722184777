import styled from "styled-components";

import {
  Block,
  InputNum,
  Widths,
} from '../../../styles'

export const ButtonsContainer = styled(Block).attrs(props => ({
  className: 'ProductButtonsContainer'
}))`
  display: ${props => props.display ? props.display : 'block'};
  text-align: center;
  width: 100%;

  & > button {
    span {
      top: ${props => props.display === 'flex' ? '1.25rem !important' : 'initial'};
    }
  }

  @media screen and (max-width: ${Widths.maxMobile}) {
    flex-direction: ${props => props.display === 'flex' ? 'column' : 'row'};

    & > a,
    & > button {
      margin: 0 auto 0.2em;
    }
  }

  @media screen and (min-width: ${Widths.minTablet}) {
    text-align: left;
  }
`;

export const QtyInput = styled(InputNum)`
  text-align: center;
  padding: 1.05em 0.3em;
`;
