import React from "react"
import WYSIWYGContent from '../WYSIWYGContent'

import {
    WrapPost,
    Title,
    Content,
    Footer,
} from './styled'

export default function PostModule({ item }) {
    const { jetpack_featured_media_url, excerpt, slug, title } = item;
    
    const categories = item._embedded['wp:term'].find((section) => section.find((children) => children.taxonomy === 'category'));
    const tags = item._embedded['wp:term'].find((section) => section.find((children) => children.taxonomy === 'post_tag'));
    
    return (
        <WrapPost>
            <a href={`post/${slug}`} className="wrapImage">
                <img src={jetpack_featured_media_url} alt={slug} />
            </a>
            <Content>
                <div>
                    {categories && 
                        <h4 className="categories">{categories.map((cat) => <span key={cat.id}>{cat.name}</span>)}</h4>
                    }
                    
                    <Title>
                        <a href={`post/${slug}`}>
                            {title.rendered}
                        </a>
                    </Title>
                    
                    <WYSIWYGContent content={excerpt.rendered} />
                </div>
                
                <Footer>
                    <a href={`post/${slug}`}>
                        Read more
                    </a>
                    {tags && 
                        <p className="tags">{tags.map((tag) => <span key={tag.id}>{tag.name}</span>)}</p>
                    }
                </Footer>
            </Content>
        </WrapPost>
    )
}
