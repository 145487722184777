export const formConfig = {
  form_fields: {
      firstName: {
        label: 'First Name ',
        name: 'firstName',
        required: true
      },
      lastName: {
        label: 'Last Name ',
        name: 'lastName',
        required: true
      },
      title: {
        label: 'Title ',
        name: 'title',
        required: true
      },
      phone: {
        label: 'Phone ',
        name: 'phone',
        required: true
      },
      email: {
        label: 'Business Email ',
        name: 'email',
        required: true
      },
      message: {
        label: 'Message ',
        name: 'message',
        required: true
      },
  },
  mailSent: 'Thanks for reaching out! We will be in touch shortly.',
  mailError: 'Please check all required fields are filled and valid, and try again.'
}
