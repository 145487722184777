import React, {useState, useEffect} from "react";
import CategoryTile from './CategoryTile'
import ProductTaxAPIClient from '../../../../api/clients/productTax'
import {
  Container,
} from "./styles";
import {
  GridContainer,
  GridItem,
  Heading,
} from '../../../../styles'
import { useWindowSize } from '../../../Helpers'

import FadeInSection from '../../../Components/FadeInSection'

export default function CategoryTiles({
  categoryIds,
  tilesPerRow
}) {
  // states
  const [categories, setCategories] = useState([])
  // vars
  const windowSize = useWindowSize()


  let allowedToLoad = true;

  useEffect(() => {
    const load = async () => {
      try {
        const API = new ProductTaxAPIClient('product-groups');
        const response = await API.getTaxById(categoryIds)
        if (true === response.success) {
          setCategories(response.product_groups)
        }
      } catch(err) {
        console.error(err)
      }
    }

    // do not call the header yet.
    allowedToLoad && load()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, [])

  let numCols = 4
  if ('mobile' === windowSize.currDevice) {
    numCols = 1
  } else if ('tablet' === windowSize.currDevice) {
    numCols = 3
  }
  const numRows = Math.ceil(categoryIds.length / numCols)
  const calcCols = `repeat(${numCols}, auto)`
  const calcRows = `repeat(${numRows}, auto)`

  return (
    <Container
      currDevice={windowSize.currDevice}
    >
      <Heading
        type={'h2'}
        children={'Categories'}
      />
      <GridContainer
        gap={`0.5em`}
        columns={calcCols}
        rows={calcRows}
      >
        {categories
          && categories.map((cat, idx) => (
            <GridItem key={idx}>
            <FadeInSection>
              <CategoryTile 
                cat={cat}
                currDevice={windowSize.currDevice}
              />
              </FadeInSection>
            </GridItem>
        ))}
      </GridContainer>
    </Container>
  );
}
