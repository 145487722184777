import axios from 'axios'
import api from '../index'
import APIClient from './base'

class TaxAPIClient extends APIClient {

  // defaultArgs = {
  //   slug: '',
  //   id: '',
  //   hide_empty: '',
  // };

  constructor (taxName, base64Auth) {
    super(base64Auth)

    this.endpoint = `${api.quamURL}/${taxName}`;
  }

  async getTax(args) {

    const url = api.parseURLRequest(
      `${this.endpoint}/${args.slug}`,
      args
    )

    const response = await axios.get(url)
    return response.data
  }

  async getTaxBySlug(slug) {
    const url = `${this.endpoint}/${slug}`

    const response = await axios.get(url)
    return response.data
  }

  async getTaxById(ids) {
    const url = `${this.endpoint}?id=${ids.join(',')}`

    const response = await axios.get(url)
    return response.data
  }
}

export default TaxAPIClient
