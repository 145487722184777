import styled from 'styled-components'

import {
  Block,
  Image,
  Anchor,
  Padding,
  Widths
} from '../../../styles'

export const LogoBlock = styled(Block).attrs(props => ({
  src: props.logo || '',
  currDevice: props.currDevice || 'desktop',
}))`
  width: ${props => 'small' === props.size
    ? Widths.logo.small
    : ('large' === props.size)
      ? Widths.logo.large
      : Widths.logo.medium
  };
  max-width: 100%;
  margin-top: ${Padding.header.desktop};
  margin-bottom: ${Padding.header.desktop};
`

export const HomeAnchor = styled(Anchor)`
  display: inline-block;
`

export const QuamLogo = styled(Image).attrs(props => ({
  src: props.logo || '',
  alt: 'The Quam Nichols Company Logo'
}))`
  /* max-height: 40px; */
`
