import React from "react";
import { useWindowSize } from '../../Helpers'
import SocialIcons from '../SocialIcons'
import {
  RightColContainer,
  Tagline,
} from './styles'

export default function RightCol({
  tagline,
  socialIcons
}) {
  // vars
  const windowSize = useWindowSize()
  return (<RightColContainer
    currDevice={windowSize.currDevice}
  >
    <Tagline
      currDevice={windowSize.currDevice}
      show={'mobile' !== windowSize.currDevice}
    >
      {tagline}
    </Tagline>
    <SocialIcons icons={socialIcons} />
  </RightColContainer>);
}
