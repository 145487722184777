import React from 'react'
import styled from 'styled-components'
import { getColorByName } from './helpers'

import {
  Font,
  FontSize,
  LineHeight,
  Margin,
  FontWeight,
  Widths,
} from './global'

const hTagAttrs = props => ({
  colorName: props.color || false,
  className: `htag`
})

export const Heading = ({type, children, color, fontWeight, fontSize, tTransform}) => {
  switch(type) {
    case 'h1':
      return <H1
        className={`heading-1`}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        tTransform={tTransform}
      >
        {children}
      </H1>

    case 'h2':
      return <H2
        className={`heading-2`}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        tTransform={tTransform}
      >
        {children}
      </H2>

    case 'h3':
      return <H3
        className={`heading-3`}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        tTransform={tTransform}
      >
        {children}
      </H3>

    case 'h4':
      return <H4
        className={`heading-4`}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        tTransform={tTransform}
      >
        {children}
      </H4>

    case 'h5':
      return <H5
        className={`heading-5`}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        tTransform={tTransform}
      >
        {children}
      </H5>

    case 'h6':
      return <H6
        className={`heading-6`}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        tTransform={tTransform}
      >
        {children}
      </H6>

    default:
      return <H3
        className={`heading-3`}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        tTransform={tTransform}
      >
        {children}
      </H3>
  }
}

export const H1 = styled.h1.attrs(hTagAttrs)`
  font-family: ${Font.secondary};
  font-size: ${FontSize.heading.h1.mobile};
  font-weight: ${props => props.fontWeight
    ? props.fontWeight
    : `${FontWeight.regular}`};
  text-transform: ${props => props.tTransform
    ? props.tTransform
    : `uppercase`};
  color: ${props => getColorByName(props.colorName)};

  @media (min-width: ${Widths.minTablet}) {
    font-size: ${FontSize.heading.h1.tablet};
  }
  @media (min-width: ${Widths.minDesktop}) {
    font-size: ${props => props.fontSize
      ? props.fontSize
      : FontSize.heading.h1.desktop};
  }
`
export const H2 = styled.h2.attrs(hTagAttrs)`
  font-family: ${Font.primary};
  font-size: ${FontSize.heading.h2.mobile};
  font-weight: ${props => props.fontWeight
    ? props.fontWeight
    : `${FontWeight.regular}`};
  text-transform: ${props => props.tTransform
    ? props.tTransform
    : `inherit`};
  margin-bottom: 1.5em;
  color: ${props => getColorByName(props.colorName, 'gray')};

  @media (min-width: ${Widths.minTablet}) {
    font-size: ${FontSize.heading.h2.tablet};
  }
  @media (min-width: ${Widths.minDesktop}) {
    font-size: ${props => props.fontSize
      ? props.fontSize
      : FontSize.heading.h2.desktop};
  }
`
export const H3 = styled.h3.attrs(hTagAttrs)`
  font-family: ${Font.primary};
  font-size: ${FontSize.heading.h3.mobile};
  font-weight: ${props => props.fontWeight
    ? props.fontWeight
    : `${FontWeight.regular}`};
  text-transform: ${props => props.tTransform
    ? props.tTransform
    : `inherit`};
  margin-bottom: 1.6em;
  color: ${props => getColorByName(props.colorName)};

  @media (min-width: ${Widths.minTablet}) {
    font-size: ${FontSize.heading.h3.tablet};
  }
  @media (min-width: ${Widths.minDesktop}) {
    font-size: ${props => props.fontSize
      ? props.fontSize
      : FontSize.heading.h3.desktop};
  }
`
export const H4 = styled.h4.attrs(hTagAttrs)`
  font-family: ${Font.primary};
  font-size: ${FontSize.heading.h4.mobile};
  font-weight: ${props => props.fontWeight
    ? props.fontWeight
    : `${FontWeight.semiBold}`};
  text-transform: ${props => props.tTransform
    ? props.tTransform
    : `uppercase`};
  margin-top: 12px;
  margin-bottom: 12px;
  color: ${props => getColorByName(props.colorName)};

  @media (min-width: ${Widths.minTablet}) {
    font-size: ${FontSize.heading.h4.tablet};
  }
  @media (min-width: ${Widths.minDesktop}) {
    font-size: ${props => props.fontSize
      ? props.fontSize
      : FontSize.heading.h4.desktop};
  }
`
export const H5 = styled.h5.attrs(hTagAttrs)`
  font-family: ${Font.primary};
  font-size: ${FontSize.heading.h5.mobile};
  font-weight: ${props => props.fontWeight
    ? props.fontWeight
    : `${FontWeight.semiBold}`};
  text-transform: ${props => props.tTransform
    ? props.tTransform
    : `uppercase`};
  line-height: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: ${props => getColorByName(props.colorName)};

  @media (min-width: ${Widths.minTablet}) {
    font-size: ${FontSize.heading.h5.tablet};
  }
  @media (min-width: ${Widths.minDesktop}) {
    font-size: ${props => props.fontSize
      ? props.fontSize
      : FontSize.heading.h5.desktop};
  }
`
export const H6 = styled.h6.attrs(hTagAttrs)`
  font-family: ${Font.primary};
  font-size: ${FontSize.heading.h6.mobile};
  font-weight: ${props => props.fontWeight
    ? props.fontWeight
    : `${FontWeight.regular}`};
  text-transform: ${props => props.tTransform
    ? props.tTransform
    : `uppercase`};
  color: ${props => getColorByName(props.colorName)};

  @media (min-width: ${Widths.minTablet}) {
    font-size: ${FontSize.heading.h6.tablet};
  }
  @media (min-width: ${Widths.minDesktop}) {
    font-size: ${props => props.fontSize
      ? props.fontSize
      : FontSize.heading.h6.desktop};
  }
`

export const Paragraph = styled.p.attrs(props => ({
  size: props.size || FontSize.body,
  margin: props.margin || Margin.paragraph,
  colorName: props.color || 'black'
}))`
  font-family: ${Font.primary};
  font-size: ${props => props.size};
  font-weight: normal;
  line-height: ${LineHeight.one};
  margin: ${props => props.margin};
  color: ${props => getColorByName(props.colorName)};
`

export const P = Paragraph

export const Span = styled.span.attrs(props => ({
  size: props.size || 'inherit',
  weight: props.weight || `400`,
  padding: props.padding || `0`,
  colorName: props.color || 'black'
}))`
  font-family: ${Font.primary};
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  padding: ${props => props.padding};
  color: ${props => getColorByName(props.colorName)};
`
