import React, { useState } from "react";
import { useHistory } from 'react-router-dom'
import {
  CategoryBlock,
  CatName
} from "./styles";
import {
  HoverCircle,
} from '../../../../styles'
import { useWindowSize } from '../../../Helpers'

import {useSpectraAnalytics} from 'spectra/src/hooks/useSpectraAnalytics'

export default function CategoryTile({
  cat,
  currDevice
}) {
  // router
  const router = useHistory()
  // states
  const [isActive, setIsActive] = useState(false)
  // vars
  const windowSize = useWindowSize()
  const imgSrc = cat?.tile_image?.sizes?.thumbnail_large || null

  const analytics = useSpectraAnalytics()

  return (<CategoryBlock
      onClick={() => {
        analytics && analytics.logEvent('category_tile_click', {category: cat.name})
        router.push(`/product-group/${cat.slug}`)
      }}
      currDevice={windowSize.currDevice}
      active={isActive}
      onMouseEnter={() => setIsActive(true)} // req. for mobile
      onMouseOver={() => setIsActive(true)} // desktop edge-cases
      onMouseLeave={() => setIsActive(false)}
      backgroundImage={imgSrc}
    >
      <CatName>
        {cat.name}
      </CatName>
      <HoverCircle
        currDevice={currDevice}
        active={isActive}
        padding={'9em'}
        text={'VIEW ALL'}
      />
    </CategoryBlock>);
}
