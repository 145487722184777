import React from "react";
import Calculator from '../../../Components/Calculator'
import {
  Heading,
} from '../../../../styles'
// import {
//   ContactInputLabel,
//   ContactInputText,
//   ContactInputTextArea,
// } from './styles'

export default function CalculatorModule({ title }) {
  return (<>
    <Heading
      type={'h3'}
      children={title}
      color={`blue`}
      fontSize={`3.2em`}
      tTransform={`capitalize`}
    />
    <Calculator />
  </>)
}
