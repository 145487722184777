import styled from "styled-components";

import {
  Color,
  Widths,
  Block,
  ContentContainer,
  SlideContainer,
  SlideWrapper,
  SlideItem,
  SlideLeft,
  SlideRight,
} from '../../../styles'

export const Container = styled(Block)`
  text-align: center;
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

export const GalleryContainer = styled(ContentContainer).attrs(props => ({
  className: 'ProductGalleryContainer'
}))``

export const CurrentSelectionContainer = styled(Block).attrs(props => ({
  className: 'ProductGalleryCurrentSelectionContainer'
}))`
  position: relative;
  text-align: center;
  width: 100%;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
  background-color: ${Color.white};
  background-size: cover;
  background-position: center;
  ${props => props.bgImage
    ? `background-image: url(${props.bgImage})`
    : ``
  };
  ${props => props.inclBackdrop
    ? `background-color: ${Color.black}`
    : ``
  };
`;

export const InternalVideoContainer = styled.video`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  // padding-top: 87%;
  transform: translateY(-50%);
`

export const ExternalVideoContainer = styled(Block)`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  transform: translateY(-128.125%);

  iframe {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
  }
`

export const ThumbnailContainer = styled(SlideContainer)`
  margin: 0 5%;
  width: 90%;
`;

export const ThumbnailWrapper = styled(SlideWrapper)``;

export const ThumbnailScrollLeft = styled(SlideLeft)`
  left: -5%;
`;

export const ThumbnailScrollRight = styled(SlideRight)`
  right: -5%;
`;

export const ThumbnailItem = styled(SlideItem)`
  position: relative;
  border: 2px solid ${props => props.active
    ? `${Color.blue60pc};`
    : `transparent;`}
  margin-top: 3.5%;
  height: 0;
  padding-top: 21.5%;
  cursor: pointer;
  background-color: ${Color.white};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${props => props.bgImage
    && `background-image: url(${props.bgImage});`}

  .SVGContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      fill: ${Color.slateGray};
      stroke: ${Color.slateGray};
      
      path,
      text,
      polygon
      {
        fill: ${Color.slateGray};
        stroke: ${Color.slateGray};
      }
    }
  }

  &:hover {
    svg {
      fill: ${Color.blue};
      stroke: ${Color.blue};
      
      path,
      text,
      polygon
      {
        fill: ${Color.blue};
        stroke: ${Color.blue};
      }
    }
  }
  
  @media screen and (min-width: ${Widths.minTablet}) {
    padding-top: 21.5%;
  }
  @media screen and (min-width: ${Widths.minDesktop}) {
    padding-top: 21.5%;
  }
`;