import styled from "styled-components";

import {
  H2,
  Color
} from '../../../styles'

export const ProdTitle = styled(H2)`
  text-transform: uppercase;
  font-weight: bold;
  color: ${Color.black};
`;