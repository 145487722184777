import styled from "styled-components";

import {
  Block,
  FontSize,
  Color,
  Widths,
  Rollover,
} from '../../../styles'

export const ButtonsContainer = styled(Block).attrs(props => ({
  className: 'ProductCtasContainer'
}))`
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Color.gray};
  max-width: 100%;
  background: ${Color.transparent};
  
  @media screen and (min-width: ${Widths.minTablet}) {
    max-width: 95%;
  }
`;

export const CtaButton = styled(Block)`
  position: relative;
  text-align: left;
  width: auto;
  font-size: ${FontSize.body};
  color: ${Color.mediumGray};
  cursor: pointer;
  background: ${Color.transparent};
  
  &:hover {
    & > .Rollover {
      opacity: 1;
    }

    svg {
      fill: ${Color.blue};
      stroke: ${Color.blue};
      
      path,
      text,
      polygon
      {
        fill: ${Color.blue};
        stroke: ${Color.blue};
      }
    }
  }

  svg {
    fill: ${Color.mediumGray};
    stroke: ${Color.mediumGray};
    
    path,
    text,
    polygon
    {
      fill: ${Color.mediumGray};
      stroke: ${Color.mediumGray};
    }
  }
`;

export const CtaButtonText = styled(Block)`
  text-align: left;
  width: auto;
  font-size: ${FontSize.body};
  color: ${Color.mediumGray};
  cursor: pointer;
  background: ${Color.transparent};
  transition: 0.2s;
    
  &:hover {
    color: ${Color.blue};
    text-shadow: 0 0 0.5px;
    text-decoration: none;
  }
`;

export const CtaRollover = styled(Rollover)``
