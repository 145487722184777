import styled from "styled-components";

import {
  Block,
  LineHeight,
  Font,
  FontSize,
  FontWeight,
  Widths,
  Color,
  Padding,
} from '../../../styles'

export const WYSIWYGContentContainer = styled(Block)`
  padding: ${props => props.slimline ? `0 0 0` : `${Padding.padding2} 0`};

  p,
  ul,
  ol {
    font-family: ${Font.primary};
    font-weight: normal;
    line-height: ${LineHeight.one};
    font-size: ${FontSize.body};
    color: ${props => props.darkTheme ? `${Color.white}` : `${Color.black}`};
  }

  p {
    margin: 1em 0;
  }

  a {
    color: ${Color.red};
    font-weight: ${FontWeight.bold};
    text-decoration: none;
  }

  h1 {
    font-family: ${Font.secondary};
    font-size: ${FontSize.heading.h1.mobile};
    font-weight: ${FontWeight.regular};
    text-transform: uppercase;
    color: ${props => props.darkTheme ? `${Color.white}` : `${Color.blue}`};

    @media (min-width: ${Widths.minTablet}) {
      font-size: ${FontSize.heading.h1.tablet};
    }
    @media (min-width: ${Widths.minDesktop}) {
      font-size: ${FontSize.heading.h1.desktop};
    }
  }

  h2 {
    font-family: ${Font.primary};
    font-size: ${FontSize.heading.h2.mobile};
    font-weight: ${FontWeight.regular};
    // text-transform: capitalize;
    margin-top: 1.5em;
    margin-bottom: 0.4em;
    color: ${props => props.darkTheme ? `${Color.white}` : `${Color.blue}`};

    @media (min-width: ${Widths.minTablet}) {
      font-size: ${FontSize.heading.h2.tablet};
    }
    @media (min-width: ${Widths.minDesktop}) {
      font-size: ${FontSize.heading.h2.desktop};
    }
  }

  h3 {
    font-family: ${Font.primary};
    font-size: ${FontSize.heading.h3.mobile};
    font-weight: ${FontWeight.regular};
    // text-transform: capitalize;
    margin-top: 1.6em;
    margin-bottom: 0.5em;
    color: ${props => props.darkTheme ? `${Color.white}` : `${Color.blue}`};

    @media (min-width: ${Widths.minTablet}) {
      font-size: ${FontSize.heading.h3.tablet};
    }
    @media (min-width: ${Widths.minDesktop}) {
      font-size: ${FontSize.heading.h3.desktop};
    }
  }

  h4 {
    font-family: ${Font.primary};
    font-size: ${FontSize.heading.h4.mobile};
    font-weight: ${FontWeight.regular};
    text-transform: uppercase;
    margin-top: 12px;
    margin-bottom: 12px;
    color: ${props => props.darkTheme ? `${Color.white}` : `${Color.blue}`};

    @media (min-width: ${Widths.minTablet}) {
      font-size: ${FontSize.heading.h4.tablet};
    }
    @media (min-width: ${Widths.minDesktop}) {
      font-size: ${FontSize.heading.h4.desktop};
    }
  }

  h5 {
    font-family: ${Font.primary};
    font-size: ${FontSize.heading.h5.mobile};
    font-weight: ${FontWeight.regular};
    text-transform: uppercase;
    line-height: 1.8em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    color: ${props => props.darkTheme ? `${Color.white}` : `${Color.blue}`};

    @media (min-width: ${Widths.minTablet}) {
      font-size: ${FontSize.heading.h5.tablet};
    }
    @media (min-width: ${Widths.minDesktop}) {
      font-size: ${FontSize.heading.h5.desktop};
    }
  }

  h6 {
    font-family: ${Font.primary};
    font-size: ${FontSize.heading.h6.mobile};
    font-weight: ${FontWeight.regular};
    text-transform: uppercase;
    color: ${props => props.darkTheme ? `${Color.white}` : `${Color.blue}`};

    @media (min-width: ${Widths.minTablet}) {
      font-size: ${FontSize.heading.h6.tablet};
    }
    @media (min-width: ${Widths.minDesktop}) {
      font-size: ${FontSize.heading.h6.desktop};
    }
  }

  .wp-block-button {
    
    &:not(.is-style-outline) {
      a { border: 0; }
    }

    &.is-style-outline {
      a { border: 1px solid; }
    }

    a {
      position: relative;
      display: inline-block;
      font-family: ${Font.primary};
      font-size: ${FontSize.button.large};
      font-weight: ${FontWeight.regular};
      text-align: center;
      box-sizing: border-box;
      padding: 1em 0.5em;
      margin: 0;
      cursor: pointer;
      white-space: normal;
      width: 100%;
      max-width: ${Widths.maxBtn};
      text-decoration: none;
      line-height: normal;
      transition: 0.2s;

      // default color scheme
      color: ${Color.white};
      background-color: ${Color.red};

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:focus {
        outline: none;
      }
    }

    .has-pale-pink-background-color {
      background-color: #f78da7; }
    .has-vivid-red-background-color {
      background-color: #cf2e2e; }
    .has-luminous-vivid-orange-background-color {
      background-color: #ff6900; }
    .has-luminous-vivid-amber-background-color {
      background-color: #fcb900; }
    .has-light-green-cyan-background-color {
      background-color: #7bdcb5; }
    .has-vivid-green-cyan-background-color {
      background-color: #00d084; }
    .has-pale-cyan-blue-background-color {
      background-color: #8ed1fc; }
    .has-vivid-cyan-blue-background-color {
      background-color: #0693e3; }
    .has-vivid-purple-background-color {
      background-color: #9b51e0; }
    .has-very-light-gray-background-color {
      background-color: #eee; }
    .has-cyan-bluish-gray-background-color {
      background-color: #abb8c3; }
    .has-very-dark-gray-background-color {
      background-color: #313131; }
    .has-pale-pink-color {
      color: #f78da7; }
    .has-vivid-red-color {
      color: #cf2e2e; }
    .has-luminous-vivid-orange-color {
      color: #ff6900; }
    .has-luminous-vivid-amber-color {
      color: #fcb900; }
    .has-light-green-cyan-color {
      color: #7bdcb5; }
    .has-vivid-green-cyan-color {
      color: #00d084; }
    .has-pale-cyan-blue-color {
      color: #8ed1fc; }
    .has-vivid-cyan-blue-color {
      color: #0693e3; }
    .has-vivid-purple-color {
      color: #9b51e0; }
    .has-very-light-gray-color {
      color: #eee; }
    .has-cyan-bluish-gray-color {
      color: #abb8c3; }
    .has-very-dark-gray-color {
      color: #313131; }
    .has-vivid-cyan-blue-to-vivid-purple-gradient-background {
      background: linear-gradient(135deg, #0693e3 0%, #9b51e0 100%); }
    .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
      background: linear-gradient(135deg, #00d084 0%, #0693e3 100%); }
    .has-light-green-cyan-to-vivid-green-cyan-gradient-background {
      background: linear-gradient(135deg, #7adcb4 0%, #00d082 100%); }
    .has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
      background: linear-gradient(135deg, #fcb900 0%, #ff6900 100%); }
    .has-luminous-vivid-orange-to-vivid-red-gradient-background {
      background: linear-gradient(135deg, #ff6900 0%, #cf2e2e 100%); }
    .has-very-light-gray-to-cyan-bluish-gray-gradient-background {
      background: linear-gradient(135deg, #eeeeee 0%, #a9b8c3 100%); }
    .has-cool-to-warm-spectrum-gradient-background {
      background: linear-gradient(135deg, #4aeadc 0%, #9778d1 20%, #cf2aba 40%, #ee2c82 60%, #fb6962 80%, #fef84c 100%); }
    .has-blush-light-purple-gradient-background {
      background: linear-gradient(135deg, #ffceec 0%, #9896f0 100%); }
    .has-blush-bordeaux-gradient-background {
      background: linear-gradient(135deg, #fecda5 0%, #fe2d2d 50%, #6b003e 100%); }
    .has-purple-crush-gradient-background {
      background: linear-gradient(135deg, #34e2e4 0%, #4721fb 50%, #ab1dfe 100%); }
    .has-luminous-dusk-gradient-background {
      background: linear-gradient(135deg, #ffcb70 0%, #c751c0 50%, #4158d0 100%); }
    .has-hazy-dawn-gradient-background {
      background: linear-gradient(135deg, #faaca8 0%, #dad0ec 100%); }
    .has-pale-ocean-gradient-background {
      background: linear-gradient(135deg, #fff5cb 0%, #b6e3d4 50%, #33a7b5 100%); }
    .has-electric-grass-gradient-background {
      background: linear-gradient(135deg, #caf880 0%, #71ce7e 100%); }
    .has-subdued-olive-gradient-background {
      background: linear-gradient(135deg, #fafae1 0%, #67a671 100%); }
    .has-atomic-cream-gradient-background {
      background: linear-gradient(135deg, #fdd79a 0%, #004a59 100%); }
    .has-nightshade-gradient-background {
      background: linear-gradient(135deg, #330968 0%, #31cdcf 100%); }
    .has-midnight-gradient-background {
      background: linear-gradient(135deg, #020381 0%, #2874fc 100%); }
  }

  .wp-block-image {
    .aligncenter img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .alignleft img {
      float: left;
    }

    .alignright img {
      float: right;
    }

    // standard width
    :not(.alignwide):not(.alignfull) img {
      // todo
    }

    .alignwide img {
      // todo
    }

    .alignfull img {
      // todo
    }
  }

  .wp-block-cover {
    .wp-block-cover__inner-container {
      padding: 9vw 6vw;

      .has-large-font-size {
        font-size: 4em;
      }

      .has-text-align-left {
        text-align: left;
      }

      .has-text-align-right {
        text-align: right;
      }

      .has-text-align-center {
        text-align: center;
      }
    }

    &.has-background-dim {
      color: #FFF;
    }
  }

  // .wp-block-gallery {
  //   .blocks-gallery-grid {
  //     display: flex;
  //     flex-flow: row wrap;

  //     .blocks-gallery-item {
  //       box-sizing: border-box;
  //       padding: 0.5em;
  //       float: left;
  //     }

  //     &:after {
  //       content: '';
  //       display: block;
  //       clear: both;
  //     }
  //   }
  //   // add styles for columns
  //   @for $i from 1 through 8 {
  //     &.columns-#{$i} .blocks-gallery-item {
  //       width: (100 / $i) + 0%;
  //     }
  //   }
  // }

  ul:not(.blocks-gallery-grid) {
    list-style: disc;
    padding-left: 3vw;
  }

  .wp-block-text-columns,
  .wp-block-columns {
    display: flex;
    flex-flow: row wrap;

    .wp-block-column {
      box-sizing: border-box;
      min-width: 100%;

      @media screen and (min-width: 600px) {
        min-width: 30%;
      }

      @media screen and (min-width: 1024px) {
        min-width: auto;
      }
    }
  }

  .wp-block-button {
    // todo
  }

  .wp-block-quote {
    cite {
      font-size: 1.3em;
      margin-top: 1.3em;
      opacity: 0.6;
    }

    &:not(.is-large) {
      border-left: 4px solid #000;
      margin: 0 0 28px;
      padding-left: 1em;
    }

    &.is-large {
      margin: 0 0 28px;
      padding-left: 1em;

      p {
        font-size: 2.4em;
      }
    }
  }

  .wp-block-code {
    font-family: Menlo,Consolas,monaco,monospace;
    font-size: 1.4em;
    padding: 0.8em 1em;
    border: 1px solid #e2e4e7;
    border-radius: 4px;
  }

  .wp-block-audio {
    audio {
      width: 100%;
    }
  }

  .wp-block-video {
    video {
      width: 100%;
    }
  }

  .wp-block-preformatted {
    font-family: Menlo,Consolas,monaco,monospace;
    font-size: 1.4em;
    white-space: pre-wrap;
  }
`;
