import axios from 'axios'
import api from '../index'
import APIClient from './base'

class ProductAPIClient extends APIClient {

  constructor (base64Auth) {
    super(base64Auth)

    this.endpoint = `${api.quamURL}/product`;
  }

  async getProductBySlug (slug, includeRelatedProducts = true) {
    const url = `${this.endpoint}/?slug=${slug}&include_related_products=${includeRelatedProducts}`

    const response = await axios.get(url)
    return response.data
  }

  // async getProductById (ids) {
  //   const url = `${this.endpoint}/id=${ids.join(',')}`

  //   const response = await axios.get(url)
  //   return response.data
  // }
}

export default ProductAPIClient
