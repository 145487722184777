import styled from 'styled-components'

export const GridPost = styled.section.attrs({
  className: 'GridPost',
})`
    display: blog;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    max-width: 1000px;
    
    @media screen and (min-width: 1200px) {
      margin: 2rem auto;
    }
    
    .wrapBreadcrumb {
      text-align: center;
      
      a {
        font-weight: bold;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
`;


export const FeaturedImage = styled.div.attrs({
  className: 'FeaturedImage',
})`
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const PostContent = styled.div.attrs({
  className: 'PostContent',
})`
    margin-bottom: 2rem;
`;

export const Taxonomies = styled.div.attrs({
  className: 'Taxonomies',
})`
    margin-bottom: 1rem;
    text-align: center;
    
    div {
      font-size: 1.5em;
      color: #DA614F;
      font-weight: bold;
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      
      span ~ span::before {
        content: ', '; 
      }
      
      img {
        height: 1.25rem;
        margin-right: 0.5rem;
      }
    }
`;
