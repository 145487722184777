import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import TemplateFactory from '../Templates/TemplateFactory'
import SiteAPIClient from '../../api/clients/site'
import { arrEmpty } from '../Helpers'

export default function FavoritesPage() {
  // router
  const router = useHistory()
  // state
  const [titleArea, setTitleArea] = useState(null)
  // vars
  let allowedToLoad = true;

  // get the banner content
  useEffect(() => {
    const loadOptions = async () => {
      try {
        const API = new SiteAPIClient();
        const response = await API.getFavoritesOptions()
        if (true === response.success) {
          // set title area
          setTitleArea({
            title: 'Favorites',
            image: null,
            breadcrumbs: [
              {
                'title': 'Favorites',
                'url': '',
              },
            ],
            description: response?.options?.favorites_description || null,
          })
        }
      } catch(err) {
        console.error(err)
      }
    }
    
    // when safe to load
    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && loadOptions()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, []) // run once initially

  // early exit
  if (arrEmpty(titleArea)) return null

  return (<TemplateFactory
    template={'product-favorites'}
    title={titleArea}
  />)
}
