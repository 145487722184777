export const formConfig = {
  form_fields: {
      email: {
        label: 'Comma Separated Emails',
        name: 'email',
        placeholder: 'Your Recipients Emails Here',
        required: true,
      },
      from: {
        label: 'Your Email',
        name: 'from',
        placeholder: 'Your Email Here',
        required: true
      },
  },
  mailSent: 'Your email has been successfully sent.',
  mailFail: 'Please check that any email addresses entered are valid before submitting the form.',
  formError: 'Sorry, we had trouble submitting your request. Please refresh this page and try again.',
}
