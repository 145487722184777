import React, { useState, useCallback } from "react";
import {
  ReelWrapper,
  ReelImage,
  ReelInner,
  ReelArrow,
  ReelImgWrapper,
  ReelCaption,
  ReelDescription,
} from './styled'

export default function Reel({ size, items, noArrows }) {
  size = size || 'large';

  const [index, setIndex] = useState(0);

  const updateIndex = useCallback((newIdx) => {
    let _new;
    const min = 0;
    const max = items.length - 1;

    if (index === max && max < newIdx) _new = 0;
    else if (index === min && min > newIdx) _new = max;
    else _new = newIdx;
    setIndex(_new);
  }, [items, index, setIndex]);

  const next = useCallback((e) => {
    e.stopPropagation();
    updateIndex(index + 1);
  }, [index, updateIndex]);

  const prev = useCallback((e) => {
    e.stopPropagation();
    updateIndex(index - 1);
  }, [index, updateIndex]);

  return (<ReelWrapper>
    <ReelInner size={size}>
      {items.map((img, idx) => (
        <ReelImgWrapper active={!!(idx === index)}>
          <ReelImage
            src={img.sizes[size]}
            key={`${img.name}_${img.ID}`}
            title={img.title}
            alt={img.alt}
          />
          {'thumbnail' !== size && <>
            <ReelCaption>
              {img.caption}
            </ReelCaption>
            <ReelDescription>
              {img.description}
            </ReelDescription>
          </>}
        </ReelImgWrapper>
      ))}
      {!(!!noArrows) && 1 < items.length && <>
        <ReelArrow
          next
          onClick={next}
        />
        <ReelArrow
          prev
          onClick={prev}
        />
      </>}
    </ReelInner>
  </ReelWrapper>);
}
