import axios from 'axios'
import api from '../index'
import APIClient from './base'

class ContactFormAPIClient extends APIClient {

  constructor (base64Auth) {
    super(base64Auth)

    this.endpoint = `${api.quamURL}/contact`;
  }

  /**
   * Used to submit all forms 
   * @param {object} formData 
   *  Object Shape:
   *  {
   *    to:       array of strings  => each a valid email address
   *    body:     string or html    => body of email
   *    url:      string            => url of page form sent from
   *    formType: string            => Contact || EmailAFriend || RequestQuote || 
   *                                   RequestSample || EmailAFriendFavs || RequestQuoteFavs
   *  }
   */
  async submitContactForm (formData) {
    let _url = `${this.endpoint}?`
    for (let i in formData) {
      if (formData.hasOwnProperty(i)) {
        _url += `${i}=${encodeURIComponent(formData[i])}&`
      }
    }
    // const fetchURL = _url.substr(0, (_url.length - 1))
    const response = await axios.post(_url)
    return response.data
  }
}

export default ContactFormAPIClient
