import styled from "styled-components";

import {
  Color,
  restrictedWidth,
  Block,
  Padding
} from '../../../../styles'

export const Column = styled(Block).attrs(props => ({
  className: `Column`,
  colHeight: props.colHeight || `50vw`,
  tAlign: props.tAlign || `center`,
}))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: ${props => props.colHeight};
  box-sizing: border-box;
  text-align: ${props => props.tAlign};

  padding-top: ${Padding.padding5};
  padding-bottom: ${Padding.padding5};

  ${restrictedWidth}

  * {
    ${props => 'center' === props.tAlign
      && `margin-left: auto;
          Margin-right: auto;`}
  }
`;

export const Content = styled(Block).attrs(props => ({
  className: `Content`,
}))`
  color: ${Color.offWhite}
`;
