/**
 * Conditions (all need to be met):
 *  - longer than 1 char ('/' and '#' cases)
 *  - doesn't include the current pathname or API URLs
 *  - does include 'http' or '://' or 'www'
 * @param {string} route
 */

const internalURLs = [
  window.location.host, // curr url hostname (localhost / quamspeakers.com)
  'api.quam.test', // dev api #1
  'api.quam.local', // dev api #2
  'api_quam.localhost', // dev api #3
  'doquam.staging.photon.software', // staging api #1
  'api-quam-staging.photon.software', // staging api #2
  'api.quamspeakers.com', // production api
  'quamspeakers.com', // production app
]

export const isExternal = (route) => {
  // console.log(route);
  if (route && route.includes('/wp-content/uploads/')) return true;
  return (
    route.length >= 2
    && !route.includes(internalURLs[0])
    && !route.includes(internalURLs[1])
    && !route.includes(internalURLs[2])
    && !route.includes(internalURLs[3])
    && !route.includes(internalURLs[4])
    && !route.includes(internalURLs[5])
    && (route.includes('http')
      || route.includes('://')
      || route.includes('www')
    )
  )
}

/**
 * Used to determine the base-slug for next/link's Link component.
 *
 * @param {string} postType | taxonomy | post | nav_menu_item | custom
 */
export const getBaseSlug = (postType) => {
  return 'taxonomy' === postType ? 'taxonomy' : 'slug'
}

/**
 * Used to determine the base-slug for next/link's Link component.
 *
 * @param {string} href
 */
export const getLinkUrl = (href) => {

  if ('' === href) return null;

  // defaults
  let newHref = href
  let newAs = href
  let external = true

  // examine href to check if link includes;
  //  a) api url
  //  b) same origin url
  //  c) external url
  //  c) # or /
  if (!isExternal(href)) {
    external = false

    // clean URL
    href = href.replace('https', '')
    href = href.replace('http', '')
    href = href.replace('://', '')
    href = href.replace('www.', '')
    href = href.replace(internalURLs[0], '')
    href = href.replace(internalURLs[1], '')
    href = href.replace(internalURLs[2], '')
    href = href.replace(internalURLs[3], '')
    href = href.replace(internalURLs[4], '')
    href = href.replace(internalURLs[5], '')

    // check if already contains correct route
    const hrefParts = href.split('/')

    if (
      hrefParts
      && 3 <= hrefParts.length
      && '' === hrefParts[0]
      && '' !== hrefParts[1]
      && '' === hrefParts[2]
    ) {
      // page (implicit, selected as post type by admin therefore doesn't contain tax slug)
      newHref=`/page/[slug]`
      newAs=`/page/${hrefParts[1]}`
    } else if (
      hrefParts
      && 3 <= hrefParts.length
      && '' === hrefParts[0]
      && 'page' === hrefParts[1]
      && '' !== hrefParts[2]
    ) {
      // page (explicit, entered as URL by admin)
      newHref=`/page/[slug]`
      newAs=`/page/${hrefParts[2]}`
    } else if (
      hrefParts
      && 3 <= hrefParts.length
      && '' === hrefParts[0]
      && 'product' === hrefParts[1]
      && '' !== hrefParts[2]
    ) {
      // product
      newHref=`/product/[slug]`
      newAs=`/product/${hrefParts[2]}`
    } else if (
      hrefParts
      && 3 <= hrefParts.length
      && '' === hrefParts[0]
      && 'product-group' === hrefParts[1]
      && '' !== hrefParts[2]
    ) {
      // product group
      newHref=`/product-group/[taxonomy]`
      newAs=`/product-group/${hrefParts[2]}`
    }
  }

  return {
    'external': external,
    'href': newHref,
    'as': newAs,
  }
}

export const useLocation = () => {
  return (window && window.location) || {}
}
