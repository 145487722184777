import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import SVGWrapper from '../SVGWrapper'
import iconSearch from '../../../assets/images/icons/Quam-Icon-Search.svg'
import { Widths } from '../../../styles'
import {
  SearchContainer,
  SearchForm,
  SearchInput,
  SearchBtn,
} from './styles'

export default function SearchBar({ keyword }) {
  // router
  const router = useHistory()
  // states
  const [newKeyword, setNewKeyword] = useState('')
  // refs
  const inputEl = useRef()


  useEffect(() => {
    if (keyword && inputEl) {
      inputEl.current.value = keyword
      setNewKeyword(keyword);
      inputEl.current.focus()
    }
  }, [keyword])

  const handleSearchClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (newKeyword && 3 <= newKeyword.length) {
      // perform new search
      router.push(`/results/${encodeURIComponent(newKeyword)}`)
    }
  }

  return (<>
    <SearchContainer>
      <SearchForm
        onSubmit={e => handleSearchClick(e)}
      >
        <SearchInput
          ref={inputEl}
          onChange={e => setNewKeyword(e.target.value)}
        />
        <SearchBtn
          onClick={e => handleSearchClick(e)}
          hoverBgColor={`red`}
          hoverBorderColor={`red`}
        >
          <SVGWrapper
            src={iconSearch}
            width={Widths.icons.small}
          />
        </SearchBtn>
      </SearchForm>
    </SearchContainer>
  </>);
}
