import React, {useState, useEffect} from "react";
import { useParams, useHistory } from 'react-router-dom'
import TemplateFactory from '../Templates/TemplateFactory'
import ProductAPIClient from '../../api/clients/product'
// import { arrEmpty } from '../Helpers'
import Loading from '../Components/Loading'

export default function ProductDetail() {
  // router
  const params = useParams()
  const router = useHistory()
  const {slug} = params
  // state
  const [isLoading, setIsLoading] = useState(false)
  const [product, setProduct] = useState(null)
  const [titleArea, setTitleArea] = useState(null)
  // vars
  let allowedToLoad = true;

  useEffect(() => {

    const load = async () => {
      setIsLoading(true)
      try {
        const API = new ProductAPIClient()
        const response = await API.getProductBySlug(slug)
        if (true === response.success) {
          const prod = response.product
          const prodTitle = prod?.sections?.product_groups?.groups[0]?.name || prod?.title || null
          const heroImg = prod?.featured_image?.large && prod?.featured_image?.large[0] ? prod?.featured_image?.large[0] : null
          setProduct(prod)
          setTitleArea({
            title: prodTitle,
            image: heroImg,
            breadcrumbs: [
              {
                'title': 'Primary Category',
                'url': '#',
              },
              {
                'title': 'Secondary Category',
                'url': '#',
              },
            ]
          })
        } else {
          console.warn('No product found by that sku/slug/name...')
        }
      } catch(error) {
        console.error(error)
      }
      setIsLoading(false)
    }

    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && load()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, [slug])

  // if (
  //   arrEmpty(product)
  //   || arrEmpty(titleArea)
  // ) return null;

  return (isLoading
    ? <Loading inclIcon={true} />
    : <TemplateFactory
      title={titleArea}
      template={'product-detail'}
      content={product}
    />)
}
