import React from "react";
import {
  VideoContainer,
  // VideoMask,
} from './styles'

export default function VideoBackground({
  url,
  poster = '',
  autoplay = false,
  mute = false,
  background = false,
  loop = false,
}) {

  return (<>
    <VideoContainer
      src={url}
      type={"video/mp4"}
      autoPlay={autoplay}
      mute={mute}
      loop={loop}
      poster={poster}
      controls={background ? false : true}
    ></VideoContainer>
    {/* <VideoMask /> */}
  </>);
}
