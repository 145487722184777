import React from "react";
import {} from '../../../Helpers'
import {
  BurgerMenuToggleContainer,
  BurgerMenuToggleLine,
} from '../styles'

export default function BurgerMenuToggle({
  isOpen
}) {
  return (<BurgerMenuToggleContainer isOpen={isOpen} >
    <BurgerMenuToggleLine />
    <BurgerMenuToggleLine />
    <BurgerMenuToggleLine />
  </BurgerMenuToggleContainer>);
}
