import styled from "styled-components";

import {
  FontSize,
  Block,
  AnchorButtonAlt,
  coverBackground,
  getColorByName,
} from '../../../../styles'

export const Column = styled.div.attrs(props => ({
  className: `Column`,
  height: props.height || `50vw`
}))`
  ${props => coverBackground(props.backgroundImage)}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: ${props => props.height};
  box-sizing: border-box;
  padding: 40px 6vw;

  h2 {
    margin-bottom: 1em;
  }
`;

export const Content = styled(Block).attrs(props => ({
  currDevice: props.currDevice || 'desktop',
  theme: props.theme || 'light'
}))`
  font-size: ${props => 'mobile' === props.currDevice 
    ? `${FontSize.body}`
    : `${FontSize.body}`};
  line-height: 1.4em;
  color: ${props => 'light' === props.theme
    ? getColorByName('white')
    : getColorByName('black')}
`;

export const CtaButtonAlt = styled(AnchorButtonAlt).attrs(props => ({
  currDevice: props.currDevice || 'desktop',
}))`
  margin-top: ${props => 'mobile' === props.currDevice
    ? `0.5em`
    : `2.25em`};
  margin-bottom: ${props => 'mobile' === props.currDevice
    ? `15px`
    : `0`};
`;
