import { arrEmpty } from './arrayHelpers'

// 20200701: added check for both 'thumbnail_large' & 'large_thumbnail' -- I assume this changed in WP at some stage, so the thumbnails aren't actually consistent. -Al
export const getThumbnail = (prod) => {
  // early exit
  if (!prod) return null
  let thumbnail = null
  // console.log('prod', prod)
  if (
    !arrEmpty(prod?.sections?.product_gallery?.gallery)
  ) {
    if (!arrEmpty(prod?.sections?.product_gallery?.gallery[0].medium)) {
      thumbnail = prod?.sections?.product_gallery?.gallery[0].medium[0]
    }
  } else if (!arrEmpty(prod?.featured_image?.medium)) {
    thumbnail = prod.featured_image.medium[0]
  }
  // Commented out -- uses larger images
  /* if (
    !arrEmpty(prod?.sections?.product_gallery?.gallery)
  ) {
    if (!arrEmpty(prod?.sections?.product_gallery?.gallery[0].thumbnail_large)) {
      thumbnail = prod?.sections?.product_gallery?.gallery[0].thumbnail_large[0]
    } else if (!arrEmpty(prod?.sections?.product_gallery?.gallery[0].large_thumbnail)) {
      thumbnail = prod?.sections?.product_gallery?.gallery[0].large_thumbnail[0]
    }
  } else if (!arrEmpty(prod?.featured_image?.thumbnail_large)) {
    thumbnail = prod.featured_image.thumbnail_large[0]
  } else if (!arrEmpty(prod?.featured_image?.large_thumbnail)) {
    thumbnail = prod.featured_image.large_thumbnail[0]
  } */
  return thumbnail
}
