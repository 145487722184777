import styled from "styled-components";

import {
  Color,
  FontSize,
  Padding,
  Widths,
  Block,
  Heading,
  P
} from '../../../styles'

export const SpecsContainer = styled(Block)`
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: ${Padding.padding1_5} 0;
  background-color: ${Color.white}
`;

export const SpecsHeading = styled(Heading)``;

export const SpecGroupContainer = styled(Block)`
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0;

  border-top: 1px solid ${Color.slateGray};
  &:last-of-type {
    border-bottom: 1px solid ${Color.slateGray};
  }
`;

export const GroupTitle = styled(Heading)``;

export const Spec = styled(Block)`
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 0;

  border-bottom: 1px solid ${Color.slateGray};
  &:first-of-type {
    border-top: 1px solid ${Color.slateGray};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

export const SpecLabel = styled(P)`
  display: inline-block;
  text-align: left;
  vertical-align: top;
  width: 50%;
  font-size: 1.4em;
  line-height: 1.43em;

  @media screen and (min-width: ${Widths.minTablet}) {
    font-size: ${FontSize.body};
  }
  @media screen and (min-width: ${Widths.minDesktop}) {
    font-size: ${FontSize.body};
  }
`;

export const SpecValue = styled(P)`
  display: inline-block;
  text-align: left;
  vertical-align: top;
  width: 50%;
  font-size: 1.4em;
  line-height: 1.43em;

  @media screen and (min-width: ${Widths.minTablet}) {
    font-size: ${FontSize.body};
  }
  @media screen and (min-width: ${Widths.minDesktop}) {
    font-size: ${FontSize.body};
  }
`;
