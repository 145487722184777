import axios from 'axios'
import api from '../index'
import APIClient from './base'

class NavAPIClient extends APIClient {

  constructor (base64Auth) {
    super(base64Auth)

    this.endpoint = `${api.quamURL}`;
  }

  async getNavsAvailable () {
    const url = `${this.endpoint}/navs-available`
    const response = await axios.get(url)
    return response.data
  }

  async getNavBySlug (slug) {
    const url = `${this.endpoint}/nav/${slug}`

    const response = await axios.get(url)
    return response.data
  }
}

export default NavAPIClient
