import React from "react";
import { useWindowSize } from '../../Helpers'
import SVGWrapper from '../SVGWrapper/SVGWrapper'
import { Widths } from '../../../styles'
import {
  SocialLink,
  SocialIcon,
} from './styles'

export default function SocialIcons({
  icons
}) {
  // vars
  const windowSize = useWindowSize()

  return (<>
    {icons
      && icons.map((icon, index) => {
        return (<SocialLink
          key={index}
          href={icon.link}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          {icon.icon.url
            && icon.icon.url.includes('.svg')
            ? <SVGWrapper
              src={icon.icon?.url}
              width={Widths.icons.small}
              height={Widths.icons.small}
            />
            : <SocialIcon
              currDevice={windowSize.currDevice}
              src={icon.icon?.url}
              alt={''}
            />}
        </SocialLink>)
      })}
  </>);
}
