import styled from 'styled-components'
import {
  Block,
  Image,
  //Heading,
  Color,
  // ContentContainer,
  Button,
  //restrictedWidth,
} from '../../../styles'
import leftArrow from '../../../assets/images/icons/Quam-Icon-LeftArrow.svg'
import rightArrow from '../../../assets/images/icons/Quam-Icon-RightArrow.svg'

export const GalleryWrapper = styled(Block).attrs({
  className: 'GalleryWrapper',
})`
  display: flex;
  flex-wrap: wrap;
`

export const ReelThumbWrapper = styled(Block).attrs({
  className: 'ReelThumbWrapper',
})`
  padding: 2em;
  box-sizing: border-box;
  width: 100%;
  text-align: center;

  a {
    position: relative;
    display: block;
  }
`

export const ReelWrapper = styled(Block).attrs({
  className: 'ReelWrapper',
})`

`

export const ReelInner = styled(Block).attrs({
  className: 'ReelInner',
})`
  position: relative;
  height: ${props => props.size === 'thumbnail' ? '250px' : '50vh'};
`

export const ReelArrow = styled(Button).attrs({
  className: 'ReelArrow',
})`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25em;
  height: 1.25em;
  min-height: 1.25em;
  padding: 0.25em;
  margin: 0;
  z-index: 3;
  border: 0;
  background-color: transparent;
  background-size:  auto 80%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.prev ?
    leftArrow :
    rightArrow
  });

  ${props => props.prev && `left: 0.15em;`}
  ${props => props.next && `right: 0.15em;`}

  &:hover {
    background-color: ${Color.blue};
  }
`

export const ReelImgWrapper = styled(Block).attrs({
  className: 'ReelImgWrapper',
})`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.active ? 1 : 0};
  z-index: ${props => props.active ? 1 : -1};
`

export const ReelImage = styled(Image).attrs({
  className: 'ReelImage',
})`
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  border-radius: 5px;
`

export const ReelCaption = styled.p.attrs({
  className: 'ReelCaption',
})`
  color: ${Color.gray};
  margin: 0.5em auto;
  width: 75%;
  font-size: 0.8rem;
`

export const ReelDescription = styled.div.attrs({
  className: 'ReelDescription',
})`
  display: block;
  text-align: left;
  width: 80%;
  margin: 1em auto;
  font-size: 1rem;
`

export const ReelThumbnail = styled(Block).attrs({
  className: 'ReelThumbnail',
})`

`
//
// export const ReelThumbnail = styled(Block).attrs({
//   className: 'ReelThumbnail',
// })`
//
// `
