import styled from 'styled-components'

import {
  // Block,
  Color,
  Widths,
} from '../../../styles'

export const VideoContainer = styled.video.attrs(props => ({
}))`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  z-index: 0;
  background: ${Color.black};

  /* @media screen and (max-width: ${Widths.maxMobile}) {
    height: 100%;
    width: 100%;
  } */
`

// export const VideoMask = styled(Block).attrs(props => ({
// }))`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: ${Color.black};
//   z-index: 0;
// `
