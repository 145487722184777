import axios from 'axios'
import api from '../index'
import APIClient from './base'

class PageAPIClient extends APIClient {

  constructor (base64Auth) {
    super(base64Auth)

    this.endpoint = `${api.quamURL}/page`;
  }

  async getPageBySlug (slug) {
    const url = `${this.endpoint}/${slug}`

    const response = await axios.get(url)
    return response.data
  }
}

export default PageAPIClient
