import styled from "styled-components";

export const Label = styled.label.attrs({
  className: 'Label',
})`
  font-size: 3em;
  font-family: Assistant, sans-serif;
  font-size: 2.2em;
  font-weight: 400;
  text-transform: inherit;
  margin-bottom: 1.5em;
`;
