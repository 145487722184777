import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom'
import ProductFile from './ProductFile'
import SiteAPIClient from '../../../api/clients/site'
import {
  //FilesContainer,
  FilesHeading,
} from './styles'

export default function ProductFiles({
  files,
  slimline = false
}) {
  // router
  const router = useLocation()
  // state
  const [colorMapping, setColorMapping] = useState({})
  // vars
  // const windowSize = useWindowSize()
  let allowedToLoad = true;

  // get the banner content
  useEffect(() => {
    const loadOptions = async () => {
      // setIsLoading(true)
      try {
        const API = new SiteAPIClient();
        const response = await API.getTechnicalFilesOptions()
        if (true === response.success) {
          // set title area
          setColorMapping(response?.options?.technical_files_color_mapping || {})
        }
      } catch(err) {
        console.error(err)
      }
      // setIsLoading(false)
    }

    // when safe to load
    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && loadOptions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, []) // run once initially
  
  return (
    <>
    {!slimline
      && <FilesHeading
        type={'h2'}
        children={'Technical Documents'}
      />}
    {files
      && files.map((file, i) => (
        <ProductFile
          key={i}
          file={file}
          colorMapping={colorMapping}
        />
      ))}
    </>
  );
}
