import styled from "styled-components";
//
import {
  Heading,
  Block,
  restrictedWidth,
} from '../../../../styles'

export const GalleryModuleWrapper = styled(Block).attrs({
  className: 'GalleryModuleWrapper',
})`
  margin-top: 5em;
  margin-bottom: 0.25em;
  text-align: center;
  ${restrictedWidth}
`

export const GalleryTitle = styled(Heading).attrs({
  className: 'GalleryTitle',
})`
  margin-top: 1em;
  margin-bottom: 0.25em;
`
