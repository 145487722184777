import styled from "styled-components";

import {
  Color,
  Padding,
  FontSize,
  LetterSpacing,
  Widths,
  Block,
  restrictedWidth,
} from '../../../styles'

export const Container = styled(Block)`
  padding-bottom: ${Padding.padding1};
  display: flex;
  ${restrictedWidth}
`;

export const Content = styled(Block).attrs(props => ({
  className: `Copyright`,
}))`
  margin: 0 auto;
  p {
    color: ${Color.veryLightBlue};
    text-align: center;
    letter-spacing: ${LetterSpacing.small};
    text-transform: uppercase;
    font-size: ${FontSize.footer.copyright.mobile};
    
    @media screen and (min-width: ${Widths.minTablet}) {
      font-size: ${FontSize.footer.copyright.tablet};
    }
    @media screen and (min-width: ${Widths.minDesktop}) {
      font-size: ${FontSize.footer.copyright.desktop};
    }
  
    a {
      color: ${Color.veryLightBlue};
      // text-decoration: none;
    }
  }
`;
