import React, { useState, useEffect } from "react";
import SVGWrapper from '../SVGWrapper'
import {
  Widths,
} from '../../../styles'
import {
  FileContainer,
  FileWrapper,
  FileAnchor,
  FileTitle,
} from "./styles";
import icon from '../../../assets/images/icons/Quam-Icon-Document.svg'

import {useSpectraAnalytics} from 'spectra/src/hooks/useSpectraAnalytics'

export default function ProductFile({
  file,
  colorMapping
}) {
  // states
  const [fileColor, setFileColor] = useState(null)
  // for spectra event tracking
  const analytics = useSpectraAnalytics()

  useEffect(() => {
    colorMapping && getColor(file.file.url)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[colorMapping])

  // see if a mappng exists for the file extension, and return the color if so
  const getColor = () => {
    const fileName = file.file_title
    Object.entries(colorMapping).map(([idx, mapping]) => {
      fileName.includes(idx) && setFileColor(mapping)
      return null
    })
  }

  const logSpectraEvent = () => {
    analytics && analytics.logEvent('file_download', {
      file_name: file.file_title,
      file_url: file.file.url,
    });
  }

  if (
    !file
    || '' === file.file_title
    || '' === file.file.url
  ) return null;

  return (
    <FileContainer>
      <FileWrapper>
        <FileAnchor
          href={file.file.url}
          target={'_blank'}
          rel={'noopener noreferrer'}
          color={fileColor}
          onClick={logSpectraEvent}
        >
          <SVGWrapper
            src={icon}
            width={Widths.icons.xsmall}
          />
          <FileTitle>
            {file.file_title}
          </FileTitle>
        </FileAnchor>
      </FileWrapper>
    </FileContainer>
  );
}
