import styled from "styled-components";

import {
  Color,
  FontSize,
  Widths,
  Block,
  Heading,
  P,
  Anchor,
  Span,
} from '../../../styles'

export const FilesContainer = styled(Block)`
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  background-color: ${Color.white}
`;

export const FilesHeading = styled(Heading)``;

export const FileContainer = styled(Block)`
  text-align: left;
  width: 100%;
  box-sizing: border-box;

  border-top: 1px solid ${Color.darkGray};
  &:last-of-type {
    border-bottom: 1px solid ${Color.darkGray};
  }
`;

export const FileWrapper = styled(P)`
  text-align: left;
  margin: 0;
  font-size: ${FontSize.body};

  @media screen and (min-width: ${Widths.minTablet}) {
    font-size: ${FontSize.body};
  }
  @media screen and (min-width: ${Widths.minDesktop}) {
    font-size: ${FontSize.body};
  }
`;

export const FileAnchor = styled(Anchor).attrs(props => ({
  className: `FileAnchor`,
  color: props.color || Color.gray,
}))`
  display: flex;
  align-items: center;
  text-align: left;
  padding: 8px 0;
  width: 100%;

  span:first-of-type {
    margin-right: 10px;

    svg {
      fill: ${props => props.color};
      stroke: ${props => props.color};

      path,
      text,
      polygon
      {
        fill: ${props => props.color};
        stroke: ${props => props.color};
      }
    }
  }

  .FileTitle {
    color: ${props => props.color};
  }
`;

export const FileTitle = styled(Span).attrs(props => ({
  className: `FileTitle`
}))``;
