import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import CategoryFiltersAPIClient from '../../../api/clients/categoryFilters'
import ProductFiltersAPIClient from '../../../api/clients/productFilters'
import TitleArea from '../../Components/TitleArea'
import CategoryFilters from '../../Components/CategoryFilters'
// import ProductsGridSort from '../../Components/ProductsGrid/ProductsGridSort'
import ProductsGrid from '../../Components/ProductsGrid'
import Loading from '../../Components/Loading'
import Notice from '../../Components/Notice'
import ModalPopup from '../../Components/ModalPopup'
import ClearFilters from '../../Components/Forms/ClearFilters'
import {
  arrEmpty,
  objEmpty,
  useWindowSize,
} from '../../Helpers'
import {
  Padding,
  ContentContainer,
  ContentWrapper,
  GridContainer,
  GridItem,
} from '../../../styles'

export default function ProductCategoryTemplate({
  title,
  bannerImage,
  bannerImageMobile,
  content,
}) {
  // router
  const router = useLocation()
  // states
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [sortBy, setSortBy] = useState('title')
  const [products, setProducts] = useState(content?.products || [])
  const [filters, setFilters] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState({})
  const [clearFiltersModalOpen, setClearFiltersModalOpen] = useState(false)
  const [clearSeed, setClearSeed] = useState(false)
  // vars
  let allowedToLoad = true;
  const windowSize = useWindowSize()
  let columns = `25% 75%`
  let rows = '1'
  if ('desktop' !== windowSize.currDevice && 'laptop' !== windowSize.currDevice) {
    columns = 'repeat(1,auto)'
    rows = `2`
  }

  /**
   * runs once, when there is content, and prepares filter options
   */
  useEffect(() => {
    const initFilters = async () => {
      try {
        const API = new CategoryFiltersAPIClient()
        const response = await API.getFiltersByCategory(content.catId)
        if (true === response.success) {
          setFilters(response.filters)
        }
      } catch (error) {
        console.error(error)
      }
    }

    // check we can load the content
    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && !objEmpty(content)
      && content.catId
      && initFilters()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, [content])

  /**
   * called when user updates filter selections
   * show/hide productions based on selected filters
   */
  // TODO - actually search the database. This becomes important when we implement pagination....
  const filterProducts = (selectedFilters) => {
    setSelectedFilters(selectedFilters)
    const getProducts = async () => {
      setIsLoading(true)
      if (objEmpty(selectedFilters)) {
        setProducts(content.products)
      } else {
        try {
          const API = new ProductFiltersAPIClient()
          const response = await API.getProductsByFilters(content.catId, selectedFilters, 2, 1)
          if (true === response.success) {
            setProducts(response.products)
          } else {
            setProducts([])
          }
        } catch (error) {
          console.error(error)
        }
      }
      setIsLoading(false)
    }

    // check we can load the content
    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && !objEmpty(content)
      && getProducts()

    return () => allowedToLoad = false;
  }

  const handleClearFiltersOpen = (e) => {
    e.preventDefault()
    e.stopPropagation()
    !clearFiltersModalOpen && setClearFiltersModalOpen(true)
  }

  const handleClearFilters = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // close the modal
    clearFiltersModalOpen && setClearFiltersModalOpen(false)
    // reset products
    setProducts(content.products)
    // reset selected filters
    setSelectedFilters({})
    // reset filters
    setClearSeed(new Date().getTime())
  }

  return (<ContentContainer bgColor={`lightGray`} >
    <TitleArea title={title} />
    {windowSize.currDevice !== 'mobile' && bannerImage && <div
      style={{ 'text-align': 'center', 'padding-top': '4rem', 'width': '100%'}}
    >
      <img src={bannerImage} alt="" />
    </div>
    }
    {windowSize.currDevice === 'mobile' && bannerImageMobile && <div
      style={{ 'text-align': 'center', 'padding-top': '4rem', 'width': '100%'}}
    >
      <img src={bannerImageMobile} alt="" />
    </div>
    }
    {arrEmpty(products) && !!objEmpty(selectedFilters) // search performed, but no products
      ? <Notice message={'No products found in this category.'} />
      : <>
        <ContentWrapper
          currDevice={windowSize.currDevice}
        >
          <GridContainer
            gap={`0`}
            columns={columns}
            rows={rows}
            padding={`${Padding.padding2} 0`}
          >
            <GridItem>
              {!arrEmpty(filters) && <CategoryFilters
                isLoading={isLoading}
                filters={filters}
                updateSelectedFilters={filterProducts}
                handleClearFiltersOpen={handleClearFiltersOpen}
                clearSeed={clearSeed}
              />}
            </GridItem>
            <GridItem>
              {/* <ProductsGridSort
                  sortProducts={(newSortBy) => setSortBy(newSortBy)}
                /> */}
              {isLoading
                ? <Loading />
                : <ProductsGrid
                  sortBy={sortBy}
                  products={products || content}
                  showDescription={true}
                  showButtons={true}
                />}
            </GridItem>
          </GridContainer>
        </ContentWrapper>
        <ModalPopup
          isOpen={clearFiltersModalOpen}
          handleClose={setClearFiltersModalOpen}
          children={<ClearFilters
            handleAccept={handleClearFilters}
          />}
        />
      </>}
  </ContentContainer>)
}
