import React, { useEffect } from "react";
import { ConstantContact } from "./styles";

export default function ConstantContactModule({ dataFormId }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "signupScript";
    script.src = "//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js";
    script.async = true;
    script.defer = true;
    script.innerHTML = `var _ctct_m = "9e6352417e66153629fa882e9b2df94b";`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ConstantContact>
      <div className="ctct-inline-form" data-form-id={dataFormId}></div>
    </ConstantContact>
  );
}