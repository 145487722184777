import styled from "styled-components";

import {
  Color,
  Padding,
  Block,
  Widths,
  restrictedWidth,
} from '../../../../styles'

export const Container = styled(Block).attrs(props => ({
  className: `ColWrapper`,
  padding: props.slimline ? `3em 6vw 3em` : Padding.moduleSection,
}))`
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: ${props => props.padding};
  padding-left: 0;
  padding-right: 0;
  background: ${Color.blue};
  ${restrictedWidth}

  p {
    font-size: 1.6em;
    margin-top: 0;
    margin-bottom: 13px;

    @media screen and (min-width: ${Widths.minTablet}) {
      font-size: 1.8em;
    }
    @media screen and (min-width: ${Widths.minDesktop}) {
      font-size: 1.8em;
    }
  }

  h2 {
    // font-size: 2.4em;
    margin-bottom: 13px;

    @media screen and (min-width: ${Widths.minTablet}) {
      margin-bottom: 0;
    }
  }

  > .Block {
  }

  > * {
    .Block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      form {
        display: inherit;
      }
    }
  }
`;
