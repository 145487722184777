import React from "react";
import { useHistory } from 'react-router-dom'
import { Parallax } from 'react-parallax';
import {
  GridContainer,
  GridItem,
  Heading,
} from '../../../../styles'
import {
  Column,
  Content,
  CtaButtonAlt,
} from "./styles";
import { 
  useWindowSize,
  isExternal,
} from '../../../Helpers'

export default function TwoColumnContent({
  left,
  right
}) {
  // router
  const router = useHistory()
  // vars
  const windowSize = useWindowSize()
  let gap = '0'
  let columns = '50% 50%'
  let rows = 'auto'
  let colHeightLeft = '50vw'
  let colHeightRight = '50vw'
  if ('mobile' === windowSize.currDevice) {
    columns = 'repeat(1,auto)'
    rows = 'repeat(2,auto)'
    colHeightLeft = left.cta ? 'auto' : '80vw'
    colHeightRight = right.cta ? 'auto' : '80vw'
  } else if ('tablet' === windowSize.currDevice) {
    columns = 'repeat(1,auto)'
    rows = 'repeat(2,auto)'
    colHeightLeft = left.cta ? 'auto' : '60vw'
    colHeightRight = right.cta ? 'auto' : '60vw'
  }
  /* if ('desktop' !== windowSize.currDevice) {
    columns = 'repeat(1,auto)'
    rows = 'repeat(2,auto)'
    colHeightLeft = left.cta ? 'auto' : '80vw'
    colHeightRight = right.cta ? 'auto' : '80vw'
  } else if ('tablet' === windowSize.currDevice) {
    columns = 'repeat(1,auto)'
    rows = 'repeat(2,auto)'
    colHeightLeft = left.cta ? 'auto' : '60vw'
    colHeightRight = right.cta ? 'auto' : '60vw'
  } */


  if (
    null === left
    || null === right
  ) return null

  return (
    <GridContainer
      gap={gap}
      columns={columns}
      rows={rows}
    >
      <GridItem>
        <Parallax strength={100} bgImage={left.background}>
        <Column
          height={colHeightLeft}
        >
          <Heading
            type={'h2'}
            children={left.title}
            color={'light' === left.theme ? 'white' : 'blue'}
          />
          <Content
            theme={left.theme}
            currDevice={windowSize.currDevice}
            tAlign={'left'}
            dangerouslySetInnerHTML={{
              __html: left.content
            }}
          />
          {left.cta 
            && <CtaButtonAlt
              currDevice={windowSize.currDevice}
              onClick={() => isExternal(left.cta.url)
                ? window.location = left.cta.url
                : router.push(left.cta.url)}
              altStyle={true}
              color={'light' === left.theme ? 'white' : 'black'}
              borderColor={'light' === left.theme ? 'white' : 'black'}
              padding={'1em 0.5em'}
            >
              {left.cta.title}
            </CtaButtonAlt>}
        </Column>
        </Parallax>
      </GridItem>
      <GridItem>
      <Parallax strength={100} bgImage={right.background}>
        <Column
          height={colHeightRight}
        >
          <Heading
            type={'h2'}
            children={right.title}
            color={'light' === right.theme ? 'white' : 'blue'}
          />
          <Content
            theme={right.theme}
            currDevice={windowSize.currDevice}
            tAlign={'left'}
            dangerouslySetInnerHTML={{
              __html: right.content
            }}
          />
          {right.cta
            && <CtaButtonAlt
              currDevice={windowSize.currDevice}
              onClick={() => isExternal(right.cta.url)
                ? window.location = right.cta.url
                : router.push(right.cta.url)}
              altStyle={true}
              color={'light' === right.theme ? 'white' : 'black'}
              borderColor={'light' === right.theme ? 'white' : 'black'}
              padding={'1em 0.5em'}
            >
              {right.cta.title}
            </CtaButtonAlt>}
        </Column>
      </Parallax>
      </GridItem>
    </GridContainer>
  );
}
