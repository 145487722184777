import React from "react"
import TitleArea from '../../Components/TitleArea'
import {
  ContentContainer,
} from '../../../styles'
import {
  GridBlog
} from './styled'
import PostModule from '../../Components/PostModule'

export default function BlogTemplate({
  title,
  posts
}) {

  return (<ContentContainer>
    {'object' === typeof title
      && <TitleArea title={title} />}
    {posts && (
      <GridBlog>
        {posts.map((post, i) => (
          <PostModule
            item={post}
            key={i}
          />
        ))}
      </GridBlog>
    )}
  </ContentContainer>)
}
