import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import TypeAhead from './TypeAhead'
import { useWindowSize } from '../../../Helpers'
import {
  SearchBtn,
  SearchContainer,
  SearchForm,
  SearchInput,
  SearchOverlay,
} from './styles'

import {useSpectraAnalytics} from 'spectra/src/hooks/useSpectraAnalytics'

export default function SearchBar({
  icon
}) {
  // router
  const router = useHistory()
  // states
  const [open, setOpen] = useState(false)
  const [keyword, setKeyword] = useState('')
  // refs
  const inputEl = useRef(null)
  const overlayEl = useRef(null)
  // vars
  const windowSize = useWindowSize()

  const analytics = useSpectraAnalytics()

  const resetSearchInput = (persistentInput = false) => {
    // close the header search bar
    setOpen(false)
    // if not persistent clear search bar value
    if (!persistentInput) {
      // clear keyword & input
      setKeyword('')
      inputEl.current.value = ''
    }
  }

  /**
   * If search bar open when click;
   *  - Check for product slug, and if given route to that product.
   *  - Check that the keyword is greater than 3 chars, and then perform the search.
   */
  const handleSearchClick = (e, productSlug = null) => {
    e.preventDefault()
    e.stopPropagation()
    analytics && analytics.logEvent('search', {keyword});

    if (open) {
      if (null !== productSlug) {
        analytics && analytics.logEvent('search_suggestion_clicked', {keyword, productSlug});
        // route to production
        router.push(`/product/${productSlug}`)
        resetSearchInput()
      } else if (
        !productSlug
        && keyword
        && 3 <= keyword.length
      ) {
        analytics && analytics.logEvent('search_button_clicked', {keyword});
        // perform search
        router.push(`/results/${encodeURIComponent(keyword)}`)
        resetSearchInput()
      } else {
        // maintain focus
        inputEl.current && inputEl.current.focus()
      }
    }
  }

  /**
   * Desktop:
   *  Hovering over the search button will auto open the search bar
   *    and focus on the input.
   *  When open, an invisible overlay is situatated above everything
   *    except the search button and the search input. When the overlay
   *    is hovered over the search bar closes.
   * Mobile:
   *  As above, except the 'hover' event is triggered by tapping the
   *    screen, therefore tapping the search input will open the input
   *    field, and tapping outside of it will close it.
   */
  const handleOpenSearch = (open) => {
    if (open) {
      inputEl.current && inputEl.current.focus()
      setOpen(true)
    } else {
      resetSearchInput(true)
    }
  }

  return (<>
    <SearchOverlay
      ref={overlayEl}
      open={open}
      onClick={e => setOpen(false)}
      onMouseEnter={() => handleOpenSearch(false)} // req. for mobile
      onMouseOver={() => handleOpenSearch(false)} // desktop edge-cases
    />
    <SearchContainer
      open={open}
      currDevice={windowSize.currDevice}
    >
      <SearchForm
        onSubmit={(e) => handleSearchClick(e)}
      >
        <SearchInput
          ref={inputEl}
          // onChange={e => setKeyword(inputEl.current.value)}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </SearchForm>
      {keyword && 3 <= keyword.length
        && <TypeAhead
          keyword={keyword}
          handleSearch={handleSearchClick}
        />}
    </SearchContainer>
    <SearchBtn
      onClick={(e) => handleSearchClick(e)}
      onMouseEnter={(e) => handleOpenSearch(true)} // req. for mobile
      onMouseOver={(e) => handleOpenSearch(true)} // desktop edge-cases
      hoverColor={`white`}
      hoverBgColor={`red`}
      hoverBorderColor={`red`}
    >
      {icon
        && <img
          src={icon}
          alt={'Search'}
        />}
    </SearchBtn>
  </>);
}
