import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import ProductsAPIClient from '../../../api/clients/products'
import TitleArea from '../../Components/TitleArea'
import ProductsGrid from '../../Components/ProductsGrid'
import Loading from '../../Components/Loading'
import Notice from '../../Components/Notice'
import ModalPopup from '../../Components/ModalPopup'
import WYSIWYGContent from '../../Components/WYSIWYGContent'
import SVGWrapper from '../../Components/SVGWrapper'
import EmailAFriend from '../../Components/Forms/EmailAFriend'
import RequestQuoteFavs from '../../Components/Forms/RequestQuoteFavs'
import ClearFavs from '../../Components/Forms/ClearFavs'
import printIcon from '../../../assets/images/icons/Quam-Icon-Printer.svg'
import clearIcon from '../../../assets/images/icons/Quam-Icon-ClearList.svg'
import {
  arrEmpty,
  objEmpty,
  QUAM_COOKIE_KEY,
  useWindowSize,
} from '../../Helpers'
import {
  Padding,
  ContentContainer,
  ContentWrapper,
  GridContainer,
  GridItem,
  Button,
  ButtonAlt,
  Widths,
} from '../../../styles'
import {
  FavoritesDescription,
  CtaContainer,
  CtaLeftCol,
  CtaRightCol,
  CtaWrapper,
  CtaRollover,
} from './styles'

export default function ProductFavoritesTemplate({title}) {
  // router
  const router = useLocation()
  // cookies
  const [cookies, setCookie] = useCookies([QUAM_COOKIE_KEY])
  // states
  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [productIds, setProductIds] = useState([])
  const [emailModalOpen, setEmailModalOpen] = useState(false)
  const [quoteModalOpen, setQuoteModalOpen] = useState(false)
  const [clearFavsModalOpen, setClearFavsModalOpen] = useState(false)
  // vars
  const windowSize = useWindowSize()

  // get the product ids on load
  useEffect(() => {
    const prodIds = []
    !objEmpty(cookies[QUAM_COOKIE_KEY])
      && Object.entries(cookies[QUAM_COOKIE_KEY]).forEach((fav) => prodIds.push(fav[0]))
    setProductIds(prodIds)
  }, [cookies])

  // TODO - needs work, as should update products without updating entire page...
  /* // get the product ids on cookie update
  useEffect(() => {
    // get new prod ids
    const newProdIds = []
    !objEmpty(cookies[QUAM_COOKIE_KEY])
      && Object.entries(cookies[QUAM_COOKIE_KEY]).forEach((fav) => newProdIds.push(fav[0]))
    console.log('newProdIds', newProdIds)
    //
    const newFavProds = [...products]
    const removeIdx = products.findIndex((prod) => {
      // console.log('prod', prod)
      // console.log('prod.id.toString()', prod.id.toString())
      // console.log('newProdIds.indexOf(prod.id.toString())', 0 > newProdIds.indexOf(prod.id.toString()))
      return 0 > newProdIds.indexOf(prod.id.toString())
    })
    console.log('removeIdx', removeIdx)
    if (0 >= removeIdx) {
      newFavProds.splice(removeIdx, 1)
      setProducts(newFavProds)
    }
  }, [cookies]) */

  // get the fav products
  useEffect(() => {

    const loadProducts = async () => {
      setIsLoading(true)
      if (!arrEmpty(productIds)) {
        try {
          const API = new ProductsAPIClient();
          const response = await API.getProductsById(productIds)
          if (true === response.success) {
            setProducts(response.products)
          } else {
            setProducts(null)
          }
        } catch(err) {
          console.error(err)
        }
      } else {
        setProducts(null)
      }
      setIsLoading(false)
    }

    // do not call the header yet.
    (router
      && '_error' !== router.pathname)
      && loadProducts()

  }, [productIds, router])

  const handlePrint = (e) => {
    e.preventDefault()
    e.stopPropagation()
    window.print()
  }

  const handleEmailToFriend = (e) => {
    e.preventDefault()
    e.stopPropagation()
    !emailModalOpen && setEmailModalOpen(true)
  }

  const handleRequestQuote = (e) => {
    e.preventDefault()
    e.stopPropagation()
    !quoteModalOpen && setQuoteModalOpen(true)
  }

  const handleClearFavsOpen = (e) => {
    e.preventDefault()
    e.stopPropagation()
    !clearFavsModalOpen && setClearFavsModalOpen(true)
  }

  const clearFavs = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // empty cookie
    setCookie(QUAM_COOKIE_KEY, {}, { path: '/' })
    // close modal
    setClearFavsModalOpen(false)
  }

  return (<ContentContainer bgColor={`lightGray`} >
    <TitleArea title={title} />
      <ContentWrapper
        currDevice={windowSize.currDevice}
        padding={`6em 0 0`}
      >
        {title.description
          && <FavoritesDescription>
            <WYSIWYGContent
              content={title.description}
              slimline={true}
            />
          </FavoritesDescription>}
      </ContentWrapper>
      {isLoading
        ? <Loading inclIcon={true} />
        : arrEmpty(products)
          ? <Notice padding={`80px 6em 6em`} message={'You have no products selected at the moment.'} />
          : <ContentWrapper
            currDevice={windowSize.currDevice}
          >
            <GridContainer
              gap={`0`}
              columns={`100%`}
              rows={`2`}
              padding={`0 0 ${Padding.padding2}`}
            >
              <CtaContainer
                currDevice={windowSize.currDevice}
              >
                <CtaLeftCol
                  currDevice={windowSize.currDevice}
                >
                  <Button
                    onClick={(e) => handleEmailToFriend(e)}
                    margin={`0 4% 0 0`}
                  >
                    {'Email List'}
                  </Button>
                  <ButtonAlt
                    onClick={(e) => handleRequestQuote(e)}
                  >
                    {'Request a Quote'}
                  </ButtonAlt>
                </CtaLeftCol>
                <CtaRightCol
                  currDevice={windowSize.currDevice}
                >
                  <CtaWrapper
                    currDevice={windowSize.currDevice}
                    onClick={(e) => handlePrint(e)}
                  >
                    <SVGWrapper
                      src={printIcon}
                      width={Widths.icons.small}
                      height={Widths.icons.small}
                    />
                    <CtaRollover>
                      {' Print Page'}
                    </CtaRollover>
                  </CtaWrapper>
                  <CtaWrapper
                    onClick={(e) => handleClearFavsOpen(e)}
                  >
                    <SVGWrapper
                      src={clearIcon}
                      width={Widths.icons.small}
                      height={Widths.icons.small}
                    />
                    <CtaRollover>
                      {' Clear List'}
                    </CtaRollover>
                  </CtaWrapper>
                </CtaRightCol>
              </CtaContainer>
              <GridItem>
                <ProductsGrid
                  sortBy={'title'}
                  products={products}
                  showDescription={true}
                  showButtons={true}
                  numGridCols={4}
                />
              </GridItem>
            </GridContainer>
          </ContentWrapper>}
      <ModalPopup
        isOpen={emailModalOpen}
        handleClose={setEmailModalOpen}
        children={<EmailAFriend
          title={'Email List'}
          content={products}
        />}
      />
      <ModalPopup
        isOpen={quoteModalOpen}
        handleClose={setQuoteModalOpen}
        children={<RequestQuoteFavs
          title={'Request a Quote'}
          content={products}
        />}
      />
      <ModalPopup
        isOpen={clearFavsModalOpen}
        handleClose={setClearFavsModalOpen}
        children={<ClearFavs
          handleAccept={clearFavs}
        />}
      />
  </ContentContainer>)
}
