import React from "react";
import parse, { domToReact } from 'html-react-parser'
import { useHistory } from 'react-router-dom'
import { getLinkUrl } from '../../Helpers'
import {
  ParsedHTMLContainer,
  ParsedHTMLAnchor,
} from './styles'

export default function ParsedHTML({
  content,
}) {
  // router
  const router = useHistory()

  const handleLinkClick = (e, atts, openNewTab = false) => {
    e.preventDefault()
    e.stopPropagation()

    if (!atts.external && '' !== atts.href) {
      openNewTab
        ? window.open(atts.as)
        : router.push(atts.as)
    } else {
      // if url is external, change window location
      openNewTab
        ? window.open(atts.as)
        : window.location = atts.href;
    }
    return
  }

  // early exit
  if ( '' === content || 'string' !== typeof content ) return null

  // config for html parser
  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) return

      if (attribs.href && '' !== attribs.href) {
        // get new link attributes
        const newAtts = getLinkUrl(attribs.href)
        const openNewTab = '_blank' === attribs.target
        // return the anchor wrapped in a Link component
        return (
            <ParsedHTMLAnchor
              className={`parsedLink`}
              onClick={e => handleLinkClick(e, newAtts, openNewTab)}
            >
              {domToReact(children, options)}
            </ParsedHTMLAnchor>
        )
      }
    }
  }

  // parse the content, based on defined options
  const parsedContent = parse(content, options)

  return (<ParsedHTMLContainer>
    {parsedContent}
  </ParsedHTMLContainer>);
}
