import { useState, useEffect } from 'react'

import {
  Widths
} from '../../styles'

// Hook
export function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    const width = isClient ? window.innerWidth : undefined
    const height = isClient ? window.innerHeight : undefined
    const currDevice = !isClient
      ? undefined
      : width <= Widths.maxMobile.replace('px', '')
        ? 'mobile'
        : width <= Widths.maxTablet.replace('px', '')
          ? 'tablet'
          : width <= Widths.maxLaptop.replace('px', '')
            ? 'laptop'
            : 'desktop'
    const windowSizes = {
      width: width,
      height: height,
      mobileMax: Widths.maxMobile.replace('px', ''),
      tabletMax: Widths.maxTablet.replace('px', ''),
      laptopMax: Widths.maxLaptop.replace('px', ''),
      currDevice: currDevice
    }
    return windowSizes
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
