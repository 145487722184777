import styled from "styled-components";

import {
  Color,
  Padding,
  Block,
  Rollover,
} from '../../../styles'

export const FavoritesDescription = styled(Block)`
  p {
    font-size: 16px;
    line-height: 22px;
  }
`

export const CtaContainer = styled(Block).attrs(props => ({
  currDevice: props.currDevice || 'desktop',
  className: 'FavoritesCtaContainer'
}))`
  display: flex;
  padding: ${Padding.padding0} 0 ${Padding.padding1};
  ${props => 'desktop' === props.currDevice || 'laptop' === props.currDevice
    ? `flex-direction: row;`
    : `flex-direction: column;`
  }
`

export const CtaLeftCol = styled(Block)`
  display: flex;
  flex: 1;
  align-items: center;
  ${props => 'desktop' === props.currDevice || 'laptop' === props.currDevice
    ? `justify-content: flex-start;`
    : `justify-content: center;`
  }
`

export const CtaRightCol = styled(Block)`
  display: flex;
  flex: 1;
  align-items: center;
  ${props => 'desktop' === props.currDevice || 'laptop' === props.currDevice
    ? `justify-content: flex-end;`
    : `justify-content: space-around;
      margin-top: 3em;`
  }
`

export const CtaWrapper = styled(Block)`
  position: relative;
  cursor: pointer;

  ${props => 'desktop' === props.currDevice || 'laptop' === props.currDevice
    ? `margin-left: 1.8em;`
    : ``
  }
  
  &:hover {
    & > .Rollover {
      opacity: 1;
    }

    svg {
      fill: ${Color.blue};
      stroke: ${Color.blue};
      
      path,
      text,
      polygon
      {
        fill: ${Color.blue};
        stroke: ${Color.blue};
      }
    }
  }

  svg {
    fill: ${Color.mediumGray};
    stroke: ${Color.mediumGray};
    
    path,
    text,
    polygon
    {
      fill: ${Color.mediumGray};
      stroke: ${Color.mediumGray};
    }
  }
`

export const CtaRollover = styled(Rollover)``
