import { 
  arrEmpty,
  arrayContains,
} from '.'
import {
  EMAIL_REGEX_VALIDATION,
  NON_CORPORATE_EMAILS,
} from '../../styles'

/**
 * Takes an email as a string, and returns a boolean if the email is valid
 * @param {string} email 
 * @param {boolean} enforceCorporate 
 */
export const validateEmail = (email, enforceCorporate = false) => {
  const validEmails = email.match(EMAIL_REGEX_VALIDATION)
  // console.log('arrayContains()', arrayContains(validEmails, NON_CORPORATE_EMAILS))
  if (
    enforceCorporate
    && !arrEmpty(validEmails)
    && arrayContains(validEmails, NON_CORPORATE_EMAILS)
  ) {
    return false
  } else {
    return !arrEmpty(validEmails)
  }
}

/**
 * Takes an email as a string.
 * Returns an array of valid emails if ALL are valid, otherwise false
 * @param {string} emails
 * @param {boolean} enforceCorporate 
 */
export const validateMultipleEmails = (emails, enforceCorporate = false) => {
    // set vars
    let emailArray = []
    let validEmails = []
    const emailString = emails.replace(' ', '')

    // validate emails
    if ('' !== emailString) {
      // built array
      emailArray = emailString.split(',')
      console.log('emailArray', emailArray)
      // check not empty AND matches regex
      validEmails = emailArray.filter(email => {
        return ('' !== email && null !== email.match(EMAIL_REGEX_VALIDATION))
      })
    }

    // console.log('arrayContains(validEmails, NON_CORPORATE_EMAILS)', arrayContains(validEmails, NON_CORPORATE_EMAILS))

    if (arrEmpty(validEmails) || emailArray.length !== validEmails.length) {
      return false
    } else if (enforceCorporate && arrayContains(validEmails, NON_CORPORATE_EMAILS)) {
      return false
    } else {
      return validEmails
    }
}

