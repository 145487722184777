import React from "react";
import ParsedHTML from '../ParsedHTML'
import {
  Container,
  Content,
} from './styles'

export default function Copyright({
  content
}) {
  return (content
    && <Container>
      <Content>
        <ParsedHTML content={content} />
      </Content>
  </Container>);
}
