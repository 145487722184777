import styled from "styled-components";
import {
  Anchor,
  Image,
  Color,
} from '../../../styles'

export const SocialLink = styled(Anchor).attrs(props => ({
  className: `SocialLink`,
}))`
  display: inline-block;
  margin-left: 15px;
  
  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    svg {
      fill: ${Color.white};
      stroke: ${Color.white};

      path,
      text,
      polygon
      {
        fill: ${Color.white};
        stroke: ${Color.white};
      }
    }
  }

  svg {
    fill: ${Color.white};
    stroke: ${Color.white};

    path,
    text,
    polygon
    {
      fill: ${Color.white};
      stroke: ${Color.white};
    }
  }

`;

export const SocialIcon = styled(Image).attrs(props => ({
  className: `SocialIcon`,
  currDevice: props.currDevice || 'desktop',
}))`
  ${props => 'mobile' === props.currDevice
    && `width: 65px;
        height: auto;`}
`;
