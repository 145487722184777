import React, { useState } from "react";
import Reel from './Reel';
import {
  Heading,
  Anchor,
  HoverCircle,
} from '../../../styles';
import ModalPopup from '../ModalPopup';
import { useWindowSize } from '../../Helpers'
import {
  ReelThumbWrapper
} from './styled';

export default function ReelThumb({ name, reel }) {
  const [active, setActive] = useState(false);
  const [hovered, setHovered] = useState(false);
  const windowSize = useWindowSize();

  const openReel = (e) => {
    e.preventDefault();
    setActive(!active);
  }
  return (<ReelThumbWrapper
      onMouseEnter={() => setHovered(true)} // req. for mobile
      onMouseOver={() => setHovered(true)} // desktop edge-cases
      onMouseLeave={() => setHovered(false)}
    >
    <Anchor onClick={openReel}>
      <Reel
        items={reel}
        size={'thumbnail'}
        noArrows
      />
      <HoverCircle
        currDevice={windowSize.currDevice}
        active={hovered}
        padding={'9em'}
        text={'VIEW ALL'}
      />
    </Anchor>
    <Anchor onClick={openReel}>
      <Heading
        type={'h5'}
        center
        children={name}
        color={`gray`}
      />
    </Anchor>
    <ModalPopup
      isOpen={active}
      width={'90%'}
      handleClose={() => setActive(false)}
      children={<>
        <Heading
          type={'h2'}
          children={name}
          color={`blue`}
        />
        <Reel items={reel} />
      </>}
    />
  </ReelThumbWrapper>);
}
