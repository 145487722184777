import styled from "styled-components";

import {
  Block,
  FontSize,
  Widths, 
  Margin,
  Color
} from '../../../styles'

export const DescriptionBlock = styled(Block)`
  p {
    font-size: ${FontSize.body};
    color: ${Color.gray};
    line-height: 1.56em;
    margin: ${Margin.productDescription};
  
    @media screen and (min-width: ${Widths.minTablet}) {
      font-size: ${FontSize.body};
    }
  }
`;
