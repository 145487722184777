import styled from 'styled-components'

import {
  Color,
  Font,
  FontSize,
  Padding,
} from './global'

import {
  Block,
} from './elements'

import {
  Span,
  P,
} from './typography'

import {
  getColorByName,
} from './helpers'

export const REQ_TEXT = ' (Required)'
// export const EMAIL_REGEX_VALIDATION = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/
export const EMAIL_REGEX_VALIDATION = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/
export const NON_CORPORATE_EMAILS = ['@gmail', '@yahoo', '@aol', '@explorer']

export const FormContainer = styled.form.attrs(props => ({
  maxWidth: props.maxWidth,
  padding: props.padding || `0`,
  className: `FormContainer`
}))`
  width: 100%;
  max-width: ${props => props.maxWidth};
  box-sizing: border-box;
  padding: ${props => props.padding};
`;

export const InputLabel = styled.label.attrs(props => ({
  className: `InputLabel`,
  fontWeight: props.fontWeight || `bold`,
}))`
  margin-bottom: 10px;
  display: inline-block;
  color: ${Color.gray};
  font-size: 1.4em;
  font-weight: ${props => props.fontWeight};
`;

export const InputReqLabel = styled(Span).attrs(props => ({
  className: `InputReqLabel`
}))`
  color: ${Color.gray};
  font-weight: normal;
`;

export const ReqMessage = styled(P)`
  margin-top: 0;
  margin-bottom: ${Padding.padding0};
  font-size: 1.4em;
  color: ${Color.gray};
`;

const generalInputStyling = `
  width: 100%;
  box-sizing: border-box;
  color: inherit;
  font-family: ${Font.primary};
  font-size: ${FontSize.body};
  transition: 0.2s;
  border-radius: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const minimalInputStyling = `
  width: auto;
  box-sizing: border-box;
  color: inherit;
  font-size: 1.5em;

  &:focus {
    outline: none;
  }
`;

export const InputText = styled.input.attrs(props => ({
  type: props.type || 'text',
  bgColor: props.bgColor || `white`,
  maxWidth: props.maxWidth || `unset`,
  margin: props.margin || `0`,
  padding: props.padding || `0.7em 0.85em`,
  border: props.border || `1px solid transparent`,
  className: `InputText`
}))`
  max-width: ${props => props.maxWidth};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  background-color: ${props => getColorByName(props.bgColor)};
  border: ${props => props.border};
  ${generalInputStyling}
`;

export const InputNum = styled.input.attrs(props => ({
  type: 'number',
  bgColor: props.bgColor || `white`,
  maxWidth: props.maxWidth || `unset`,
  margin: props.margin || `0`,
  padding: props.padding || `0.7em 0.85em`,
  border: props.border || `1px solid transparent`,
  className: `InputNum`
}))`
  max-width: ${props => props.maxWidth};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  background-color: ${props => getColorByName(props.bgColor)};
  border: ${props => props.border};
  ${generalInputStyling}
`;

export const InputCheckbox = styled.input.attrs(props => ({
  type: 'checkbox',
  bgColor: props.bgColor || `white`,
  maxWidth: props.maxWidth || `unset`,
  margin: props.margin || `0`,
  padding: props.padding || `0.7em 0.85em`,
  border: props.border || `1px solid transparent`,
  className: `InputCheckbox`
}))`
  max-width: ${props => props.maxWidth};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  background-color: ${props => getColorByName(props.bgColor)};
  border: ${props => props.border};
  ${generalInputStyling}
  width: auto;
  margin-right: 8px;
`;

export const InputCheckboxRound = styled.input.attrs(props => ({
  type: 'checkbox',
  bgColor: props.bgColor || `white`,
  maxWidth: props.maxWidth || `unset`,
  margin: props.margin || `0`,
  padding: props.padding || `0`,
  border: props.border || `1px solid ${Color.gray}`,
  className: `InputCheckbox InputCheckboxRound`
}))`
  ${generalInputStyling}
  width: 15px;
  height: 15px;
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  margin-right: 8px;
  background-color: ${props => getColorByName(props.bgColor)};
  border-radius: 50%;
  vertical-align: middle;
  border: ${props => props.border};
  -webkit-appearance: none;
  outline: none;
  
  &:checked {
    background-color: ${Color.blue60pc};
  }
`;

export const InputRadioRound = styled.input.attrs(props => ({
  type: 'radio',
  bgColor: props.bgColor || `white`,
  maxWidth: props.maxWidth || `unset`,
  margin: props.margin || `0`,
  padding: props.padding || `0`,
  border: props.border || `1px solid ${Color.gray}`,
  className: `InputRadio InputRadioRound`
}))`
  ${generalInputStyling}
  width: 15px;
  height: 15px;
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  margin-right: 8px;
  background-color: ${props => getColorByName(props.bgColor)};
  border-radius: 50%;
  vertical-align: middle;
  border: ${props => props.border};
  -webkit-appearance: none;
  outline: none;
  
  &:checked {
    background-color: ${Color.blue60pc};
  }
`;

export const InputSelect = styled.select.attrs(props => ({
  bgColor: props.bgColor || `white`,
  minStyle: props.minStyle || false,
  maxWidth: props.maxWidth || `unset`,
  margin: props.margin || `0`,
  padding: props.padding || `0.7em 0.85em`,
  border: props.border || `1px solid transparent`,
  className: `InputSelect`
}))`
  appearance: none;
  max-width: ${props => props.maxWidth};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  background-color: ${props => getColorByName(props.bgColor)};
  border: ${props => props.border};
  ${props => props.minStyle
    ? `${minimalInputStyling}`
    : `${generalInputStyling}`
  }
`;

export const InputTextArea = styled.textarea.attrs(props => ({
  bgColor: props.bgColor || `white`,
  maxWidth: props.maxWidth || `unset`,
  margin: props.margin || `0`,
  padding: props.padding || `0.7em 0.85em`,
  border: props.border || `1px solid transparent`,
  className: `InputTextArea`
}))`
  max-width: ${props => props.maxWidth};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  background-color: ${props => getColorByName(props.bgColor)};
  border: ${props => props.border};
  ${generalInputStyling}
`;

export const MailSuccess = styled(Block).attrs(props => ({
  fontSize: `1.6em`,
  padding: `1em 0 1.5em`,
  className: `MailSuccess`
}))`
  color: ${Color.green};
  font-weight: normal;
`;

export const MailError = styled(Block).attrs(props => ({
  fontSize: `1.6em`,
  padding: `1em 0 1.5em`,
  className: `MailError`
}))`
  color: ${Color.red};
  font-weight: normal;
`;