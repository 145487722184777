import React from "react";
import { useWindowSize } from '../../Helpers'
import LeftCol from './LeftCol'
import RightCol from './RightCol'
import {
  Container,
  Tagline,
} from './styles'

export default function FooterLogoSocials({
  logo,
  tagline,
  socialIcons,
}) {
  // vars
  const windowSize = useWindowSize()

  return (<Container
    currDevice={windowSize.currDevice}
  >
    <LeftCol 
      logo={logo}
    />
    <RightCol
      tagline={tagline}
      socialIcons={socialIcons}
    />
    <Tagline
      currDevice={windowSize.currDevice}
      show={'mobile' === windowSize.currDevice}
    >
      {tagline}
    </Tagline>
  </Container>);
}
