import styled from 'styled-components'

import {
  Color,
  Padding,
  Block,
  Image,
  Keyframes,
} from '../../../styles'

export const LoadingContainer = styled(Block).attrs(props => ({
  background: props.inclOverlay ? Color.black20pc : 'transparent'
}))`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${Padding.padding2};
  box-sizing: border-box;
  text-align: center;
`

export const LoadingContent = styled(Block).attrs(props => ({
  animate: props.animate || false
}))`
  font-size: 2em;
  position: relative;
`

export const RotatingIcon = styled(Image).attrs(props => ({
  className: `RotatingIcon`
}))`
  animation: ${Keyframes.rotate} 0.8s linear infinite;
  width: 60px;
  height: 60px;
`
