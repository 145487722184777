import React from "react"
import TitleArea from '../../Components/TitleArea'
import WYSIWYGContent from '../../Components/WYSIWYGContent'
import tagsIcon from '../../../assets/images/icons/tags.svg'
import {
  ContentContainer,
} from '../../../styles'
import {
  GridPost,
  FeaturedImage,
  PostContent,
  Taxonomies,
} from './styled'
import { outputBreadcrumbs } from '../../Helpers/breadcrumbHelpers';

export default function PostTemplate({
  title,
  post
}) {
  const categories = post._embedded['wp:term'].find((section) => section.find((children) => children.taxonomy === 'category'));
  const tags = post._embedded['wp:term'].find((section) => section.find((children) => children.taxonomy === 'post_tag'));
  
  return (<ContentContainer>
      {'object' === typeof title
        && <TitleArea title={title} />}
        
      {post && (
        <GridPost>
          <div className="wrapBreadcrumb">
            {outputBreadcrumbs(title.breadcrumbs, '/', 'black')}
          </div>
          <FeaturedImage>
            <img src={post.jetpack_featured_media_url} alt={post.slug} />
          </FeaturedImage>
          
          {(categories || tags) && (
              <Taxonomies>
                {categories &&  (
                  <div>
                    <img src={tagsIcon} alt="Categories" />
                    {categories.map((cat) => <span key={cat.id}>{cat.name}</span>)}
                  </div>)}
                {tags && (
                  <div>
                    <img src={tagsIcon} alt="Tags" />
                    {tags.map((tag) => <span key={tag.id}>{tag.name}</span>)}
                  </div>)}
              </Taxonomies>
          )}
          
          <PostContent>
            <WYSIWYGContent content={post.content.rendered} />
          </PostContent>
        </GridPost>
      )}
  </ContentContainer>)
}
