import React from "react";
import { useHistory } from 'react-router-dom'
import { Parallax } from 'react-parallax';
import WYSIWYGContent from '../../../Components/WYSIWYGContent'
import {
  GridContainer,
  GridItem,
  AnchorButtonAlt,
  ContentWrapper
} from '../../../../styles'
import {
  Column,
} from "./styles";
import {
  useWindowSize,
  getLinkUrl,
} from '../../../Helpers'

export default function Banner({
  image,
  textAlign,
  text,
  cta
}) {
  // router
  const router = useHistory()
  // vars
  const windowSize = useWindowSize()
  let gap = '0'
  let columns = '100%'
  let rows = 'auto'
  let colHeight = '240px'
  if ('mobile' === windowSize.currDevice) {
    colHeight = '50px'
  }
  // else if ('desktop' === windowSize.currDevice) {
  //   colHeight = '42vw'
  // }
  // CTA urls
  const newAtts = getLinkUrl((cta && cta.url) || '')

  const renderCTA = () => {
    if (cta && newAtts) {
      return <AnchorButtonAlt
        onClick={() => newAtts.external
          ? window.location = newAtts.href
          : router.push(newAtts.as)}
        color={`white`}
        borderColor={`white`}
        bgColor={`transparent`}
        hoverColor={`white`}
        hoverBorderColor={`black`}
        hoverBgColor={`black`}
      >
        {cta.title}
      </AnchorButtonAlt>
    }
  }

  if (
    null === image
    || null === text
  ) return null

  return (
    <GridContainer
      gap={gap}
      columns={columns}
      rows={rows}
      className={'FooterBanner'}
    >
      <GridItem>
        <Parallax strength={100} bgImage={image?.sizes?.hero_banner}>
          <Column
            colHeight={colHeight}
            tAlign={textAlign}
          >
          <ContentWrapper>
            {text
              && <WYSIWYGContent content={text} slimline={true} darkTheme={true} />}
            {renderCTA()}
          </ContentWrapper>
          </Column>
        </Parallax>
      </GridItem>
    </GridContainer>
  );
}
