import React from "react";
import { useWindowSize } from '../../Helpers'
import Logo from '../Logo'
import {
  LeftColContainer,
} from './styles'

export default function LeftCol({
  logo
}) {
  // vars
  const windowSize = useWindowSize()
  return (<LeftColContainer
    currDevice={windowSize.currDevice}
  >
    <Logo large logo={logo} />
  </LeftColContainer>);
}
