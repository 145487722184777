import React from "react";
import { useHistory } from 'react-router-dom'
import {
  createMarkup,
  useWindowSize
} from '../../../Helpers'
import {
  Image,
  Heading
} from '../../../../styles'
import {
  Container,
  InnerContainer,
  Icon,
  IconText,
} from "./styles";

export default function IconGrid({
  title,
  icons
}) {
  // router
  const router = useHistory()
  // vars
  const windowSize = useWindowSize()
  let gap = `2em 3em`
  let columns = `repeat(3,auto)`
  let rows = 'auto'
  if ('mobile' === windowSize.currDevice) {
    columns = 'repeat(1,auto)'
    rows = `repeat(${icons.length},auto)`
  } else if ('tablet' === windowSize.currDevice) {
    columns = 'repeat(2,auto)'
    rows = `repeat(${icons.length},auto)`
  }

  return (
    <Container>
      <Heading type={`h2`}>
        {title}
      </Heading>
      <InnerContainer
        columns={columns}
        rows={rows}
        justify={`stretch`}
        gap={gap}
      >
        {icons
          && icons.map((icon, i) => (
          <Icon key={i}
            clickable={!!icon.icon_url}
          >
            <Image
              onClick={() => '' !== icon.icon_url && router.push(icon.icon_url)}
              src={icon.icon_image.url}
              alt=""
            />
            <IconText
              currDevice={windowSize.currDevice}
              dangerouslySetInnerHTML={createMarkup(icon.icon_text)}
            />
          </Icon>
        ))}
      </InnerContainer>
    </Container>
  );
}
