import React from "react";
import ReelThumb from './ReelThumb';
import { GalleryWrapper } from './styled'
import {
  GridContainer,
  GridItem,
} from '../../../styles'
import { useWindowSize } from '../../Helpers'

export default function Gallery({ items }) {

  const windowSize = useWindowSize();

  const {currDevice} = windowSize;
  const numCols = 'mobile' === currDevice ?
    1 : 'tablet' === currDevice ? 3 : 4;
  const numRows = Math.ceil(items.length / numCols)

  return (<GalleryWrapper>
    <GridContainer
      gap={`0.5em`}
      columns={`repeat(${numCols}, 1fr)`}
      rows={`repeat(${numRows}, 1fr)`}
    >
      {items && items.map((item, i) => (
        <GridItem>
            <ReelThumb {...item} key={i} />
        </GridItem>
      ))}
    </GridContainer>
  </GalleryWrapper>);
}
