import styled from 'styled-components'

export const WrapCalculator = styled.div.attrs({
  className: 'WrapCalculator',
})`
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
`
export const WrapResult = styled.div.attrs({
  className: 'WrapResult',
})`
  border-top: 2px solid #5881bc;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;

  p {
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }
`

export const Small = styled.small.attrs({
  className: 'Small',
})`
  font-size: 0.75rem;
  margin-bottom: 2rem;
  display: block;
`

export const Disclaimer = styled.div`

`
