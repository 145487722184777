import React from "react";
import { useHistory } from 'react-router-dom'
import { useWindowSize } from '../../Helpers'
import {
  LogoBlock,
  HomeAnchor,
  QuamLogo
} from './styles'

export default function Logo({
  logo,
  small,
  large
}) {
  // router
  const router = useHistory()
  // vars
  const windowSize = useWindowSize()
  let _size = 'medium'

  if (small && false !== small) {
    _size = 'small'
  } else if (large && false !== large) {
    _size = 'large'
  }

  return (<LogoBlock
    inline
    vAlign={'middle'}
    size={_size}
    currDevice={windowSize.currDevice}
    className={'LogoContainer'}
  >
      <HomeAnchor onClick={() => router.push('/')}>
        {logo
          && <QuamLogo
            inline
            logo={logo}
          />}
      </HomeAnchor>
  </LogoBlock>);
}
