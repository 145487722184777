import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SiteAPIClient from "../../../api/clients/site";
import { arrEmpty } from "../../Helpers";
import Banner from "../../Modules/modules/Banner/Banner";
import SubscribeToNewsletter from "../../Modules/modules/SubscribeToNewsletter/SubscribeToNewsletter";
import FooterMenus from "../FooterMenus";
import FooterLogoSocials from "../FooterLogoSocials";
import Copyright from "../Copyright";
import { Container, Separator } from "./styles";

export default function Footer() {
  // router
  const router = useLocation();
  // states
  const [showBanner, setShowBanner] = useState(false);
  const [bannerImage, setBannerImage] = useState(null);
  const [bannerTextAlign, setBannerTextAlign] = useState("center");
  const [bannerText, setBannerText] = useState("");
  const [bannerCta, setBannerCta] = useState(null);
  const [showNewsletter, setShowNewsletter] = useState(false);
  const [newsletterHeadline, setNewsletterHeadline] = useState("");
  const [newsletterTitle, setNewsletterTitle] = useState("");
  const [menus, setMenus] = useState(null);
  const [logo, setLogo] = useState(null);
  const [socialIcons, setSocialIcons] = useState(null);
  const [tagline, setTagline] = useState(null);
  const [copyright, setCopyright] = useState(null);
  // vars
  let allowedToLoad = true;

  useEffect(() => {
    // get data
    const load = async () => {
      const currPath =
        "/" === router.pathname ? "home" : router.pathname.split("/").pop();
      try {
        // get header assets
        const API = new SiteAPIClient();
        const response = await API.getFooterOptions();
        if (true === response.success) {
          const { options } = response;
          if (options?.include_full_width_banner) {
            const _excludeBannerOn = options.exclude_full_width_banner_on_pages;
            setBannerImage(options.banner_image || null);
            setBannerTextAlign(options.banner_text_align || "center");
            setBannerText(options.banner_text || "");
            setBannerCta(options.banner_cta || null);
            // show the banner?
            if (
              "" !== currPath &&
              _excludeBannerOn &&
              Array.isArray(_excludeBannerOn)
            ) {
              arrEmpty(_excludeBannerOn)
                ? setShowBanner(true)
                : setShowBanner(!_excludeBannerOn.includes(currPath));
            }
          }
          if (options?.include_newsletter_signup) {
            const _excludeNewsletterOn = options.exclude_newsletter_on_pages;
            setNewsletterHeadline(options.newsletter_headline || "");
            setNewsletterTitle(options.newsletter_title || "");
            // show the newsletter signup?
            if (
              "" !== currPath &&
              _excludeNewsletterOn &&
              Array.isArray(_excludeNewsletterOn)
            ) {
              arrEmpty(_excludeNewsletterOn)
                ? setShowNewsletter(true)
                : setShowNewsletter(!_excludeNewsletterOn.includes(currPath));
            }
          }
          setMenus(options.footer_menus || null);
          setLogo(options.footer_logo.url || null);
          setSocialIcons(options.social_icons || null);
          setTagline(options.footer_tagline || null);
          setCopyright(options.copyright_content || null);
        }
      } catch (error) {
        console.error(error);
      }
    };
    // do not call the header yet.
    router && "_error" !== router.pathname && allowedToLoad && load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => (allowedToLoad = false);
  }, [router]);

  return (
    <>
      {showBanner && (
        <Banner
          image={bannerImage}
          textAlign={bannerTextAlign}
          text={bannerText}
          cta={bannerCta}
        />
      )}
      <Container>
        {showNewsletter && (
          <>
            <SubscribeToNewsletter
              headline={newsletterHeadline}
              title={newsletterTitle}
              slimline={true}
            />
            <Separator margin={`0 auto`} />
          </>
        )}
        {!arrEmpty(menus) && <FooterMenus menus={menus} />}

        <FooterLogoSocials
          logo={logo}
          tagline={tagline}
          socialIcons={socialIcons}
        />
        <Separator margin={`10px auto 1.5vw`} />
        <Copyright content={copyright} />
      </Container>
    </>
  );
}
