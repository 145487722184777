import React from 'react'
import { ImageWrapper } from './styles'

export default function Image({
  image
}) {
  return (<ImageWrapper
    src={image?.sizes?.medium || image?.url || null}
    alt={image?.alt || ''}
  />)
}