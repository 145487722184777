import axios from 'axios'
import api from '../index'
import APIClient from './base'

class ProductFiltersAPIClient extends APIClient {

  constructor (base64Auth) {
    super(base64Auth)
    this.endpoint = `${api.quamURL}/product-filters`;
  }

  async getProductsByFilters (catId, filters, postsPerPage = -1, page = 1) {
    // console.log('filters', filters)
    // encode the filters object
    const encodedFilters = btoa(JSON.stringify(filters))
    const url = `${this.endpoint}/?cat_id=${catId}&filters=${encodedFilters}&posts_per_page=${postsPerPage}&page=${page}`
    const response = await axios.get(url)
    return response.data
  }
}

export default ProductFiltersAPIClient
