import React from "react";
import { useHistory } from 'react-router-dom'
import {useSpectraAnalytics} from 'spectra/src/hooks/useSpectraAnalytics'
import {
  useWindowSize,
  isExternal
} from '../../../Helpers'
import {
  SubMenuContainer,
  SubMenuWrapper,
  MenuItemAnchor,
  CloseSubMenu,
} from '../styles'

export default function SubMenu({
  items,
  setMenuOpen,
  setSubMenuOpen
}) {
  const analytics = useSpectraAnalytics()
  // router
  const router = useHistory()
  // vars
  const windowSize = useWindowSize()

  const handleSubMenuItemClick = () => {
    setMenuOpen && setMenuOpen(false)
    setSubMenuOpen && setSubMenuOpen(false)
  }

  return (<SubMenuContainer
    currDevice={windowSize.currDevice}
  >
    <CloseSubMenu
      onClick={(e) => setSubMenuOpen(false)}
    >
      {'+'}
    </CloseSubMenu>
    <SubMenuWrapper
      twoCols={5 <= items.length}
      currDevice={windowSize.currDevice}
      onClick={() => handleSubMenuItemClick()}
    >
      {items.map((item, itemKey) => {
        const itemAs = 'custom' === item.base_slug
          ? `${item.href}`
          : `/${item.base_slug}/${item.slug}`
        return 1 >= item.href.length
          ? <MenuItemAnchor
            key={item.id}
            onClick={() => {
              analytics
              && analytics.logEvent('navigation_click', item)
            }}
            dangerouslySetInnerHTML={{__html: item.title}}
            // onClick={() => handleSubMenuItemClick()}
          />
          : <MenuItemAnchor
            key={item.id}
            dangerouslySetInnerHTML={{__html: item.title}}
            onClick={() => {
              analytics
              && analytics.logEvent('navigation_click', item)
              isExternal(itemAs)
              ? window.location = itemAs
              : router.push(itemAs)
            }}
          />
      })}
    </SubMenuWrapper>
  </SubMenuContainer>)
}
