import React from "react";
import SVG from 'react-inlinesvg'
import {
  SVGContainer
} from './styles'

export default function SVGWrapper({
  src,
  width,
  height,
}) {
  if (!src) return null
  return (<SVGContainer
    width={width}
    height={height}
  >
    <SVG src={src} />
  </SVGContainer>);
}
