import React, { useEffect, useState }  from "react";
import TitleArea from "../../Components/TitleArea";
import ProductGallery from "../../Components/ProductGallery";
import ProductTitle from "../../Components/ProductTitle";
import ProductDescription from "../../Components/ProductDescription";
import ProductButtons from "../../Components/ProductButtons";
import ProductCTAs from "../../Components/ProductCTAs";
import ProductSpecs from "../../Components/ProductSpecs";
import ProductFiles from "../../Components/ProductFiles";
import ProductSlider from "../../Modules/modules/ProductSlider/ProductSlider";
import {
  arrEmpty,
  useWindowSize,
} from '../../Helpers'
import {
  Color,
  Padding,
  ContentContainer,
  ContentWrapper,
  GridContainer,
  GridItem,
} from '../../../styles'

export default function ProductDetailTemplate({
  title,
  content
}) {
  // states
  const [primarySpecSheet, setPrimarySpecSheet] = useState(null)
  // vars
  const windowSize = useWindowSize()
  const prodTitle = content?.title || null;
  const prodDescription = content?.sections?.product_details?.long_description || null;
  const prodGallery = content?.sections?.product_gallery?.gallery || null;
  const prodSpecs = content?.sections?.product_specs?.specs || null;
  const prodFiles = content?.sections?.product_files?.files || null;
  // const prodGroup = content?.sections?.product_groups?.groups[0]?.term_taxonomy_id || null;
  const relatedProds = content?.sections?.related_products?.products || null;
  let gap = '4%'
  let columns = '48% 48%'
  let rows = '1'
  if ('mobile' === windowSize.currDevice) {
    gap = '1.5em'
    columns = 'repeat(1,100%)'
    rows = 'repeat(2,auto)'
  } else if ('tablet' === windowSize.currDevice) {
    gap = '1.5em'
    columns = 'repeat(1,100%)'
    rows = 'repeat(2,auto)'
  }

  useEffect(() => {
    let primaryFile = null
    if (!arrEmpty(prodFiles)) {
      primaryFile = prodFiles.find(file => !!file.primary_spec_sheet)
    }
    setPrimarySpecSheet(primaryFile)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  if (!content) return null

  return (<ContentContainer>
    <TitleArea title={title} />
    <ContentContainer
      bgColor={`lightGray`}
    >
      <ContentWrapper
        currDevice={windowSize.currDevice}
      >
        <GridContainer
          rows={rows}
          columns={columns}
          gap={gap}
          padding={`${Padding.padding2} 0 ${Padding.padding1}`}
        >
          <GridItem>
            {prodGallery && <ProductGallery gallery={prodGallery} />}
          </GridItem>
          <GridItem>
            {prodTitle
              && <ProductTitle
                title={prodTitle}
                fontWeight={'800'}
              />}
            {prodDescription && <ProductDescription description={prodDescription} />}
            <ProductButtons
              product={content}
              primarySpecSheet={primarySpecSheet}
            />
            <ProductCTAs />
          </GridItem>
        </GridContainer>
      </ContentWrapper>
    </ContentContainer>
    {prodSpecs && <ProductSpecs specs={prodSpecs} />}
    {prodFiles &&
      <ContentWrapper
        currDevice={windowSize.currDevice}
      >
        <ProductFiles files={prodFiles} />
      </ContentWrapper>
    }
    {relatedProds
      && <ProductSlider
        title={'Related Products'}
        products={relatedProds}
        bgColor={Color.lightGray}
        slimline={true}
      />}
  </ContentContainer>)
}
