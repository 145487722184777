import styled from 'styled-components'

import {
  Color,
  Padding,
  Block,
} from '../../../styles'

export const ErrorContainer = styled(Block).attrs(props => ({
  className: `ErrorContainer`,
}))`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${Padding.padding2};
  box-sizing: border-box;
  text-align: center;
`

export const ErrorContent = styled(Block).attrs(props => ({
  className: `ErrorContent`,
}))`
  font-size: 2em;
  position: relative;
  color: ${Color.error};
`
