import styled from "styled-components";

import {
  Padding,
  InputText,
  InputLabel,
  InputTextArea,
} from '../../../../styles'

export const ContactInputLabel = styled(InputLabel)`
  // margin-top: ${Padding.padding0};
`;

export const ContactInputText = styled(InputText)``;
  
export const ContactInputTextArea = styled(InputTextArea)``;
