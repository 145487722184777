import styled from "styled-components";

import {
  Padding,
  Block,
  Color,
  FontSize,
  P,
  restrictedWidth
} from '../../../../styles'
import { coverBackground } from "../../../../styles/helpers";

export const Container = styled(Block).attrs(props => ({
  currDevice: props.currDevice || 'desktop',
}))`
  padding: ${props => 'mobile' === props.currDevice 
    ? `${Padding.padding2} 0`
    : `40px 0`};
  padding: ;
  background: ${Color.white};
  position: relative;
  text-align: center;
  ${restrictedWidth}

  h2 {
    margin-top: 0;
    margin-bottom: ${props => 'mobile' === props.currDevice 
      ? `0.2em`
      : `15px`};
  }
`;

export const CategoryBlock = styled(Block).attrs(props => ({
  currDevice: props.currDevice || 'desktop',
}))`
  ${props => props.backgroundImage && coverBackground(props.backgroundImage)}
  width: 100%;
  height: 0;
  padding-top: ${props => 'mobile' === props.currDevice ? `85%` : `100%`};
  text-align: center;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
`;

export const CatName = styled(P)`
  position: absolute;
  bottom: 5%;
  left: 0;
  width: 100%;
  color: ${Color.gray};
  text-align: center;
  font-weight: bold;
  font-size: ${FontSize.body};
`;
