import React from "react";
import { ProdTitle } from './styles'

export default function ProductTitle({
  title
}) {
  return (
    <ProdTitle
      type={'h2'}
      children={title}
    />
  );
}
