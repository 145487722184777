  import React, { useState, useEffect } from 'react'
  import {
    useWindowSize,
  } from '../../Helpers'
  import {
    ModalOverlay,
    ModalWrapper,
    Close,
    ModalContent,
  } from './styles'

  export default function ModalPopup({
    isOpen,
    width,
    handleClose,
    children
  }) {
    // states
    const [open, setOpen] = useState(false)
    // vars
    const windowSize = useWindowSize()

    useEffect(() => {
      setOpen(isOpen)
    }, [isOpen])

    return (<>
      <ModalOverlay
        isOpen={open}
        // onClick={() => setOpen(false)}
        onClick={() => handleClose(false)}
      />
      <ModalWrapper
        width={width}
        isOpen={open}
        currDevice={windowSize.currDevice}
      >
        <Close
          // onClick={() => setOpen(false)}
          onClick={() => handleClose(false)}
        >{`+`}</Close>
        <ModalContent>
          {children || null}
        </ModalContent>
      </ModalWrapper>
    </>)
  }
