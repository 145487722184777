import React from 'react'
import {
  Block,
  Span,
  Anchor,
} from '../../styles'
import { objEmpty } from '.'

/**
 * Formats breadcrumbs and outputs in a container
 *
 * @param {Array} breadcrumbs
 * @param {String} delimiter
 */
export const outputBreadcrumbs = (
  breadcrumbs,
  delimiter = '/',
  color = 'white',
  padding = '30px 0 1vw'
) => {
  return (!objEmpty(breadcrumbs)
    && <Block
      padding={padding}
    >
      <Span
        color={color}
        size={'1.75em'}
      >
        <Anchor
          href={`/`}
          color={color}
        >
          {`Home`}
        </Anchor>
        {` ` + delimiter + ` `}
      </Span>
      {breadcrumbs.map((breadcrumb, idx) => {
        return (
          <Span
            key={idx}
            color={color}
            size={'1.75em'}
          >
            <Anchor
              href={breadcrumb.url}
              color={color}
            >
              {breadcrumb.title}
            </Anchor>
            {`${undefined !== breadcrumbs[idx + 1] ? (` ` + delimiter + ` `) : ``}`}
          </Span>
        )
      })}
    </Block>)
}
