import React, {useState, useRef, useEffect} from "react";
import {
  FadeInContainer
} from './styles'

export default function FadeInSection(props) {
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    const currDomRef = domRef.current
    observer.observe(currDomRef);
    return () => observer.unobserve(currDomRef);
  }, []);
  return (
    <FadeInContainer
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </FadeInContainer>
  );
}
