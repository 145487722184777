import React, { useState, useRef, useEffect } from "react";
import {
  Color,
  ContentContainer,
  Button,
} from '../../../styles'
import {
  InputLabel,
  InputNum,
  InputSelect
} from '../../../styles/forms'
import {
  WrapCalculator,
  WrapResult,
  Small,
  Disclaimer
} from './styled'

export default function Calculator() {
  const resultsRef = useRef(null)
  const [showResult, setShowResult] = useState(false)
  const [roomLength, setRoomLength] = useState(60)
  const [roomWidth, setRoomWidth] = useState(40)
  const [ceilingHeight, setCeilingHeight] = useState(12)
  const [listenerHeight, setListenerHeight] = useState(3.5)
  //const [transformerTapSetting, setTransformerTapSetting] = useState(10)
  const [speakerLayout, setSpeakerLayout] = useState('Edge to Edge')

  const [numberSpeakerRowLengthRoom, setNumberSpeakerRowLengthRoom] = useState(1)
  const [numberSpeakerRowWidthRoom, setNumberSpeakerRowWidthRoom] = useState(1)
  const [total, setTotal] = useState(0)
  const [speakerPlacement, setSpeakerPlacement] = useState(0)

  const inputBorderStyle = `1px solid ${Color.gray}`
  const inputMarginStyle = `0 0 3% 0`
  const inputPaddingStyle = `0.65em`

  const handleSubmit = (e) => {
    e.preventDefault()

    let dispersionAngle = 50
    let listenerEarDistanceSpeaker = ceilingHeight - listenerHeight
    let radius = Math.tan(dispersionAngle * Math.PI / 180) * listenerEarDistanceSpeaker

    let dSpacingFT = Math.round((2 * radius) / 2) * 2
    if (dSpacingFT > 20) {
      dSpacingFT = 20
    }

    //let	dSpacingIN = dSpacingFT * 12
    let bSpacing = dSpacingFT / 2


    let speakersL = 1
    let	speakersW = 1
    //let enterTapW = 1

    speakersL = Math.floor(roomLength / dSpacingFT)
    if ( speakersL < 1 ) {
      speakersL = 1
    }

    speakersW = Math.floor(roomWidth / dSpacingFT)
    if ( speakersW < 1 ) {
      speakersW = 1
    }

    let yPlacementSpkr = (roomLength / 2) - (speakersL * 0.5) * dSpacingFT + bSpacing
    let speakerPlacementSpacingDistance = yPlacementSpkr
    if (roomLength > dSpacingFT) {
      speakerPlacementSpacingDistance = dSpacingFT
    }

    let total = speakersL * speakersW

    setNumberSpeakerRowLengthRoom(speakersL)
    setNumberSpeakerRowWidthRoom(speakersW)
    setTotal(total)
    setSpeakerPlacement(speakerPlacementSpacingDistance)
    setShowResult(true)


  }

  useEffect(() => {
    if(showResult && resultsRef && resultsRef.current) {
      resultsRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [showResult])

  return (
    <ContentContainer bgColor={Color.white} >
      <WrapCalculator>
        <form onSubmit={handleSubmit} onChange={() => setShowResult(false)}>
          <div>
            <InputLabel>What is the room length? (Y)</InputLabel>
            <InputNum
              name="room_length"
              value={roomLength}
              onChange={({target}) => setRoomLength(target.value)}
              margin={inputMarginStyle}
              padding={inputPaddingStyle}
              border={inputBorderStyle}
            />
          </div>
          <div>
            <InputLabel>What is the room width? (X)</InputLabel>
            <InputNum
              name="room_width"
              value={roomWidth}
              onChange={({target}) => setRoomWidth(target.value)}
              margin={inputMarginStyle}
              padding={inputPaddingStyle}
              border={inputBorderStyle}
            />
          </div>
          <div>
            <InputLabel>What is the ceiling height?</InputLabel>
            <InputNum
              name="ceiling_height"
              value={ceilingHeight}
              onChange={({target}) => setCeilingHeight(target.value)}
              margin={inputMarginStyle}
              padding={inputPaddingStyle}
              border={inputBorderStyle}
            />
            <Small>
              Maximum ceiling height of 16 feet
            </Small>
          </div>

          <div>
            <InputLabel>What is the listener height? (Seated or Standing)</InputLabel>
            <InputSelect
              name="listener_height"
              onChange={({target}) => setListenerHeight(target.value)}
              margin={inputMarginStyle}
              padding={inputPaddingStyle}
              border={inputBorderStyle}
              >
                <option value="3.5">Seated</option>
                <option value="5">Standing</option>
            </InputSelect>
          </div>
          <div>
            <InputLabel>Speaker Layout</InputLabel>
            <InputSelect
              name="speaker_layout"
              value={speakerLayout}
              onChange={({target}) => setSpeakerLayout(target.value)}
              margin={inputMarginStyle}
              padding={inputPaddingStyle}
              border={inputBorderStyle}
            >
              <option>Edge to Edge</option>
            </InputSelect>
          </div>

          <Button
            type={'submit'}
          >
            Calculate
          </Button>
        </form>
      </WrapCalculator>

      {showResult > 0 && (<WrapResult>
        <p>Total Speakers Required: <strong>{total}</strong></p>
        <p>Number of speaker in row length of room: <strong>{numberSpeakerRowLengthRoom}</strong></p>
        <p>Number of Speaker in row width of room: <strong>{numberSpeakerRowWidthRoom}</strong></p>
        <p>Speaker placement spacing distance (on centers): <br /><strong>{speakerPlacement} feet</strong></p>
        <div ref={resultsRef}></div>
      </WrapResult>)}

      <Disclaimer>
        <p>This calculator determines the number of loudspeakers and recommended starting positions needed for a given room. To make this easy, calculations are based on these assumptions:</p>
        <ul>
          <li>
            Designed for Edge-to-Edge speaker coverage pattern
          </li>
          <li>
            16' maximum ceiling height
          </li>
          <li>
            Standing height at 5’ and sitting height at 3.5’
          </li>
          <li>
            Open floor plan – free of barriers
          </li>
        </ul>
        <p>If these assumptions don't apply to your application or you are unsure, contact Quam Tech Support or your Quam Customer Success Representative for assistance.</p>
        <p><strong>This calculator is intended for estimation purposes only</strong>. Critical listening applications may require additional speakers</p>
      </Disclaimer>

    </ContentContainer>
  );
}
