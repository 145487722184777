import React from 'react'
import { useWindowSize } from '../../../Helpers'
import ParsedHTML from '../../../Components/ParsedHTML'
import { TextContainer } from './styles'

export default function Text({
  text,
  bgColor,
}) {
  //vars
  const windowSize = useWindowSize()

  return (<TextContainer
    currDevice={windowSize.currDevice}
    bgColor={bgColor || `transparent`}
  >
    <ParsedHTML content={text} />
  </TextContainer>
)
}
