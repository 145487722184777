import styled from "styled-components";

import {
  Image,
} from '../../../../styles'

export const ImageWrapper = styled(Image)`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1em;
`