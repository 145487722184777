import React, { useRef } from "react";
import {
  PaginationContainer,
  PaginationItem,
  PaginationItemSpacer,
  PageSelector,
  NumPagesText,
} from './styles'

export default function PaginationAlt({
  currPage,
  numPages,
  updatePage,
  isLoading = false
}) {
  // refs
  const inputPageNumberEl = useRef(currPage)

  if (!currPage || !numPages) return null

  const handleInputChange = (e, newVal) => {
    updatePage(parseInt(newVal <= numPages.length ? newVal : numPages.length))
  }

  return (
    <PaginationContainer>
      {currPage > 1
        ? <PaginationItem
          onClick={(e) => handleInputChange(e, currPage - 1)}
        >
          {'<'}
        </PaginationItem>
        : <PaginationItemSpacer>{'<'}</PaginationItemSpacer>}
      {numPages
        && <PageSelector
          ref={inputPageNumberEl}
          name={'page-number'}
          onChange={(e) => handleInputChange(e, inputPageNumberEl.current.value)}
          value={currPage}
          required={true}
          min={'1'}
          max={numPages.length}
          step={1}
        />}
      <NumPagesText>
        {` of ${numPages.length} pages`}
      </NumPagesText>
      {currPage <  numPages.length
        ? <PaginationItem
          onClick={(e) => handleInputChange(e, currPage + 1)}
        >
          {'>'}
        </PaginationItem>
        : <PaginationItemSpacer>{'>'}</PaginationItemSpacer>}
    </PaginationContainer>
  )
}
