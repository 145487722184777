import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { Parallax } from 'react-parallax';
import VideoBackground from '../../../Components/VideoBackground'
import {
  useWindowSize,
  getLinkUrl,
} from '../../../Helpers'
import {
  Hero,
  Heading,
  Cta,
} from "./styles";

export default function PageHero({
  mediaType,
  image,
  video,
  heading,
  headingColor,
  cta,
  ctaColor,
}) {
  // router
  const router = useHistory()
  // states
  // eslint-disable-next-line no-unused-vars
  const [headingColors, setHeadingColors] = useState({
    color: headingColor || 'white'
  })
  const [ctaColors, setCtaColors] = useState({
    cta: {
      color: 'white',
      bgColor: 'black' === ctaColor ? 'black' : 'transparent',
      borderColor: 'black' === ctaColor ? 'black' : 'white',
      hoverColor: 'black' === ctaColor ? 'black' : 'white',
      hoverBgColor: 'black' === ctaColor ? 'white' : 'black',
      hoverBorderColor: 'black' === ctaColor ? 'white' : 'black',
    },
    heading: {
      color: headingColor,
    }
  })
  // vars
  const windowSize = useWindowSize()
  const imageUrl = image?.sizes?.large;
  // load the right video url / sized image
  const mediaURL = 'image' ===  mediaType
    ? imageUrl || ''
    : video?.url || ''
  // video thumbnail
  const posterURL = 'video' ===  mediaType && video?.sizes?.large
    ? video.sizes.large
    : ''
  // CTA urls
  const newAtts = getLinkUrl((cta && cta.url) || '')

  useEffect(() => {
    if ('transparent1' === ctaColor) {
      setCtaColors({
        color: 'white',
        bgColor: 'transparent',
        borderColor: 'white',
        hoverColor: 'black',
        hoverBgColor: 'transparent',
        hoverBorderColor: 'black'
      })
    } else if ('transparent2' === ctaColor) {
      setCtaColors({
        color: 'black',
        bgColor: 'transparent',
        borderColor: 'black',
        hoverColor: 'white',
        hoverBgColor: 'transparent',
        hoverBorderColor: 'white'
      })
    } else if ('black' === ctaColor) {
      setCtaColors({
        color: 'white',
        bgColor: 'black',
        borderColor: 'black',
        hoverColor: 'black',
        hoverBgColor: 'white',
        hoverBorderColor: 'white'
      })
    } else if ('white' === ctaColor) {
      setCtaColors({
        color: 'black',
        bgColor: 'white',
        borderColor: 'white',
        hoverColor: 'white',
        hoverBgColor: 'black',
        hoverBorderColor: 'black'
      })
    } else if ('blue' === ctaColor) {
      setCtaColors({
        color: 'white',
        bgColor: 'blue',
        borderColor: 'blue',
        hoverColor: 'white',
        hoverBgColor: 'blue',
        hoverBorderColor: 'blue'
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const renderCTA = () => {
    if (cta && newAtts) {
      return <Cta
        onClick={() => newAtts.external
          ? window.location = newAtts.href
          : router.push(newAtts.as)}
        color={ctaColors.color}
        bgColor={ctaColors.bgColor}
        borderColor={ctaColors.borderColor}
        hoverColor={ctaColors.hoverColor}
        hoverBgColor={ctaColors.hoverBgColor}
        hoverBorderColor={ctaColors.hoverBorderColor}
        maxWidth={`160px`}
      >
        {cta.title}
      </Cta>
    }
  }

  const renderHeroContent = () => (
    <>
      {('video' === mediaType &&
        'mobile' !== windowSize.currDevice) &&
        <VideoBackground
          url={mediaURL}
          poster={posterURL}
          autoplay={true}
          mute={true}
          background={true}
          loop={true}
          playsInline
        />
      }

      {heading &&
        <Heading
          currDevice={windowSize.currDevice}
          headingColor={headingColors.color}
        >
          {heading}
        </Heading>
      }

      {renderCTA()}
    </>
  )

  return (
    <Parallax
      strength={200}
      bgImage={imageUrl}
      bgImageAlt="Quam Speakers Home Page Hero"
    >
      <Hero>
        {renderHeroContent()}
      </Hero>
    </Parallax>
  );
}
