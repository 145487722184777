import styled from "styled-components";

import {
  Span,
} from '../../../styles'

export const SVGContainer = styled(Span).attrs(props => ({
  className: `SVGContainer`
}))`
  display: inline-block;
  position: relative;

  width: ${props => props.width ? props.width : `auto`};
  height: ${props => props.height ? props.height : `auto`};

  svg {
    height: 100%;
    width: 100%;
    transition: 0.1s;

    & > * {
      transition: 0.1s;
    }
  }
`;
