export const formConfig = {
  form_fields: {
      name: {
        label: 'Name',
        name: 'name',
        required: true
      },
      title: {
        label: 'Title',
        name: 'title',
        required: true
      },
      company: {
        label: 'Company',
        name: 'company',
        required: true
      },
      address: {
        label: 'Address',
        name: 'address',
        required: true
      },
      email: {
        label: 'Corporate Email',
        name: 'email',
        required: true
      },
      phone: {
        label: 'Phone Number',
        name: 'phone',
        required: true
      },
      zip: {
        label: 'Zip Code',
        name: 'zip',
        required: true
      },
      project: {
        label: 'Tell Us About The Project',
        name: 'project',
        required: true
      },
      urgency: {
        label: 'I Need This Sample By…',
        name: 'urgency',
        required: true
      },
  },
  mailSent: 'Thank You. A Quam representative will contact you in the next 24 hours to confirm your request. We also sent a copy of this request to your email for your records.',
  mailFail: 'Please check that all required fields are entered and valid before submitting the form.',
  formError: 'Sorry, we had trouble submitting your request. Please refresh this page and try again.',
}

// Project Description (dropdown, choices TBD by client. Max 10 choices)
// Purchase Urgency (3 choices TBD by client)