import React, {useState, useEffect} from "react";
import { useParams, useHistory } from 'react-router-dom'
import TemplateFactory from '../Templates/TemplateFactory'
import SiteAPIClient from '../../api/clients/site'
import { arrEmpty } from '../Helpers'
import Loading from '../Components/Loading'

export default function SearchResults() {
  // router
  const params = useParams()
  const router = useHistory()
  const { keyword } = params
  // state
  const [isLoading, setIsLoading] = useState(false)
  const [titleArea, setTitleArea] = useState(null)
  const [currKeyword, setCurrKeyword] = useState(null)
  // vars
  let allowedToLoad = true;

  // get the banner content
  useEffect(() => {
    const loadBanner = async () => {
      setIsLoading(true)
      try {
        const API = new SiteAPIClient();
        const response = await API.getSearchOptions()
        if (true === response.success) {
          // set title area
          setTitleArea({
            title: `Search` || null,
            image: response?.options?.search_results_banner_image || null,
            breadcrumbs: null
          })
        }
      } catch(err) {
        console.error(err)
      }
      setIsLoading(false)
    }

    // when safe to load
    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && loadBanner()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, []) // run once initially

  useEffect(() => {
    keyword && setCurrKeyword(decodeURIComponent(keyword))
  }, [keyword])

  // early exit
  if (arrEmpty(titleArea)) return null

  return (isLoading
    ? <Loading inclIcon={true} />
    : <TemplateFactory
      template={'search-results'}
      title={titleArea}
      content={currKeyword}
    />)
}
