import styled from 'styled-components'

import {
  Color,
  Block,
} from '../../../styles'

export const NoticeContainer = styled(Block).attrs(props => ({
  className: `NoticeContainer`,
}))`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
`

export const NoticeContent = styled(Block).attrs(props => ({
  className: `NoticeContent`,
}))`
  font-size: 2em;
  position: relative;
  color: ${Color.notice};

   a {
    color: ${Color.notice};
   }
`
