import React from "react";
import {
  ContentContainer,
  Heading,
  Button,
  P,
} from '../../../../styles'

export default function ClearFavs({
  handleAccept
}) {
  return (<ContentContainer>
    <Heading
      type={'h4'}
      children={'Clear Favorites'}
      color={`blue`}
    />
    <P>{'Are you sure you want to remove all the items from your favorites list?'}</P>
    <Button
      onClick={(e) => handleAccept(e)}
      maxWidth={`48%`}
      margin={`4% 4% 0 0`}
    >
      {'Confirm'}
    </Button>
  </ContentContainer>);
}
