import React, { useState, useEffect } from "react";
import { 
  arrEmpty,
  objEmpty,
} from '../../Helpers'
import {
  Widths,
} from '../../../styles'
import {
  Container,
  Title,
  FiltersTitleWrapper,
  FiltersWrapper,
  FilterContainer,
  FilterTitle,
  FiltersToggle,
  FilterContent,
  FilterOption,
  FilterRadio,
  FilterRadioLabel,
  CtaRightCol,
  CtaWrapper,
  // CtaRollover,
  CtaText,
  CloseFilterDrawer,
} from './styles'
import SVGWrapper from '../../Components/SVGWrapper'
import clearIcon from '../../../assets/images/icons/Quam-Icon-ClearList.svg'

export default function CategoryFilters({
  isLoading,
  filters,
  updateSelectedFilters,
  handleClearFiltersOpen,
  clearSeed = false,
}) {
  // states
  const [selectedFilters, setSelectedFilters] = useState({})
  const [filtersOpen, setFiltersOpen] = useState(false)

  useEffect(() => {
    updateSelectedFilters(selectedFilters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters])

  useEffect(() => {
    !!clearSeed && handleClearAllFilters()
  }, [clearSeed])

  const handleClearAllFilters = () => {
    // reset selected state
    setSelectedFilters({})
    // get all inputs
    const inputs = document.querySelectorAll('.CategoryFiltersContainer input[type=radio]')
    // loop and set checked to false
    !arrEmpty(inputs) && inputs.forEach((input, idx) => {input.checked = false})
  }

  const handleFilterChange = (e, filterKey) => {
    // get current input atts
    const value = e.target.value
    const _newSelectedFilters = {...selectedFilters}
    if (
      _newSelectedFilters.hasOwnProperty(filterKey)
      && _newSelectedFilters[filterKey].includes(value.toString())
    ) {
      // if;
      //  - the filters already contain an object with the filterKey &
      //  - the value of the object with filterKey is equal to the value of the checked input
      // then;
      //  - delete said object by key &
      //  - un-check the radio input
      delete _newSelectedFilters[filterKey]
      e.target.checked = false
    } else {
      // add new key and array of the newly selected value
      _newSelectedFilters[filterKey] = [value]
    }
    setSelectedFilters(_newSelectedFilters)
  }

  const buildFilterOptions = (filterKey, filterOptions) => (
    <FilterContent>
      {!objEmpty(filterOptions) && Object.entries(filterOptions).map(([idx, option]) => (
        <FilterOption key={idx}>
          <FilterRadioLabel
            disabled={!!isLoading}
          >
            <FilterRadio
              disabled={!!isLoading}
              name={filterKey}
              value={option.value}
              // onChange={(e) => handleFilterChange(e, filterKey)}
              onClick={(e) => handleFilterChange(e, filterKey)}
            />
            {option.label}
          </FilterRadioLabel>
        </FilterOption>
      ))}
    </FilterContent>
  )

  return (!arrEmpty(filters) &&
  <Container bgColor={`lightGray`} >
    <FiltersTitleWrapper
      onClick={() => setFiltersOpen(!filtersOpen)}
    >
      <Title>
        {'Simplify Your Search'}
      </Title>
      <FiltersToggle>
        {filtersOpen
          ? `-`
          : `+`}
      </FiltersToggle>
    </FiltersTitleWrapper>
    <FiltersWrapper filtersOpen={filtersOpen} >
      {filters.map((filter, idx) => {
        return (!arrEmpty(filter?.options) 
          && <FilterContainer key={idx}>
            <FilterTitle>
              {filter.name}
            </FilterTitle>
            {buildFilterOptions(filter.slug, filter.options)}
          </FilterContainer>)
      })}
      <CtaRightCol>
        <CtaWrapper
          onClick={(e) => handleClearFiltersOpen(e)}
        >
          <SVGWrapper
            src={clearIcon}
            width={Widths.icons.small}
            height={Widths.icons.small}
          />
          <CtaText>
            {'Clear All Filters'}
          </CtaText>
          {/* <CtaRollover>
            {' Clear Filters'}
          </CtaRollover> */}
        </CtaWrapper>
      </CtaRightCol>
      <CloseFilterDrawer
        onClick={() => setFiltersOpen(false)}
      >
        {'Close'}
      </CloseFilterDrawer>
    </FiltersWrapper>
  </Container>);
}