import React from "react";
import MenuCol from './MenuCol'
import { useWindowSize } from '../../Helpers'
import {
  Container,
  ColWrapper,
} from './styles'

export default function FooterMenus({menus}) {
  // vars
  const windowSize = useWindowSize()
  
  return (<Container>
    <ColWrapper currDevice={windowSize.currDevice} >
      {menus.map((menu, index) => {
        return <MenuCol 
          key={index}
          menuHtml={menu}
        />
      })}
    </ColWrapper>
  </Container>);
}
