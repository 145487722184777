import axios from 'axios'
import api from '../index'

class APIClient {
  authentication = null;
  baseEndpoint = '';
  endpoint = '';

  constructor (base64Auth) {
    base64Auth = base64Auth || null
    this.authentication = base64Auth
    this.baseEndpoint = `${api.baseURL}`;
    // this.endpoint = '';
  }

  async getInfo () {
    const url = `${this.baseEndpoint}`
    const response = await axios.get(url)
    return response.data
  }
}

export default APIClient
