import React from "react";
import {
  ErrorContainer,
  ErrorContent,
} from './styles'

export default function Error({
  message = 'We have encountered an error. Please refresh the page to try again. If the problem persists please contact us.'
}) {
  return (
    <ErrorContainer>
      <ErrorContent>
        {message}
      </ErrorContent>
    </ErrorContainer>
  )
}