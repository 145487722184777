export const formConfig = {
  formFields: {
      email: {
        label: 'Your Email',
        name: 'email',
        placeholder: 'Your Email',
        required: true,
      },
  },
  mailSent: 'Your email has been successfully subscribed.',
  mailFail: 'Please check that any email address entered is valid before submitting the form.',
  formError: 'Sorry, we had trouble submitting your request. Please refresh this page and try again.',
}
