import axios from 'axios'
import TaxAPIClient from './tax'

class ProductTaxAPIClient extends TaxAPIClient {

  // constructor (taxName, base64Auth) {
  //   super(taxName, base64Auth)
  // }

  async getTaxByProductId(ids) {
    const url = `${this.endpoint}?product_id=${ids.join(',')}`

    const response = await axios.get(url)
    return response.data
  }
}

export default ProductTaxAPIClient
