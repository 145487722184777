import React from "react";
import ParsedHTML from '../ParsedHTML'
// import {} from '../../../styles'
import {
  WYSIWYGContentContainer
} from './styles'

export default function WYSIWYGContent({
  content,
  slimline = true,
  darkTheme = false
}) {
  return (<WYSIWYGContentContainer
    slimline={slimline}
    darkTheme={darkTheme}
  >
    <ParsedHTML content={content} />
  </WYSIWYGContentContainer>);
}
