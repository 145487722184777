import styled from 'styled-components'

import {
  Color,
  Widths,
  Block,
  restrictedWidth
} from './index'

export const SlideContainer = styled(Block).attrs(props => ({
  padding: props.padding || '2% 0',
  className: `SlideContainer ${props.className || ''}`
}))`
  width: 100%;
  position: relative;

  ${restrictedWidth}
`;

export const SlideWrapper = styled(Block).attrs(props => ({
  padding: props.padding || '2% 0 0',
  className: `SlideWrapper ${props.className || ''}`
}))`
  position: relative;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
  padding: ${props => props.padding};
  scrollbar-width: none; // Firefox horizontal scrollbar fix

  display: flex;
  flex-wrap: nowrap;

  // scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    box-shadow: unset;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${Color.transparent};
    outline: unset;
  }
`;

export const SlideButton = styled(Block).attrs(props => ({
  visible: props.visible || false,
  className: `SlideButton ${props.className || ''}`,
  top: props.top || `50%`
}))`
  opacity: ${props => props.visible
    ? `1`
    : `0`};
  position: absolute;
  top: ${props => props.top};
  transform: translateY(-55%);
  height: auto;
  width: 8px;
  cursor: pointer;
  font-size: 3em;
  font-weight: 400;
  padding: 40px 0;
  transition: 0.2s;
  transform-origin: center;
  z-index: 99;

  &:hover {
    transform: translateY(-50%) scale(1.2);
  }

  @media screen and (min-width: ${Widths.minTablet}) {
    width: 12px;
    transform: translateY(-50%);
  }
`;

export const SlideLeft = styled(SlideButton).attrs(props => ({
  className: `SlideLeft ${props.className || ''}`
}))`
  left: -3%;
`

export const SlideRight = styled(SlideButton).attrs(props => ({
  className: `SlideRight ${props.className || ''}`
}))`
  right: -3%;
`

export const SlideItem = styled(Block).attrs(props => ({
  active: props.active || false,
  flexDesktop: props.flexDesktop || `0 0 23%`,
  flexTablet: props.flexTablet || `0 0 48%`,
  flexMobile: props.flexMobile || `0 0 99%`,
  padding: props.padding || `0`,
  className: `SlideItem ${props.className || ``}`
}))`
  // scroll-snap-align: start;
  // scroll-snap-stop: always;
  display: inline-block;
  height: 100%;
  padding: ${props => props.padding};
  box-sizing: border-box;
  flex: ${props => props.flexMobile};

  &:nth-child(n+2){
    margin-left: 2.5%;
  }
  &:first-of-type {
    margin-left: 0%;
  }
  &:last-of-type {
    margin-right: 0%;
  }

  @media screen and (min-width: ${Widths.minTablet}) {
    flex: ${props => props.flexTablet};
  }

  @media screen and (min-width: ${Widths.minLaptop}) {
    flex: ${props => props.flexDesktop};
  }
`;

export const AnimatedSlideItem = styled(SlideItem)`
  position: relative;
  vertical-align: top;
  transition: 0.2s;
  box-shadow: ${props => props.active
    ? `0 0 10px 0 ${Color.black20pc};`
    : `0;`}
  margin-bottom: ${props => props.active
    ? `30px;`
    : `0;`}
`;

// NB: components using this file also share/utilise the following from productGrids.js in a big to reuse code where possible:
// -- ProductImage
// -- ProductContentContainer
// -- ProductTitle
// -- ProductDescription
// -- ProductModelCount
// -- ButtonContainer
