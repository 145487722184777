import styled from "styled-components";

import {
  Block,
  Font,
  Widths,
  AnchorButtonAlt,
} from '../../../../styles'

import {
  getColorByName,
} from '../../../../styles/helpers'

export const Hero = styled(Block)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-top: 10vw;
  padding-bottom: 10vw;
  min-height: 320px;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;

  @media screen and (max-width: ${Widths.maxMobile}) {
    height: 320px;
  }
`;

export const Heading = styled.h1.attrs(props => ({
  currDevice: props.currDevice || 'desktop',
  headingColor: props.headingColor || `white`,
}))`
  position: relative;
  z-index: 3;
  color: ${props => getColorByName(props.headingColor)};
  font-family: ${Font.secondary};
  width: 100%;
  text-align: center;
  font-size: ${props => 'mobile' === props.currDevice
    ? `4.8em`
    : 'tablet' === props.currDevice
      ? `6.8em`
      : `9em`
  };
  margin-bottom: 0.37em;
`

export const Cta = styled(AnchorButtonAlt).attrs(props => ({
  currDevice: props.currDevice || 'desktop',
}))`
  /* position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%,-50%); */
`
