import React from "react";
import Gallery from '../../../Components/Gallery'

import {
  GalleryTitle,
  GalleryModuleWrapper,
} from './styles'

export default function GalleryModule({ title, items }) {
  return (<GalleryModuleWrapper>
    <GalleryTitle
      type={'h3'}
      children={title}
      color={`blue`}
    />
    <Gallery items={items} />
  </GalleryModuleWrapper>)
}
