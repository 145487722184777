import styled from 'styled-components'

export const FadeInContainer = styled.div`
  &.fade-in-section {
    opacity: 0;
    transform: translateY(5vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1s ease-out;
    will-change: opacity, visibility;

    &.is-visible {
      opacity: 1;
      transform: none;
      visibility: visible;
    }
  }
`;
