import styled from "styled-components";

import {
  Color,
  FontWeight,
  Block,
  BoxShadow,
  FormContainer,
  InputText,
  Button,
  Anchor,
  P,
} from '../../../../styles'

import {
  HeaderButtonStyles
} from '../styles'

export const SearchBtn = styled(Button).attrs(props => ({
  bgColor: 'red',
  borderColor: 'red'
}))`
  ${HeaderButtonStyles}
  right: 5em;
  z-index: 9999;
`

export const SearchContainer = styled(Block).attrs(props => ({
  currDevice: props.currDevice || 'desktop'
}))`
  position: absolute;
  top: 0;
  right: ${props => props.open ? '14em' : '0'};
  width: ${props => props.open ? 'calc(100% - 140px)' : '0'};
  height: 100%;
  opacity: ${props => props.open ? '1' : '0'};
  max-width: ${props => 'mobile' === props.currDevice ? `unset` : `300px`};
  overflow: visible;
  transition: 0.4s;
  z-index: 9998;

  &:focus {
    border: inherit;
  }

  form,
  input {
    height: 100%;
  }
`

export const SearchForm = styled(FormContainer)`
  background: ${Color.red};
  padding: 1.5em 2em;
`

export const SearchInput = styled(InputText).attrs(props => ({
  bgColor: 'white',
  maxWidth: '100%',
  padding: '0 0.82em',
  border: '0',
}))`
  border-radius: 2em;
`

export const TypeAheadContainer = styled(Block)`
  width: calc(100% + 70px);
  height: auto;
  background: ${Color.transparent};
  z-index: 9998;
  padding: 0.8em 0 0;
  box-sizing: border-box;
`

export const TypeAheadWrapper = styled(Block)`
  width: 100%;
  height: auto;
  background: ${Color.lightGray};
  padding: 1.5em;
  box-sizing: border-box;
  box-shadow: ${BoxShadow.small};
`

export const ResultLineItem = styled(P)`
  width: 100%;
  margin: 0;
  padding: 0;
`

const linkStyles = `
  display: inline-block;
  width: 100%;
  transition: 0.2s;
  margin: 0 auto 0 0;
  padding: 0.3em;
  box-sizing: border-box;
  transition: 0.2s;

  &:hover {
    color: ${Color.blue} !important;
    text-shadow: 0 0 0.5px;
    text-decoration: none !important;
  }
`

export const ResultLink = styled(Anchor)`
  ${linkStyles}
  &:hover {
    background: ${Color.lightGray};
  }
`

export const ViewAllLink = styled(Anchor)`
  ${linkStyles}
  color: ${Color.red};
  font-weight: ${FontWeight.bold};

  &:hover {
    color: ${Color.red} !important;
  }
`

export const SearchOverlay = styled(Block)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${props => props.open ? '100vh' : '0'};
  opacity: 0;
  background: transparent;
  z-index: 9997;
`
