import axios from 'axios'
import api from '../index'
import APIClient from './base'

class CategoryFiltersAPIClient extends APIClient {

  constructor (base64Auth) {
    super(base64Auth)
    this.endpoint = `${api.quamURL}/category-filters`;
  }

  async getFiltersByCategory (catId) {
    const url = `${this.endpoint}/?cat_id=${catId}`
    const response = await axios.get(url)
    return response.data
  }
}

export default CategoryFiltersAPIClient
