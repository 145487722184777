import React, {useState, useEffect} from "react";
import { useWindowSize } from '../../Helpers'
import NavAPIClient from '../../../api/clients/nav'
import MenuItem from './parts/MenuItem'
import BurgerMenuToggle from './parts/BurgerMenuToggle'
import {
  NavBlock,
  NavBlockMobile,
  MenuToggle,
} from './styles'

export default function Navigation({
  slug,
  headerBump
}) {
  // states
  const [menu, setMenu] = useState([])
  const [menuOpen, setMenuOpen] = useState(false)
  // vars
  let allowedToLoad = true;
  const windowSize = useWindowSize()

  useEffect(() => {
    const load = async () => {
      try {
        const API = new NavAPIClient()
        const response = await API.getNavBySlug(slug)
        // console.log('getNavBySlug', response)
        if (true === response.success) {
          setMenu(response.nav)
        }
      } catch(error) {
        console.error(error)
      }
    }

    allowedToLoad && load()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, [])

  const desktopMenu = () => {
    return (menu
      && <NavBlock inline>
        {Object.entries(menu).map(([itemKey, item]) => {
          return (<MenuItem
            key={item.id}
            item={item}
          />)
        })}
      </NavBlock>)
  }

  const mobileMenu = () => {
    return (<>
      <MenuToggle
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <BurgerMenuToggle isOpen={menuOpen} />
      </MenuToggle>
      {menu
        && <NavBlockMobile
          isOpen={menuOpen}
          headerBump={headerBump}
        >
          {Object.entries(menu).map(([itemKey, item]) => {
            return (<MenuItem
              key={item.id}
              item={item}
              setMenuOpen={setMenuOpen}
            />)
          })}
        </NavBlockMobile>}
    </>)
  }

  return ('desktop' === windowSize.currDevice || 'laptop' === windowSize.currDevice
    ? desktopMenu()
    : mobileMenu()
  );
}
