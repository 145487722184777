import styled from 'styled-components'
import {
  Color,
  BoxShadow,
  Block,
} from '../../../styles'

export const ModalContainer = styled(Block).attrs(props => ({
  className: 'ModalContainer'
}))`
  ${props => props.isOpen? `100vh` : `0`};
  overflow: hidden;
`

export const ModalOverlay = styled(Block).attrs(props => ({
  className: 'ModalOverlay'
}))`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  ${props => props.isOpen
    ? `height: 100vh;
       box-shadow: 0px 0px 10px 10px ${Color.black20pc};`
    : `height: 0;
       box-shadow: none;`}
  background: ${Color.black20pc};
  transition: 0.5s;
  overflow: hidden;
  z-index: 9998;
`

export const ModalWrapper = styled(Block).attrs(props => ({
  className: 'ModalWrapper'
}))`
  position: fixed;
  ${props => 'mobile' === props.currDevice
    ? `width: 95vw;`
    : `width: ${(props.width || 'auto')};`}
  max-width: ${props => (props.width || '480px')};
  box-sizing: border-box;
  ${props => props.isOpen
    ? `top: 50%;
       height: auto;`
    : `top: -100vh;
       height: 0;`}
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  background: ${Color.white};
  border: 1px solid ${Color.lightGray};
  box-shadow: ${BoxShadow.medium};
  border-radius: 5px;
  padding: 3em 3em;
  overflow: hidden;
  z-index: 9999;
`

export const Close = styled(Block).attrs(props => ({
  className: 'ModalClose'
}))`
  position: absolute;
  right: 0.25em;
  top: 0em;
  transform-origin: left;
  transform: rotate(45deg);
  font-size: 2.8em;
  font-weight: 400;
  cursor: pointer;
  z-index: 9999;
`

export const ModalContent = styled(Block).attrs(props => ({
  className: 'ModalContent'
}))`
  max-height: 70vh;
  padding: 0 1em;
  box-sizing: border-box;
  overflow-y: auto;
`
