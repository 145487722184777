import React from "react";
import {
  HorizontalLine,
} from '../../../styles'
import {
  DescriptionBlock
} from './styles'

export default function ProductDescription({
  description
}) {
  return (<>
    <DescriptionBlock
      dangerouslySetInnerHTML={{
        __html: description
      }}
    />
    <HorizontalLine 
      color={`slateGray`}
    />
  </>);
}
