import React from "react";
import { createMarkup } from '../../Helpers'
import {
  NoticeContainer,
  NoticeContent,
} from './styles'
import {
  Padding,
} from '../../../styles'

export default function Notice({
  message,
  padding = null,
}) {
  if (!message) return null
  return (
    <NoticeContainer 
      padding={padding ? padding : Padding.padding2}
    >
      <NoticeContent
        dangerouslySetInnerHTML={createMarkup(message)}
      />
    </NoticeContainer>
  )
}