import React, {useState, useEffect} from "react";
import { useHistory } from 'react-router-dom'
import TemplateFactory from '../Templates/TemplateFactory'
import SiteAPIClient from '../../api/clients/site'
import { arrEmpty } from '../Helpers'
import Loading from '../Components/Loading'

export default function TechnicalFiles() {
  // router
  const router = useHistory()
  // state
  const [isLoading, setIsLoading] = useState(false)
  const [titleArea, setTitleArea] = useState(null)
  // vars
  let allowedToLoad = true;

  // get the banner content
  useEffect(() => {
    const loadOptions = async () => {
      setIsLoading(true)
      try {
        const API = new SiteAPIClient();
        const response = await API.getTechnicalFilesOptions()
        if (true === response.success) {
          // set title area
          setTitleArea({
            title: response?.options?.technical_files_banner_title || `Technical Files`,
            image: response?.options?.technical_files_banner_image || null,
            breadcrumbs: null
          })
        }
      } catch(err) {
        console.error(err)
      }
      setIsLoading(false)
    }
    
    // when safe to load
    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && loadOptions()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, [])

  // early exit
  if (arrEmpty(titleArea)) return null

  return (isLoading
    ? <Loading inclIcon={true} />
    : <TemplateFactory
      template={'technical-files'}
      title={titleArea}
      content={null}
    />)
}
