import axios from 'axios'
import api from '../index'

import APIClient from './base'

class SiteAPIClient extends APIClient {

  constructor (base64Auth) {
    super(base64Auth)

    this.endpoint = `${api.quamURL}/site`;
  }

  async getHeaderOptions () {
    const url = `${this.endpoint}/header`
    const response = await axios.get(url)
    return response.data
  }

  async getFooterOptions () {
    const url = `${this.endpoint}/footer`
    const response = await axios.get(url)
    return response.data
  }

  async getProductsOptions () {
    const url = `${this.endpoint}/products`
    const response = await axios.get(url)
    return response.data
  }

  async getSearchOptions () {
    const url = `${this.endpoint}/search`
    const response = await axios.get(url)
    return response.data
  }

  async getFavoritesOptions () {
    const url = `${this.endpoint}/favorites`
    const response = await axios.get(url)
    return response.data
  }

  async getTechnicalFilesOptions () {
    const url = `${this.endpoint}/technical-files`
    const response = await axios.get(url)
    return response.data
  }

  async getOther () {
    const url = `${this.endpoint}/other`
    const response = await axios.get(url)
    return response.data
  }
}

export default SiteAPIClient
