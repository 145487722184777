import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ScrollToTopAuto from '../lib/Components/ScrollToTopAuto'
// import loadable from '@loadable/component'
import Header from '../lib/Components/Header'
import Footer from '../lib/Components/Footer'

// Routes
import Page from './page'
import Home from './Home'
import BlogPage from './blog'
import PostPage from './post'
import Favorites from './favorites'
import TechnicalFiles from './technical-files'
import SearchResults from './results/[keyword]'
import ProductGroup from './product-group/[taxonomy]'
import ProductDetail from './product/[slug]'
import NoMatch from './page/404'

export default function QuamRouter() {
  return (
    <Router>
      <ScrollToTopAuto />
      <Header />
        <Switch>

            {/* Custom WP Pages */}
            <Route path="/page/:slug">
              <Page />
            </Route>

            {/* Product Group Pages */}
            <Route path="/product-group/:taxonomy">
              <ProductGroup />
            </Route>

            {/* Product Detail Pages */}
            <Route path="/product/:slug">
              <ProductDetail />
            </Route>

            {/* Tech Files Page */}
            <Route exact path="/technical-files">
              <TechnicalFiles />
            </Route>

            {/* Favorites Page */}
            <Route exact path="/favorites">
              <Favorites />
            </Route>

            {/* Search Results Page */}
            <Route path="/results/:keyword">
              <SearchResults />
            </Route>
            
            <Route exact path="/blog">
              <BlogPage />
            </Route>
            
            <Route exact path="/post/:slug">
              <PostPage />
            </Route>
            
            {/* Home Page */}
            <Route exact path="/">
              <Home />
            </Route>

            <Route path="/*">
              <NoMatch />
            </Route>

        </Switch>
      <Footer />
    </Router>
  );
}
