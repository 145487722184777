import React, { useState } from "react";
import FavoritesButton from '../Favorites/FavoritesButton'
import Calculator from '../Calculator'
import SVGWrapper from '../SVGWrapper'
import specIcon from '../../../assets/images/icons/Quam-Icon-Document.svg'
import ModalPopup from '../ModalPopup'
import {
  HorizontalLine,
  AnchorButton,
  AnchorButtonAlt,
  Widths,
} from '../../../styles'
import {
  ButtonsContainer,
  // QtyInput,
} from './styles'

export default function ProductButtons({
  product,
  primarySpecSheet = null,
}) {
  // states
  // const [qty, setQty] = useState(1)
  const [calculatorModalOpen, setCalculatorModalOpen] = useState(false)

  const handleCalculatorForm = (e) => {
    e.preventDefault()
    e.stopPropagation()
    !calculatorModalOpen && setCalculatorModalOpen(true)
  }

  return (<>
    <ButtonsContainer display={'flex'}>
      {/* <QtyInput
        maxWidth={`14%`}
        margin={`0 4% 0 0`}
        value={qty}
        min={1}
        max={99}
        onChange={(e) => setQty(e.target.value)}
      /> */}
      <AnchorButton
        /*padding={`1em 0.5em 1em ${Widths.icons.small}`}*/
        href={primarySpecSheet?.file?.url}
        target={'_blank'}
        rel={'noopener noreferrer'}
        bgColor={'blue'}
        borderColor={'blue'}
        margin={`0 5% 0 0`}
        padding={`1em 0.5em`}
        onClick={(e) => handleCalculatorForm(e)}
      >Coverage Calculator
      </AnchorButton>

      {primarySpecSheet
        && <AnchorButtonAlt
          padding={`1em 0.5em 1em ${Widths.icons.small}`}
          href={primarySpecSheet?.file?.url}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <SVGWrapper
            src={specIcon}
            width={Widths.icons.small}
            height={Widths.icons.small}
          />
          {'Spec Sheet'}
        </AnchorButtonAlt>}

        <FavoritesButton
          product={product}
          padding={`0.5em`}
          margin={`0 0 0 5%`}
          inclIcon={true}
          hideText={true}
        />
    </ButtonsContainer>
    <HorizontalLine
      color={`slateGray`}
    />
    <ModalPopup
      isOpen={calculatorModalOpen}
      handleClose={setCalculatorModalOpen}
      children={<Calculator />}
    />
  </>);
}
