import React from "react";
import {
  LoadingContainer,
  LoadingContent,
  RotatingIcon,
} from './styles'
import loadingIcon from '../../../assets/images/icons/loading_icon.png'

export default function Loading({
  inclOverlay = false,
  inclIcon = true,
  loadingText = 'Loading...'
}) {
  return (
    <LoadingContainer
      inclOverlay={inclOverlay}
    >
      <LoadingContent>
        {inclIcon
          ? <RotatingIcon 
            src={loadingIcon}
            alt={loadingText}
          />
          : loadingText}
      </LoadingContent>
    </LoadingContainer>
  )
}