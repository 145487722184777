import styled from 'styled-components'

import {
  Color,
  Padding,
  Block,
} from '../../../styles'

export const TitleAreaContainer = styled(Block).attrs(props => ({
  backgroundImage: props.backgroundImage || ``,
  className: `TitleAreaContainer ${props.className || ''}`
}))`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: ${Padding.padding3} ${Padding.padding2} ${Padding.padding3};

  color: ${Color.white};
  text-align: center;
  background-color: ${Color.blue};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.backgroundImage});
`;