import React from "react";
import ModuleFactory from '../../Modules/ModuleFactory'
import TitleArea from '../../Components/TitleArea'
import WYSIWYGContent from '../../Components/WYSIWYGContent'
import {
  ContentContainer,
} from '../../../styles'

export default function FullWidthTemplate({
  title,
  content,
  modules
}) {

  return (<ContentContainer>
    {'object' === typeof title
      && <TitleArea title={title} />}
    {content
      && <WYSIWYGContent content={content} />}
    {modules
      && modules.map((module, i) => (
        <ModuleFactory
          module={module}
          key={i}
        />
      ))}
  </ContentContainer>)
}
