import React from "react";
import {
  SpecGroupContainer,
  GroupTitle,
  Spec,
  SpecLabel,
  SpecValue,
} from "./styles";

export default function ProductSpecGroup({
  specGroup
}) {
  const groupTitle = specGroup.group_title || null;
  const groupSpecs = specGroup.group_specs || null;
  
  return (
    <SpecGroupContainer>
      <GroupTitle
        type={'h4'}
        children={groupTitle}
        color={'blue'}
        fontWeight={'800'}
      />
      {groupSpecs
        && groupSpecs.map((spec, i) => (
          <Spec
            key={i}
          >
            <SpecLabel 
              margin={`0 auto 0 0`}
            >
              {spec.spec_label}
            </SpecLabel>
            <SpecValue
              margin={`0 auto 0 0`}
            >
              {spec.spec_value}
            </SpecValue>
          </Spec>
        ))}

    </SpecGroupContainer>
  );
}
