import styled from 'styled-components'

export const GridBlog = styled.div.attrs({
  className: 'GridBlog',
})`
    display: grid;
    grid-template-columns: 1fr;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 2rem;
    max-width: 1200px;
    
    @media screen and (min-width: 1200px) {
      margin: 2rem auto;
    }
`;

