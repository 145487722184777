import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie'
import SVGWrapper from '../../SVGWrapper'
import {
  Button,
  Widths,
} from '../../../../styles'
import {
  objEmpty,
  objHasKey,
  useLocation,
  QUAM_COOKIE_KEY,
} from '../../../Helpers'
import favIcon from '../../../../assets/images/icons/Quam-Icon-Heart.svg'

import {useSpectraAnalytics} from 'spectra/src/hooks/useSpectraAnalytics'

export default function FavoritesButton({
  product,
  fontSize,
  maxWidth,
  margin,
  padding,
  inclIcon,
  hideText = false,
}) {
  // cookies
  const [cookies, setCookie] = useCookies([QUAM_COOKIE_KEY]);
  // states
  const [addedToFav, setAddedToFav] = useState(null)

  const location = useLocation()

  const analytics = useSpectraAnalytics()

  useEffect(() => {
    // check if already added to favs
    if (
      !objEmpty(cookies[QUAM_COOKIE_KEY])
      && objHasKey(cookies[QUAM_COOKIE_KEY], product.id)
    ) {
      setAddedToFav(true)
    } else {
      setAddedToFav(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddToFavorites = (e, addToFavorites) => {
    e.preventDefault()
    e.stopPropagation()
    if (addToFavorites) {
      analytics && analytics.logEvent('favorites', {
        product: product.name,
        add: true
      })
      // build new fav obj including new product
      const newFavorites = Object.assign({}, cookies[QUAM_COOKIE_KEY], {
        [product.id]: {
          'title': product.title,
          'url': `${location.origin}/product/${product.name}`,
        }
      })
      // set cookie
      setCookie(QUAM_COOKIE_KEY, newFavorites, { path: '/' })
      // set state
      setAddedToFav(true)
    } else {
      analytics && analytics.logEvent('favorites', {
        product: product.name,
        remove: true
      })
      // copy cookie
      const newFavorites = {...cookies[QUAM_COOKIE_KEY]}
      // remove fav obj item
      delete newFavorites[product.id]
      // set cookie
      setCookie(QUAM_COOKIE_KEY, newFavorites, { path: '/' })
      // set state
      setAddedToFav(false)
    }
  }

  return (
    null !== addedToFav
      ? <Button
        maxWidth={hideText ? '53px' : maxWidth || null}
        fontSize={fontSize || null}
        margin={margin || null}
        padding={padding || null}
        onClick={(e) => handleAddToFavorites(e, !addedToFav)}
      >
        {inclIcon
          && <SVGWrapper
            src={favIcon}
            width={Widths.icons.small}
            height={Widths.icons.small}
          />}
        {!hideText && `${addedToFav ? `Remove` : `Add To Favorites`}`}
      </Button>
      : null
  )

}
