import axios from 'axios'
import api from '../index'
import APIClient from './base'

class ProductsAPIClient extends APIClient {

  constructor (base64Auth) {
    super(base64Auth)

    this.endpoint = `${api.quamURL}/products`;
  }

  async getProductsBySlug (slug) {
    const url = `${this.endpoint}/${slug}`

    const response = await axios.get(url)
    return response.data
  }

  async getProductsById (
    ids,
    includeRelatedProducts = false,
    postsPerPage = -1
  ) {
    const url = `${this.endpoint}/?id=${ids.join(',')}&include_related_products=${includeRelatedProducts}&posts_per_page=${postsPerPage}`

    const response = await axios.get(url)
    return response.data
  }

  async getProductsByGroupId (
    ids,
    includeRelatedProducts = false,
    postsPerPage = -1
  ) {
    const url = `${this.endpoint}/?product_group_id=${ids.join(',')}&include_related_products=${includeRelatedProducts}&posts_per_page=${postsPerPage}`

    const response = await axios.get(url)
    return response.data
  }

  async getProductsByKeyword (
    keyword,
    includeRelatedProducts = false,
    postsPerPage = -1
  ) {
    const url = `${this.endpoint}/?s=${keyword}&include_related_products=${includeRelatedProducts}&posts_per_page=${postsPerPage}`

    const response = await axios.get(url)
    return response.data
  }
}

export default ProductsAPIClient
