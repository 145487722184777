import React, {useState, useEffect} from "react";
import { useParams, useHistory } from 'react-router-dom'
import TemplateFactory from '../Templates/TemplateFactory'
import ProductTaxAPIClient from '../../api/clients/productTax'
import ProductsAPIClient from '../../api/clients/products'
import { 
  arrEmpty,
  objEmpty
} from '../Helpers'
import Loading from '../Components/Loading'
import Error from '../Components/Error'

export default function ProductCategory() {
  // router
  const params = useParams()
  const router = useHistory()
  const {taxonomy} = params
  // state
  const [isLoading, setIsLoading] = useState(false)
  const [category, setCategory] = useState(null)
  const [titleArea, setTitleArea] = useState(null)
  const [products, setProducts] = useState(null)
  const [bannerImage, setBannerImage] = useState(null)
  const [bannerImageMobile, setBannerImageMobile] = useState(null)
  // vars
  const currCatName = taxonomy
    ? taxonomy
    : null
  let allowedToLoad = true;

  // get the current category
  useEffect(() => {
    // reset all
    setCategory(null)
    setTitleArea(null)
    setProducts(null)
    setBannerImage(null)
    setBannerImageMobile(null)

    // load category
    const loadCat = async () => {
      setIsLoading(true)
      try {
        const API = new ProductTaxAPIClient('product-groups');
        const response = await API.getTaxBySlug(currCatName)
        if (true === response.success) {
          const grp = response.product_groups[0]
          // should only return a single item in the array
          setCategory(grp)
          // set title area
          // TODO - set breadcrumbs...
          setTitleArea({
            title: grp?.name || null,
            image: grp?.hero_image?.sizes?.large || null,
            breadcrumbs: [
              {
                'title': 'Primary Category',
                'url': '#',
              },
              {
                'title': 'Secondary Category',
                'url': '#',
              },
            ]
          })

          setBannerImage(grp?.banner_image?.sizes['2048x2048'] || grp?.banner_image?.sizes?.large || null)
          setBannerImageMobile(grp?.banner_image_mobile?.sizes?.large || null)
        } else {
          // couldn't find a category, so stop loading
          setCategory({})
          setIsLoading(false)
        }
      } catch(err) {
        console.error(err)
      }
      // setIsLoading(false)
    }

    // do not call the header yet.
    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && loadCat()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, [taxonomy])

  // get the category products
  useEffect(() => {
    const loadProducts = async () => {
      // setIsLoading(true)
      try {
        const API = new ProductsAPIClient();
        const response = await API.getProductsByGroupId([category.term_id])
        if (true === response.success) {
          setProducts(response.products)
        } else {
          setProducts(null)
        }
      } catch(err) {
        console.error(err)
      }
      setIsLoading(false)
    }

    // do not call the header yet.
    (router
      && '_error' !== router.location.pathname)
      && !objEmpty(category)
      && loadProducts()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])

  if (
    (objEmpty(category)
      || arrEmpty(products)
      || arrEmpty(titleArea))
    && !allowedToLoad
  ) return <Error />;

  return (isLoading
    ? <Loading inclIcon={true} />
    : <TemplateFactory
      template={'product-category'}
      title={titleArea}
      bannerImage={bannerImage}
      bannerImageMobile={bannerImageMobile}
      content={{
        catId: category?.term_id,
        products: products
      }}
    />)
}
