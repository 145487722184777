import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ProductsAPIClient from '../../../../api/clients/products'
import Loading from '../../Loading'
import { arrEmpty } from '../../../Helpers'
import {
  TypeAheadContainer,
  TypeAheadWrapper,
  ResultLineItem,
  ResultLink,
  ViewAllLink,
} from './styles'

export default function TypeAhead({
  keyword,
  handleSearch
}) {
  // router
  const router = useLocation()
  // states
  const [results, setresults] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  // get the results
  useEffect(() => {
    const loadProducts = async () => {
      setIsLoading(true)
      try {
        const API = new ProductsAPIClient();
        const response = await API.getProductsByKeyword(keyword, false, 5)
        if (true === response.success) {
          // set products
          setresults(response.products)
        } else {
          setresults(null)
        }
      } catch(err) {
        console.error(err)
      }
      setTimeout(() => {
        setIsLoading(false)
      }, 200)
    }

    if (
      (router && '_error' !== router.pathname)
      && null !== keyword
    ) {
       // less than 3 chars
      3 > keyword.length
        && setresults(null)
      // more than 3 chars
      3 <= keyword.length
        && loadProducts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword])

  const outputResults = () => {
    if (!arrEmpty(results)) {
      return (<>
        {results.map((result, index) => {
          return (<ResultLineItem key={index} >
            <ResultLink
              tabIndex={index}
              onClick={(e) => handleSearch(e, result.name)}
            >
              {result.title}
            </ResultLink>
          </ResultLineItem>)
        })}
        <ResultLineItem>
          <ViewAllLink
            href={''}
            onClick={(e) => handleSearch(e)}
          >
            {'View All Results'}
          </ViewAllLink>
        </ResultLineItem>
      </>)
    } else {
      return null
    }
  }

  return (<TypeAheadContainer>
    <TypeAheadWrapper>
      {isLoading
        ? <Loading />
        : outputResults()}
    </TypeAheadWrapper>
  </TypeAheadContainer>);
}
