import { keyframes } from 'styled-components'

/**
 * To be used like:
 * 
  import { Keyframes } from '../../../styles'
  export const RotatingIcon = styled(Image).attrs(props => ({
    className: `RotatingIcon`
  }))`
    animation: ${Keyframes.rotate} 2s linear infinite;
    // ... further SCSS goes here ...
  `
 */

export const Keyframes = {
  rotate: keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `,
  // add more keyframe animations...
}
