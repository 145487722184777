import React from "react";
import ParsedHTML from '../ParsedHTML'
import { useWindowSize } from '../../Helpers'
import {
  Col
} from "./styles";

export default function MenuCol({
  menuHtml
}) {
  //vals
  const windowSize = useWindowSize()

  return (<Col currDevice={windowSize.currDevice} >
    <ParsedHTML content={menuHtml} />
  </Col>)
}
