import React from "react";
import ProductSpecGroup from './ProductSpecGroup'
import { useWindowSize } from '../../Helpers'
import { ContentWrapper } from '../../../styles'
import {
  SpecsContainer,
  SpecsHeading,
} from './styles'

export default function ProductSpecs({
  specs
}) {
  // vars
  const windowSize = useWindowSize()

  return (
    <SpecsContainer>
      <ContentWrapper
        currDevice={windowSize.currDevice}
      >
        <SpecsHeading
          type={'h2'}
          children={'Additional Specs'}
        />
        {specs
          && specs.map((specGroup, i) => (
            <ProductSpecGroup
              key={i}
              specGroup={specGroup}
            />
          ))}
      </ContentWrapper>
    </SpecsContainer>
  );
}
