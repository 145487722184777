import React from "react";
import ProductGridItemContainer from './ProductGridItemContainer'
import Notice from '../Notice'
import {
  ProductGridContainer,
  ProductGridWrapper,
} from '../../../styles'
import { arrEmpty, sortArrayOfObjectsByKeyName } from '../../Helpers'

export default function ProductsGrid({
  sortBy,
  products,
  showDescription,
  showButtons,
  numGridCols = 3,
}) {
  // if (!products) return null
  return (arrEmpty(products)
    ? <Notice message={'Sorry. We do not currently stock any product based on your selected criteria. No worries. You can request a quote for a custom order by contacting us at <a href="mailto:qnc@qnc.com">qnc@qnc.com</a>.'} />
    : <ProductGridContainer>
      <ProductGridWrapper
        padding={'1em 0 0'}
      >
        {'date' === sortBy
          ? products.map((product, index) => {
            return <ProductGridItemContainer 
              key={index}
              product={product}
              showDescription={showDescription}
              showButtons={showButtons}
              numGridCols={numGridCols}
            />
          })
          : sortArrayOfObjectsByKeyName(products, sortBy).map((product, index) => {
            return <ProductGridItemContainer 
              key={index}
              product={product}
              showDescription={showDescription}
              showButtons={showButtons}
              numGridCols={numGridCols}
            />
          })}
      </ProductGridWrapper>
    </ProductGridContainer>
  );
}
