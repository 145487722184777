import styled from "styled-components";

import {
  Padding,
  Widths,
  Block,
  P,
  restrictedWidth,
} from '../../../styles'

export const Container = styled(Block).attrs(props => ({}))`
  padding-top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${restrictedWidth}
`;

export const LeftColContainer = styled(Block).attrs(props => ({
  className: `LeftColContainer`
}))`
  flex: 1;
  width: 50%;
  text-align: left;
`;

export const RightColContainer = styled(Block).attrs(props => ({
  className: `RightColContainer`
}))`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  text-align: left;

  @media screen and (min-width: ${Widths.minTablet}) {
    text-align: right;
  }
`;

export const Tagline = styled(P).attrs(props => ({
  className: `Tagline`,
  colorName: `white`,
  currDevice: props.currDevice || 'desktop',
}))`
  display: ${props => props.show ? `block` : `none`};
  ${props => 'mobile' === props.currDevice
    ? `text-align: center;
       width: 100%;
       margin: 0 0 40px 0;`
    : `text-align: right;
       margin: 0 ${Padding.padding0} 0 0;`}
  font-size: 1.2em;

  @media screen and (max-width: ${Widths.maxMobile}) {
    margin: 60px 0 0;
  }

  @media screen and (min-width: ${Widths.minTablet}) {
    font-size: 1.8em;
  }
  @media screen and (min-width: ${Widths.minDesktop}) {
    font-size: 1.8em;
  }
`;
