import React from "react";
import WYSIWYGContent from '../../../Components/WYSIWYGContent'
import SidebarModuleFactory from '../../../SidebarModules/SidebarModuleFactory'
import {
  ContentWrapper,
  GridContainer,
  GridItem,
  Padding,
} from '../../../../styles'
// import {} from "./styles";
import { useWindowSize } from '../../../Helpers'

export default function ContentAndSidebar({
  sidebarPosition,
  content,
  sidebarModules
}) {
  // vars
  const windowSize = useWindowSize()
  let gap = '5%'
  let columns = 'left' === sidebarPosition
    ? '25% 70%'
    : '70% 25%'
  let rows = 'auto'
  if ('mobile' === windowSize.currDevice) {
    gap = '5em'
    columns = 'repeat(1,auto)'
    rows = 'repeat(2,auto)'
  } else if ('tablet' === windowSize.currDevice) {
    gap = '5em'
    columns = 'repeat(1,auto)'
    rows = 'repeat(2,auto)'
  }

  if (
    null === sidebarPosition
    || null === content
    || null === sidebarModules
  ) return null

  return (<ContentWrapper
    currDevice={windowSize.currDevice}
  >
    <GridContainer
      gap={gap}
      columns={columns}
      rows={rows}
      padding={`${Padding.padding3} 0`}
    >
      <GridItem>
        {'left' === sidebarPosition
          ? sidebarModules
              && sidebarModules.map((module, i) => (
                <SidebarModuleFactory
                  key={i}
                  module={module}
                />
              ))
          : content
            && <WYSIWYGContent
              content={content}
            />}
      </GridItem>
      <GridItem>  
        {'left' === sidebarPosition
          ? content
            && <WYSIWYGContent
              content={content}
            />
          : sidebarModules
            && sidebarModules.map((module, i) => (
              <SidebarModuleFactory
                key={i}
                module={module}
              />
            ))}
      </GridItem>
    </GridContainer>
  </ContentWrapper>);
}
